<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-md-6">
                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0" style="width: 150px;">Nama Perusahaan <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="currentData.nama_perusahaan" placeholder=""  />
                    </div>

                  </div>
                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0" style="width: 150px;">PIC</label>
                      <input type="text" class="form-control" v-model="currentData.pic" placeholder=""  />
                    </div>

                  </div>
                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0" style="width: 150px;">Telepon</label>
                      <input type="number" class="form-control" v-model="currentData.telepon" placeholder=""  />
                    </div>

                  </div>
                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0" style="width: 150px;">Whatsapp</label>
                      <input type="number" class="form-control" v-model="currentData.whatsapp" placeholder=""  />
                    </div>

                  </div>
                </div>


                <div class="col-md-6">

                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0" style="width: 150px;">Wilayah Cakupan Ekspedisi</label>
                      <input type="text" class="form-control" v-model="currentData.wilayah_cakupan" placeholder=""  />
                    </div>
  
                  </div>

                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center ">
                      <label class="me-3 mb-0" style="width: 150px;">Jenis Ekspedisi</label>
                      <div class="p-3 d-flex align-items-center w-100 justify-content-center">
                        <div class="form-check me-3 w-100">
                          <input class="form-check-input me-1" type="checkbox" :checked="currentData.jenis_ekspedisi === 'pelanggan'" @change="updateJenisEkspedisi('pelanggan')" id="flexCheckDefault" style="width: 28px; height: 28px;">
                          <label class="form-check-label pt-1" for="flexCheckDefault"  style="font-weight: 300 !important; font-size: 13px; white-space: nowrap;">
                            Ekspedisi Pelanggan
                          </label>
                        </div>
  
                        <div class="form-check w-100">
                          <input class="form-check-input me-1" type="checkbox" :checked="currentData.jenis_ekspedisi === 'supplier'" @change="updateJenisEkspedisi('supplier')" id="flexCheckDefault" style="width: 28px; height: 28px;">
                          <label class="form-check-label pt-1" for="flexCheckDefault" style="font-weight: 300 !important; font-size: 13px; white-space: nowrap;">
                            Ekspedisi Supplier
                          </label>
                        </div>
  
                      </div>
                      <!-- <input type="text" class="form-control" v-model="currentData.wilayah_cakupan" placeholder=""  /> -->
                    </div>
  
                  </div>
                </div>
                
                <!-- <div class="col-lg-12 col-sm-12 col-12">
                  <label class="mb-">Gambar </label>
                  <div class="custom-file-container" data-upload-id="product-image">
                    <label v-show="isGambar == true" @click="isGambar = false">Remove
                      <a href="javascript:void(0)" class="custom-file-container__image-clear"
                        title="Clear Image">x</a></label>
                    <label class="custom-file-container__custom-file">
                      <input type="file" @change="updateIsGambar()" ref="gambar"
                        class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
                      <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                      <span class="custom-file-container__custom-file__custom-file-control"></span>
                    </label>
                    <div class="custom-file-container__image-preview"></div>
                  </div>

                </div> -->

                <!-- <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="daftar_bank" class="btn btn-cancel">Batal</router-link>
                </div> -->
                <div class="simpan-button d-flex justify-content-end mt-5">
                    <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
                    <RouterLink to="ekspedisi" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px; border: 1px solid #D6B884 !important;">Batal</RouterLink>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, base_url_wilayah_indonesia, AlertBottomError } from "@/assets/js/function";
import { ModelSelect } from "vue-search-select";
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editcustomer",
  data() {
    return {
      filter: true,
      title: "Perbaharui Ekspedisi",
      title1: "edit ekspedisi",
      config: "",
      api: "",

      //data
      currentData: {
        // fullname: "",
        // email: "",
        // telepone: "",
        // whatsapp: "",
        // job_title: "",
        // dob: "",
        // rate: 0,
        // discount_rate: 0,
        // company: "",
        // industry: "",
        // interest: "",
        // billing_address1: "",
        // billing_address2: "",
        // billing_address3: "",
        // shipping_address1: "",
        // shipping_address2: "",
        // shipping_address3: "",
        // shipping_name: "",
        // default_terms: 0,
        // callback: "customers",

      },

      // currentDate: new Date,

      // provinsiList: [],
      // kotaList: [],
      // kecamatanList: [],
      // kelurahanList: [],

      // needData1: [],
      // needData2: [],
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.currentData.dob = new Date();
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }
    if (localStorage.getItem('callback') != null) {
      this.callback = localStorage.getItem('callback');
    }

    //panggil api provinsi 
    // this.provinsi_index();


    this.prepareData();


  },

  mounted() {
    if (localStorage.getItem('callback') != null) {
      localStorage.removeItem('callback');
    }
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  watch: {
    // 'currentData.provinsi': {
    //   handler(newValue) {
    //     this.kabupaten_index(newValue);
    //     this.currentData.kota = '';
    //     this.currentData.kecamatan = '';
    //     this.currentData.kelurahan = '';
    //   }
    // },
    // 'currentData.kota': {
    //   handler(newValue) {
    //     this.kecamatan_index(newValue);
    //     this.currentData.kecamatan = '';
    //     this.currentData.kelurahan = '';
    //   }
    // },
    // 'currentData.kecamatan': {
    //   handler(newValue) {
    //     this.kelurahan_index(newValue);
    //     this.currentData.kelurahan = '';
    //   }
    // },
  },

  methods: {
    // provinsi_index() {
    //   this.provinsiList = [];
    //   ShowLoading();
    //   axios
    //     .post(base_url_wilayah_indonesia + "provinsi_index", '')
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         // AlertPopup("success", response.data.message, "", 1500, false);
    //         // console.log(response.data.data);
    //         response.data.data.forEach((value, index) => {
    //           let item_prepare = { value: value.id, text: value.nama };
    //           this.provinsiList.push(item_prepare)
    //         });

    //       }
    //       if (response.data.status == false) {

    //         AlertBottomError(response.data.message ? response.data.message : '');

    //       }
    //       CloseLoading();
    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });
    // },
    // kabupaten_index(id) {
    //   this.kotaList = [];
    //   ShowLoading();
    //   const formData = new FormData();
    //   formData.append("id", id);

    //   axios
    //     .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
    //     .then((response) => {

    //       if (response.data.status == true && response.data.data != null) {

    //         response.data.data.forEach((value, index) => {
    //           let item_prepare = { value: value.id, text: value.nama };
    //           this.kotaList.push(item_prepare)
    //         });


    //       }
    //       if (response.data.status == false) {

    //         AlertBottomError(response.data.message ? response.data.message : '');


    //       }
    //       CloseLoading();
    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });
    // },
    // kecamatan_index(id) {
    //   this.kecamatanList = [];
    //   ShowLoading();
    //   const formData = new FormData();
    //   formData.append("id", id);
    //   axios
    //     .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
    //     .then((response) => {
    //       if (response.data.status == true && response.data.data != null) {
    //         response.data.data.forEach((value, index) => {
    //           let item_prepare = { value: value.id, text: value.nama };
    //           this.kecamatanList.push(item_prepare)
    //         });


    //       }
    //       if (response.data.status == false) {
    //         AlertBottomError(response.data.message ? response.data.message : '');


    //       }
    //       CloseLoading();
    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });
    // },
    // kelurahan_index(id) {
    //   this.kelurahanList = [];
    //   ShowLoading();
    //   const formData = new FormData();
    //   formData.append("id", id);
    //   axios
    //     .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
    //     .then((response) => {
    //       if (response.data.status == true && response.data.data != null) {
    //         response.data.data.forEach((value, index) => {
    //           let item_prepare = { value: value.id, text: value.nama };
    //           this.kelurahanList.push(item_prepare)
    //         });

    //       }
    //       if (response.data.status == false) {
    //         AlertBottomError(response.data.message ? response.data.message : '');


    //       }
    //       CloseLoading();
    //     })

    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);
    //     });

    // },
    // copyFromBillingAddres() {

    //   this.currentData.shipping_address1 = this.currentData.billing_address1;
    //   this.currentData.shipping_address2 = this.currentData.billing_address2;
    //   this.currentData.shipping_address3 = this.currentData.billing_address3;
    // },


    prepareData() {

      this.currentData = JSON.parse(localStorage.getItem('currentData'));

      // needData1
      // const needData1 = JSON.parse(localStorage.getItem('needData1'));
      // needData1.forEach((item, index) => {
      //   const element = { value: item.id, text: item.nama }
      //   this.needData1.push(element);
      // });

      // needData2
      // const needData2 = JSON.parse(localStorage.getItem('needData2'));
      // needData2.forEach((item, index) => {
      //   const element = { value: item.id, text: item.nama }
      //   this.needData2.push(element);
      // });


    },


    // getNameFileUrl(url) {
    //   const parts = url.split('/');
    //   const fileName = parts[parts.length - 1];
    //   return fileName;
    // },

    updateJenisEkspedisi(selectedType) {
      // Toggle the selected type; deselect if already selected
      if (this.currentData.jenis_ekspedisi === selectedType) {
        this.currentData.jenis_ekspedisi = null; // Uncheck if clicked again
      } else {
        this.currentData.jenis_ekspedisi = selectedType; // Update with the selected type
      }
    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if(!this.currentData.nama_perusahaan || this.currentData.nama_perusahaan === ''){
        AlertPopup("error", "Validation Error", "Nama Perusahaan tidak boleh kosong!", 1500, false)
        return;
      }

      // formData.delete("dob");
      // if (this.currentData.dob != 'Invalid Date' && this.currentData.dob != new Date()) {
      //   var formattedDate = this.currentData.dob.toISOString().split('T')[0];
      //   formData.append("dob", formattedDate);
      // } else {
      //   formData.append("dob", null);
      // }

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      // CloseLoading()
      // return true

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'ekspedisi_update';
      } else {
        this.api = base_url + 'ekspedisi_update';
      }

      //provinsi 
      // formData.delete("provinsi");
      // const index_provinsi = this.provinsiList.findIndex((record) => record.value === this.currentData.provinsi);
      // if (index_provinsi > -1) {
      //   formData.append('provinsi', this.provinsiList[index_provinsi].text);
      // }

      // //kota
      // formData.delete("kota");
      // const index_kota = this.kotaList.findIndex((record) => record.value === this.currentData.kota);
      // if (index_kota > -1) {
      //   formData.append('kota', this.kotaList[index_kota].text);
      // }
      // //kabupaten
      // formData.delete("kecamatan");
      // const index_kecamatan = this.kecamatanList.findIndex((record) => record.value === this.currentData.kecamatan);
      // if (index_kecamatan > -1) {
      //   formData.append('kecamatan', this.kecamatanList[index_kecamatan].text);
      // }

      // //kelurahan
      // formData.delete("kelurahan");
      // const index_kelurahan = this.kelurahanList.findIndex((record) => record.value === this.currentData.kelurahan);
      // if (index_kelurahan > -1) {
      //   formData.append('kelurahan', this.kelurahanList[index_kelurahan].text);
      // }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('ekspedisi');
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },
  components: {
    ModelSelect
  },

};
</script>


<style scoped>
.form-control {
  text-transform: none !important;
}

.form-check-input:checked {
  border-color: #0DDF7A !important;
  background-color: #0DDF7A !important;
}

label {
  font-weight: 300 !important;

}

/* Hide arrows for number input fields */
input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
