<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12 mb-4">
                  <div class="form-group d-flex align-items-center">
                    <label class="me-3 mb-0 " style="width: 150px;">Nama <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="currentData.nama" placeholder="" />
                  </div>

                </div>
                <!-- <div class="col-lg-12 col-sm-12 col-12">

                  <label class="mb-">Gambar </label>
                  <div class="custom-file-container" data-upload-id="product-image">
                    <label v-show="isGambar == true" @click="isGambar = false">Remove
                      <a href="javascript:void(0)" class="custom-file-container__image-clear"
                        title="Clear Image">x</a></label>
                    <label class="custom-file-container__custom-file">
                      <input type="file" @change="updateIsGambar()" ref="gambar"
                        class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
                      <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                      <span class="custom-file-container__custom-file__custom-file-control"></span>
                    </label>
                    <div class="custom-file-container__image-preview"></div>
                  </div>

                </div> -->

                <!-- <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="payment" class="btn btn-cancel">Batal</router-link>
                </div> -->

                <div class="simpan-button d-flex justify-content-end mt-5">
                    <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
                    <RouterLink to="payment" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px; border: 1px solid #D6B884 !important;">Batal</RouterLink>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editkategori",
  data() {
    return {
      filter: true,
      title: "Perbaharui Metode Pembayaran",
      title1: "edit metode pembayaran",
      config: "",
      api: "",

      //data
      currentData: {
      },
      currentDate: new Date,
      //gambar
      gambar_file_64: "",
      isGambar: false,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData();
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  mounted() {
    //First upload
    // var FileUploadWithPreview = (function () {
    //   "use strict";
    //   var A,
    //     e =
    //       ((function (A) {
    //         var e = (function (A) {
    //           var e,
    //             t = Object.prototype,
    //             g = t.hasOwnProperty,
    //             n = "function" == typeof Symbol ? Symbol : {},
    //             i = n.iterator || "@@iterator",
    //             B = n.asyncIterator || "@@asyncIterator",
    //             E = n.toStringTag || "@@toStringTag";
    //           function r(A, e, t, g) {
    //             var n = e && e.prototype instanceof c ? e : c,
    //               i = Object.create(n.prototype),
    //               B = new k(g || []);
    //             return (
    //               (i._invoke = (function (A, e, t) {
    //                 var g = o;
    //                 return function (n, i) {
    //                   if (g === Q) throw new Error("Generator is already running");
    //                   if (g === a) {
    //                     if ("throw" === n) throw i;
    //                     return R();
    //                   }
    //                   for (t.method = n, t.arg = i; ;) {
    //                     var B = t.delegate;
    //                     if (B) {
    //                       var E = S(B, t);
    //                       if (E) {
    //                         if (E === s) continue;
    //                         return E;
    //                       }
    //                     }
    //                     if ("next" === t.method) t.sent = t._sent = t.arg;
    //                     else if ("throw" === t.method) {
    //                       if (g === o) throw ((g = a), t.arg);
    //                       t.dispatchException(t.arg);
    //                     } else "return" === t.method && t.abrupt("return", t.arg);
    //                     g = Q;
    //                     var r = C(A, e, t);
    //                     if ("normal" === r.type) {
    //                       if (((g = t.done ? a : I), r.arg === s)) continue;
    //                       return { value: r.arg, done: t.done };
    //                     }
    //                     "throw" === r.type &&
    //                       ((g = a), (t.method = "throw"), (t.arg = r.arg));
    //                   }
    //                 };
    //               })(A, t, B)),
    //               i
    //             );
    //           }
    //           function C(A, e, t) {
    //             try {
    //               return { type: "normal", arg: A.call(e, t) };
    //             } catch (A) {
    //               return { type: "throw", arg: A };
    //             }
    //           }
    //           A.wrap = r;
    //           var o = "suspendedStart",
    //             I = "suspendedYield",
    //             Q = "executing",
    //             a = "completed",
    //             s = {};
    //           function c() { }
    //           function u() { }
    //           function l() { }
    //           var h = {};
    //           h[i] = function () {
    //             return this;
    //           };
    //           var m = Object.getPrototypeOf,
    //             f = m && m(m(J([])));
    //           f && f !== t && g.call(f, i) && (h = f);
    //           var d = (l.prototype = c.prototype = Object.create(h));
    //           function p(A) {
    //             ["next", "throw", "return"].forEach(function (e) {
    //               A[e] = function (A) {
    //                 return this._invoke(e, A);
    //               };
    //             });
    //           }
    //           function v(A) {
    //             var e;
    //             this._invoke = function (t, n) {
    //               function i() {
    //                 return new Promise(function (e, i) {
    //                   !(function e(t, n, i, B) {
    //                     var E = C(A[t], A, n);
    //                     if ("throw" !== E.type) {
    //                       var r = E.arg,
    //                         o = r.value;
    //                       return o && "object" == typeof o && g.call(o, "__await")
    //                         ? Promise.resolve(o.__await).then(
    //                           function (A) {
    //                             e("next", A, i, B);
    //                           },
    //                           function (A) {
    //                             e("throw", A, i, B);
    //                           }
    //                         )
    //                         : Promise.resolve(o).then(
    //                           function (A) {
    //                             (r.value = A), i(r);
    //                           },
    //                           function (A) {
    //                             return e("throw", A, i, B);
    //                           }
    //                         );
    //                     }
    //                     B(E.arg);
    //                   })(t, n, e, i);
    //                 });
    //               }
    //               return (e = e ? e.then(i, i) : i());
    //             };
    //           }
    //           function S(A, t) {
    //             var g = A.iterator[t.method];
    //             if (g === e) {
    //               if (((t.delegate = null), "throw" === t.method)) {
    //                 if (
    //                   A.iterator.return &&
    //                   ((t.method = "return"), (t.arg = e), S(A, t), "throw" === t.method)
    //                 )
    //                   return s;
    //                 (t.method = "throw"),
    //                   (t.arg = new TypeError(
    //                     "The iterator does not provide a 'throw' method"
    //                   ));
    //               }
    //               return s;
    //             }
    //             var n = C(g, A.iterator, t.arg);
    //             if ("throw" === n.type)
    //               return (t.method = "throw"), (t.arg = n.arg), (t.delegate = null), s;
    //             var i = n.arg;
    //             return i
    //               ? i.done
    //                 ? ((t[A.resultName] = i.value),
    //                   (t.next = A.nextLoc),
    //                   "return" !== t.method && ((t.method = "next"), (t.arg = e)),
    //                   (t.delegate = null),
    //                   s)
    //                 : i
    //               : ((t.method = "throw"),
    //                 (t.arg = new TypeError("iterator result is not an object")),
    //                 (t.delegate = null),
    //                 s);
    //           }
    //           function y(A) {
    //             var e = { tryLoc: A[0] };
    //             1 in A && (e.catchLoc = A[1]),
    //               2 in A && ((e.finallyLoc = A[2]), (e.afterLoc = A[3])),
    //               this.tryEntries.push(e);
    //           }
    //           function w(A) {
    //             var e = A.completion || {};
    //             (e.type = "normal"), delete e.arg, (A.completion = e);
    //           }
    //           function k(A) {
    //             (this.tryEntries = [{ tryLoc: "root" }]),
    //               A.forEach(y, this),
    //               this.reset(!0);
    //           }
    //           function J(A) {
    //             if (A) {
    //               var t = A[i];
    //               if (t) return t.call(A);
    //               if ("function" == typeof A.next) return A;
    //               if (!isNaN(A.length)) {
    //                 var n = -1,
    //                   B = function t() {
    //                     for (; ++n < A.length;)
    //                       if (g.call(A, n)) return (t.value = A[n]), (t.done = !1), t;
    //                     return (t.value = e), (t.done = !0), t;
    //                   };
    //                 return (B.next = B);
    //               }
    //             }
    //             return { next: R };
    //           }
    //           function R() {
    //             return { value: e, done: !0 };
    //           }
    //           return (
    //             (u.prototype = d.constructor = l),
    //             (l.constructor = u),
    //             (l[E] = u.displayName = "GeneratorFunction"),
    //             (A.isGeneratorFunction = function (A) {
    //               var e = "function" == typeof A && A.constructor;
    //               return (
    //                 !!e && (e === u || "GeneratorFunction" === (e.displayName || e.name))
    //               );
    //             }),
    //             (A.mark = function (A) {
    //               return (
    //                 Object.setPrototypeOf
    //                   ? Object.setPrototypeOf(A, l)
    //                   : ((A.__proto__ = l), E in A || (A[E] = "GeneratorFunction")),
    //                 (A.prototype = Object.create(d)),
    //                 A
    //               );
    //             }),
    //             (A.awrap = function (A) {
    //               return { __await: A };
    //             }),
    //             p(v.prototype),
    //             (v.prototype[B] = function () {
    //               return this;
    //             }),
    //             (A.AsyncIterator = v),
    //             (A.async = function (e, t, g, n) {
    //               var i = new v(r(e, t, g, n));
    //               return A.isGeneratorFunction(t)
    //                 ? i
    //                 : i.next().then(function (A) {
    //                   return A.done ? A.value : i.next();
    //                 });
    //             }),
    //             p(d),
    //             (d[E] = "Generator"),
    //             (d[i] = function () {
    //               return this;
    //             }),
    //             (d.toString = function () {
    //               return "[object Generator]";
    //             }),
    //             (A.keys = function (A) {
    //               var e = [];
    //               for (var t in A) e.push(t);
    //               return (
    //                 e.reverse(),
    //                 function t() {
    //                   for (; e.length;) {
    //                     var g = e.pop();
    //                     if (g in A) return (t.value = g), (t.done = !1), t;
    //                   }
    //                   return (t.done = !0), t;
    //                 }
    //               );
    //             }),
    //             (A.values = J),
    //             (k.prototype = {
    //               constructor: k,
    //               reset: function (A) {
    //                 if (
    //                   ((this.prev = 0),
    //                     (this.next = 0),
    //                     (this.sent = this._sent = e),
    //                     (this.done = !1),
    //                     (this.delegate = null),
    //                     (this.method = "next"),
    //                     (this.arg = e),
    //                     this.tryEntries.forEach(w),
    //                     !A)
    //                 )
    //                   for (var t in this)
    //                     "t" === t.charAt(0) &&
    //                       g.call(this, t) &&
    //                       !isNaN(+t.slice(1)) &&
    //                       (this[t] = e);
    //               },
    //               stop: function () {
    //                 this.done = !0;
    //                 var A = this.tryEntries[0].completion;
    //                 if ("throw" === A.type) throw A.arg;
    //                 return this.rval;
    //               },
    //               dispatchException: function (A) {
    //                 if (this.done) throw A;
    //                 var t = this;
    //                 function n(g, n) {
    //                   return (
    //                     (E.type = "throw"),
    //                     (E.arg = A),
    //                     (t.next = g),
    //                     n && ((t.method = "next"), (t.arg = e)),
    //                     !!n
    //                   );
    //                 }
    //                 for (var i = this.tryEntries.length - 1; i >= 0; --i) {
    //                   var B = this.tryEntries[i],
    //                     E = B.completion;
    //                   if ("root" === B.tryLoc) return n("end");
    //                   if (B.tryLoc <= this.prev) {
    //                     var r = g.call(B, "catchLoc"),
    //                       C = g.call(B, "finallyLoc");
    //                     if (r && C) {
    //                       if (this.prev < B.catchLoc) return n(B.catchLoc, !0);
    //                       if (this.prev < B.finallyLoc) return n(B.finallyLoc);
    //                     } else if (r) {
    //                       if (this.prev < B.catchLoc) return n(B.catchLoc, !0);
    //                     } else {
    //                       if (!C)
    //                         throw new Error("try statement without catch or finally");
    //                       if (this.prev < B.finallyLoc) return n(B.finallyLoc);
    //                     }
    //                   }
    //                 }
    //               },
    //               abrupt: function (A, e) {
    //                 for (var t = this.tryEntries.length - 1; t >= 0; --t) {
    //                   var n = this.tryEntries[t];
    //                   if (
    //                     n.tryLoc <= this.prev &&
    //                     g.call(n, "finallyLoc") &&
    //                     this.prev < n.finallyLoc
    //                   ) {
    //                     var i = n;
    //                     break;
    //                   }
    //                 }
    //                 i &&
    //                   ("break" === A || "continue" === A) &&
    //                   i.tryLoc <= e &&
    //                   e <= i.finallyLoc &&
    //                   (i = null);
    //                 var B = i ? i.completion : {};
    //                 return (
    //                   (B.type = A),
    //                   (B.arg = e),
    //                   i
    //                     ? ((this.method = "next"), (this.next = i.finallyLoc), s)
    //                     : this.complete(B)
    //                 );
    //               },
    //               complete: function (A, e) {
    //                 if ("throw" === A.type) throw A.arg;
    //                 return (
    //                   "break" === A.type || "continue" === A.type
    //                     ? (this.next = A.arg)
    //                     : "return" === A.type
    //                       ? ((this.rval = this.arg = A.arg),
    //                         (this.method = "return"),
    //                         (this.next = "end"))
    //                       : "normal" === A.type && e && (this.next = e),
    //                   s
    //                 );
    //               },
    //               finish: function (A) {
    //                 for (var e = this.tryEntries.length - 1; e >= 0; --e) {
    //                   var t = this.tryEntries[e];
    //                   if (t.finallyLoc === A)
    //                     return this.complete(t.completion, t.afterLoc), w(t), s;
    //                 }
    //               },
    //               catch: function (A) {
    //                 for (var e = this.tryEntries.length - 1; e >= 0; --e) {
    //                   var t = this.tryEntries[e];
    //                   if (t.tryLoc === A) {
    //                     var g = t.completion;
    //                     if ("throw" === g.type) {
    //                       var n = g.arg;
    //                       w(t);
    //                     }
    //                     return n;
    //                   }
    //                 }
    //                 throw new Error("illegal catch attempt");
    //               },
    //               delegateYield: function (A, t, g) {
    //                 return (
    //                   (this.delegate = { iterator: J(A), resultName: t, nextLoc: g }),
    //                   "next" === this.method && (this.arg = e),
    //                   s
    //                 );
    //               },
    //             }),
    //             A
    //           );
    //         })(A.exports);
    //         try {
    //           regeneratorRuntime = e;
    //         } catch (A) {
    //           Function("r", "regeneratorRuntime = r")(e);
    //         }
    //       })((A = { exports: {} }), A.exports),
    //         A.exports);
    //   function t(A, e, t, g, n, i, B) {
    //     try {
    //       var E = A[i](B),
    //         r = E.value;
    //     } catch (A) {
    //       return void t(A);
    //     }
    //     E.done ? e(r) : Promise.resolve(r).then(g, n);
    //   }
    //   var g = function (A) {
    //     return function () {
    //       var e = this,
    //         g = arguments;
    //       return new Promise(function (n, i) {
    //         var B = A.apply(e, g);
    //         function E(A) {
    //           t(B, n, i, E, r, "next", A);
    //         }
    //         function r(A) {
    //           t(B, n, i, E, r, "throw", A);
    //         }
    //         E(void 0);
    //       });
    //     };
    //   };
    //   var n = function (A, e) {
    //     if (!(A instanceof e)) throw new TypeError("Cannot call a class as a function");
    //   };
    //   function i(A, e) {
    //     for (var t = 0; t < e.length; t++) {
    //       var g = e[t];
    //       (g.enumerable = g.enumerable || !1),
    //         (g.configurable = !0),
    //         "value" in g && (g.writable = !0),
    //         Object.defineProperty(A, g.key, g);
    //     }
    //   }
    //   var B = function (A, e, t) {
    //     return e && i(A.prototype, e), t && i(A, t), A;
    //   };
    //   return (
    //     Element.prototype.matches ||
    //     (Element.prototype.matches =
    //       Element.prototype.matchesSelector ||
    //       Element.prototype.mozMatchesSelector ||
    //       Element.prototype.msMatchesSelector ||
    //       Element.prototype.oMatchesSelector ||
    //       Element.prototype.webkitMatchesSelector ||
    //       function (A) {
    //         for (
    //           var e = (this.document || this.ownerDocument).querySelectorAll(A),
    //           t = e.length;
    //           --t >= 0 && e.item(t) !== this;

    //         );
    //         return t > -1;
    //       }),
    //     Array.prototype.findIndex ||
    //     Object.defineProperty(Array.prototype, "findIndex", {
    //       value: function (A) {
    //         if (null == this) throw new TypeError('"this" is null or not defined');
    //         var e = Object(this),
    //           t = e.length >>> 0;
    //         if ("function" != typeof A)
    //           throw new TypeError("predicate must be a function");
    //         for (var g = arguments[1], n = 0; n < t;) {
    //           var i = e[n];
    //           if (A.call(g, i, n, e)) return n;
    //           n++;
    //         }
    //         return -1;
    //       },
    //       configurable: !0,
    //       writable: !0,
    //     }),
    //     (function () {
    //       if ("function" == typeof window.CustomEvent) return !1;
    //       function A(A, e) {
    //         e = e || { bubbles: !1, cancelable: !1, detail: null };
    //         var t = document.createEvent("CustomEvent");
    //         return t.initCustomEvent(A, e.bubbles, e.cancelable, e.detail), t;
    //       }
    //       (A.prototype = window.Event.prototype), (window.CustomEvent = A);
    //     })(),
    //     (function () {
    //       function A(e, t) {
    //         if ((n(this, A), !e))
    //           throw new Error(
    //             "No uploadId found. You must initialize file-upload-with-preview with a unique uploadId."
    //           );
    //         if (
    //           ((this.uploadId = e),
    //             (this.options = t || {}),
    //             (this.options.showDeleteButtonOnImages =
    //               !this.options.hasOwnProperty("showDeleteButtonOnImages") ||
    //               this.options.showDeleteButtonOnImages),
    //             (this.options.text = this.options.hasOwnProperty("text")
    //               ? this.options.text
    //               : { chooseFile: "Choose file..." }),
    //             (this.options.text.chooseFile = this.options.text.hasOwnProperty(
    //               "chooseFile"
    //             )
    //               ? this.options.text.chooseFile
    //               : "Choose file..."),
    //             (this.options.text.browse = this.options.text.hasOwnProperty("browse")
    //               ? this.options.text.browse
    //               : "Browse"),
    //             (this.options.text.selectedCount = this.options.text.hasOwnProperty(
    //               "selectedCount"
    //             )
    //               ? this.options.text.selectedCount
    //               : "files selected"),
    //             (this.cachedFileArray = []),
    //             (this.currentFileCount = 0),
    //             (this.el = document.querySelector(
    //               '.custom-file-container[data-upload-id="'.concat(this.uploadId, '"]')
    //             )),
    //             !this.el)
    //         )
    //           throw new Error(
    //             "Could not find a 'custom-file-container' with the id of: ".concat(
    //               this.uploadId
    //             )
    //           );
    //         if (
    //           ((this.input = this.el.querySelector('input[type="file"]')),
    //             (this.inputLabel = this.el.querySelector(
    //               ".custom-file-container__custom-file__custom-file-control"
    //             )),
    //             (this.imagePreview = this.el.querySelector(
    //               ".custom-file-container__image-preview"
    //             )),
    //             (this.clearButton = this.el.querySelector(
    //               ".custom-file-container__image-clear"
    //             )),
    //             (this.inputLabel.innerHTML = this.options.text.chooseFile),
    //             this.addBrowseButton(this.options.text.browse),
    //             !(
    //               this.el &&
    //               this.input &&
    //               this.inputLabel &&
    //               this.imagePreview &&
    //               this.clearButton
    //             ))
    //         )
    //           throw new Error(
    //             "Cannot find all necessary elements. Please make sure you have all the necessary elements in your html for the id: ".concat(
    //               this.uploadId
    //             )
    //           );
    //         (this.options.images = this.options.hasOwnProperty("images")
    //           ? this.options.images
    //           : {}),
    //           (this.baseImage = this.options.images.hasOwnProperty("baseImage")
    //             ? this.options.images.baseImage

    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.successPdfImage = this.options.images.hasOwnProperty(
    //             "successPdfImage"
    //           )
    //             ? this.options.images.successPdfImage
    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.successVideoImage = this.options.images.hasOwnProperty(
    //             "successVideoImage"
    //           )
    //             ? this.options.images.successVideoImage
    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.successFileAltImage = this.options.images.hasOwnProperty(
    //             "successFileAltImage"
    //           )
    //             ? this.options.images.successFileAltImage
    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.backgroundImage = this.options.images.hasOwnProperty(
    //             "backgroundImage"
    //           )
    //             ? this.options.images.backgroundImage
    //             : ""),
    //           this.bindClickEvents(),
    //           (this.imagePreview.style.backgroundImage = 'url("'.concat(
    //             this.baseImage,
    //             '")'
    //           )),
    //           (this.options.presetFiles = this.options.hasOwnProperty("presetFiles")
    //             ? this.options.presetFiles
    //             : null),
    //           this.options.presetFiles &&
    //           this.addImagesFromPath(this.options.presetFiles)
    //             .then(function () { })
    //             .catch(function (A) {
    //               console.log("Error - " + A.toString()),
    //                 console.log(
    //                   "Warning - An image you added from a path is not able to be added to the cachedFileArray."
    //                 );
    //             });
    //       }
    //       return (
    //         B(A, [
    //           {
    //             key: "bindClickEvents",
    //             value: function () {
    //               var A = this,
    //                 e = this;
    //               e.input.addEventListener(
    //                 "change",
    //                 function () {
    //                   e.addFiles(this.files);
    //                 },
    //                 !0
    //               ),
    //                 this.clearButton.addEventListener(
    //                   "click",
    //                   function () {
    //                     A.clearPreviewPanel();
    //                   },
    //                   !0
    //                 ),
    //                 this.imagePreview.addEventListener("click", function (e) {
    //                   if (
    //                     e.target.matches(
    //                       ".custom-file-container__image-multi-preview__single-image-clear__icon"
    //                     )
    //                   ) {
    //                     var t = e.target.getAttribute("data-upload-token"),
    //                       g = A.cachedFileArray.findIndex(function (A) {
    //                         return A.token === t;
    //                       });
    //                     A.deleteFileAtIndex(g);
    //                   }
    //                 });
    //             },
    //           },
    //           {
    //             key: "addFiles",
    //             value: function (A) {
    //               if (0 !== A.length) {
    //                 this.input.multiple
    //                   ? (this.currentFileCount += A.length)
    //                   : ((this.currentFileCount = A.length), (this.cachedFileArray = []));
    //                 for (var e = 0; e < A.length; e++) {
    //                   var t = A[e];
    //                   (t.token =
    //                     Math.random().toString(36).substring(2, 15) +
    //                     Math.random().toString(36).substring(2, 15)),
    //                     this.cachedFileArray.push(t),
    //                     this.processFile(t);
    //                 }
    //                 var g = new CustomEvent("fileUploadWithPreview:imagesAdded", {
    //                   detail: {
    //                     uploadId: this.uploadId,
    //                     cachedFileArray: this.cachedFileArray,
    //                     addedFilesCount: A.length,
    //                   },
    //                 });
    //                 window.dispatchEvent(g);
    //               }
    //             },
    //           },
    //           {
    //             key: "processFile",
    //             value: function (A) {
    //               var e = this;
    //               0 === this.currentFileCount
    //                 ? (this.inputLabel.innerHTML = this.options.text.chooseFile)
    //                 : 1 === this.currentFileCount
    //                   ? (this.inputLabel.innerHTML = A.name)
    //                   : (this.inputLabel.innerHTML = ""
    //                     .concat(this.currentFileCount, " ")
    //                     .concat(this.options.text.selectedCount)),
    //                 this.addBrowseButton(this.options.text.browse),
    //                 this.imagePreview.classList.add(
    //                   "custom-file-container__image-preview--active"
    //                 );
    //               var t = new FileReader();
    //               t.readAsDataURL(A),
    //                 (t.onload = function () {
    //                   e.input.multiple ||
    //                     (A.type.match("image/png") ||
    //                       A.type.match("image/jpeg") ||
    //                       A.type.match("image/gif")
    //                       ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                         t.result,
    //                         '")'
    //                       ))
    //                       : A.type.match("application/pdf")
    //                         ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                           e.successPdfImage,
    //                           '")'
    //                         ))
    //                         : A.type.match("video/*")
    //                           ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                             e.successVideoImage,
    //                             '")'
    //                           ))
    //                           : (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                             e.successFileAltImage,
    //                             '")'
    //                           ))),
    //                     e.input.multiple &&
    //                     ((e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                       e.backgroundImage,
    //                       '")'
    //                     )),
    //                       A.type.match("image/png") ||
    //                         A.type.match("image/jpeg") ||
    //                         A.type.match("image/gif")
    //                         ? e.options.showDeleteButtonOnImages
    //                           ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                             .concat(
    //                               A.token,
    //                               '"\n                                style="background-image: url(\''
    //                             )
    //                             .concat(
    //                               t.result,
    //                               '\'); "\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                             )
    //                             .concat(
    //                               A.token,
    //                               '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                             ))
    //                           : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                             .concat(
    //                               A.token,
    //                               '"\n                                style="background-image: url(\''
    //                             )
    //                             .concat(
    //                               t.result,
    //                               "'); \"\n                            ></div>\n                        "
    //                             ))
    //                         : A.type.match("application/pdf")
    //                           ? e.options.showDeleteButtonOnImages
    //                             ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                               .concat(
    //                                 A.token,
    //                                 '"\n                                style="background-image: url(\''
    //                               )
    //                               .concat(
    //                                 e.successPdfImage,
    //                                 '\'); "\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                               )
    //                               .concat(
    //                                 A.token,
    //                                 '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                               ))
    //                             : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                               .concat(
    //                                 A.token,
    //                                 '"\n                                style="background-image: url(\''
    //                               )
    //                               .concat(
    //                                 e.successPdfImage,
    //                                 "'); \"\n                            ></div>\n                        "
    //                               ))
    //                           : A.type.match("video/*")
    //                             ? e.options.showDeleteButtonOnImages
    //                               ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successVideoImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                                 ))
    //                               : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successVideoImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            ></div>\n                        '
    //                                 ))
    //                             : e.options.showDeleteButtonOnImages
    //                               ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successFileAltImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                                 ))
    //                               : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successFileAltImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            ></div>\n                        '
    //                                 )));
    //                 });
    //             },
    //           },
    //           {
    //             key: "addImagesFromPath",
    //             value: function (A) {
    //               var t = this;
    //               return new Promise(
    //                 (function () {
    //                   var n = g(
    //                     e.mark(function g(n, i) {
    //                       var B, E, r, C, o;
    //                       return e.wrap(
    //                         function (e) {
    //                           for (; ;)
    //                             switch ((e.prev = e.next)) {
    //                               case 0:
    //                                 (B = []), (E = 0);
    //                               case 2:
    //                                 if (!(E < A.length)) {
    //                                   e.next = 24;
    //                                   break;
    //                                 }
    //                                 return (
    //                                   (r = void 0),
    //                                   (C = void 0),
    //                                   (e.prev = 5),
    //                                   (e.next = 8),
    //                                   fetch(A[E], { mode: "cors" })
    //                                 );
    //                               case 8:
    //                                 return (r = e.sent), (e.next = 11), r.blob();
    //                               case 11:
    //                                 (C = e.sent), (e.next = 18);
    //                                 break;
    //                               case 14:
    //                                 return (
    //                                   (e.prev = 14),
    //                                   (e.t0 = e.catch(5)),
    //                                   i(e.t0),
    //                                   e.abrupt("continue", 21)
    //                                 );
    //                               case 18:
    //                                 ((o = new Blob([C], { type: C.type })).name = A[
    //                                   E
    //                                 ].split("/").pop()),
    //                                   B.push(o);
    //                               case 21:
    //                                 E++, (e.next = 2);
    //                                 break;
    //                               case 24:
    //                                 t.addFiles(B), n();
    //                               case 26:
    //                               case "end":
    //                                 return e.stop();
    //                             }
    //                         },
    //                         g,
    //                         null,
    //                         [[5, 14]]
    //                       );
    //                     })
    //                   );
    //                   return function (A, e) {
    //                     return n.apply(this, arguments);
    //                   };
    //                 })()
    //               );
    //             },
    //           },
    //           {
    //             key: "replaceFiles",
    //             value: function (A) {
    //               if (!A.length) throw new Error("Array must contain at least one file.");
    //               (this.cachedFileArray = A), this.refreshPreviewPanel();
    //             },
    //           },
    //           {
    //             key: "replaceFileAtIndex",
    //             value: function (A, e) {
    //               if (!A) throw new Error("No file found.");
    //               if (!this.cachedFileArray[e])
    //                 throw new Error("There is no file at index", e);
    //               (this.cachedFileArray[e] = A), this.refreshPreviewPanel();
    //             },
    //           },
    //           {
    //             key: "deleteFileAtIndex",
    //             value: function (A) {
    //               if (!this.cachedFileArray[A])
    //                 throw new Error("There is no file at index", A);
    //               this.cachedFileArray.splice(A, 1), this.refreshPreviewPanel();
    //               var e = new CustomEvent("fileUploadWithPreview:imageDeleted", {
    //                 detail: {
    //                   uploadId: this.uploadId,
    //                   cachedFileArray: this.cachedFileArray,
    //                   currentFileCount: this.currentFileCount,
    //                 },
    //               });
    //               window.dispatchEvent(e);
    //             },
    //           },
    //           {
    //             key: "refreshPreviewPanel",
    //             value: function () {
    //               var A = this;
    //               (this.imagePreview.innerHTML = ""),
    //                 (this.currentFileCount = this.cachedFileArray.length),
    //                 this.cachedFileArray.forEach(function (e) {
    //                   return A.processFile(e);
    //                 }),
    //                 this.cachedFileArray.length || this.clearPreviewPanel();
    //             },
    //           },
    //           {
    //             key: "addBrowseButton",
    //             value: function (A) {
    //               this.inputLabel.innerHTML += '<span class="custom-file-container__custom-file__custom-file-control__button"> '.concat(
    //                 A,
    //                 " </span>"
    //               );
    //             },
    //           },
    //           {
    //             key: "emulateInputSelection",
    //             value: function () {
    //               this.input.click();
    //             },
    //           },
    //           {
    //             key: "clearPreviewPanel",
    //             value: function () {
    //               (this.input.value = ""),
    //                 (this.inputLabel.innerHTML = this.options.text.chooseFile),
    //                 this.addBrowseButton(this.options.text.browse),
    //                 (this.imagePreview.style.backgroundImage = 'url("'.concat(
    //                   this.baseImage,
    //                   '")'
    //                 )),
    //                 this.imagePreview.classList.remove(
    //                   "custom-file-container__image-preview--active"
    //                 ),
    //                 (this.cachedFileArray = []),
    //                 (this.imagePreview.innerHTML = ""),
    //                 (this.currentFileCount = 0);
    //             },
    //           },
    //         ]),
    //         A
    //       );
    //     })()
    //   );
    // })();

    // let gambar2 = new FileUploadWithPreview("product-image");


  },
  methods: {

    // getNameFileUrl(url) {
    //   const parts = url.split('/');
    //   const fileName = parts[parts.length - 1];
    //   return fileName;
    // },
    // convertToBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       resolve(reader.result); // Resolve with the result after loading
    //     };
    //     reader.onerror = reject; // Reject if an error occurs
    //     reader.readAsDataURL(file);
    //   });
    // },
    // updateIsGambar() {
    //   if (this.$refs.gambar.value != "") {
    //     this.isGambar = true;
    //   } else {
    //     this.isGambar = false;
    //   }


    //   const file = this.$refs.gambar.files[0];
    //   if (file) {
    //     this.convertToBase64(file).then(base64String => {
    //       this.gambar_file_64 = base64String;
    //     });
    //   }

    // },

    prepareData(){
      this.currentData = JSON.parse(localStorage.getItem('currentData'));
    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if(!this.currentData.nama || this.currentData.nama === ''){
        AlertPopup("error", "Validation Error", "Nama Metode pembayaran Tidak Boleh Kosong!", 1500, false)
        return
      }

      // formData.delete("gambar");

      // if (this.$refs.gambar.value != "") {

      //   formData.append("gambar", this.gambar_file_64);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'payment_update';
      } else {
        this.api = base_url + 'payment_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("payment");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>

<style scoped>
.form-control {
  text-transform: none !important;
}

label {
  font-weight: 300 !important;

}
</style>