  <template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      
      <div class="content">
          <pageheader :title="title" :title1="title1" />

          <div class="menu-tab">
            <div class="d-flex justify-content-start align-items-center gap-4 order-2 order-md-1 mt-4 mt-md-0">
                <div class="font-weight-normal text-md position-relative cursor-pointer px-4 py-2" @click="activePage('umum');"
                    :class="{ 'menu-top': active_page === 'umum', 'text-muted': active_page !== 'umum' }" style="cursor: pointer;">
                    UMUM
                </div>
                
                <div class="font-weight-normal text-md position-relative cursor-pointer px-4 py-2" @click="activePage('rincian_produk');"
                    :class="{ 'menu-top': active_page === 'rincian_produk', 'text-muted': active_page !== 'rincian_produk' }" style="cursor: pointer;">
                    RINCIAN PRODUK
                </div>

                <!-- <div class="font-weight-normal text-md position-relative cursor-pointer px-4 py-2 d-none" @click="activePage('info');"
                    :class="{ 'menu-top': active_page === 'info', 'text-muted': active_page !== 'info' }" style="cursor: pointer;">
                    INFO
                </div>

                <div class="font-weight-normal text-md position-relative cursor-pointer px-4 py-2 d-none" @click="activePage('biaya_lainnya');"
                    :class="{ 'menu-top': active_page === 'biaya_lainnya', 'text-muted': active_page !== 'biaya_lainnya' }" style="cursor: pointer;">
                    BIAYA LAINNYA
                </div> -->
                
                <div class="ms-auto mb-2" v-if="active_page === 'rincian_produk'">
                    <button type="button" @click="addNewRow" class="btn btn-primary rounded-circle p-0" style="width: 35px; height: 35px; font-size: 24px;">
                        <i class='bx bx-plus'></i>
                    </button>
                    <!-- <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Non-Aktif
                        </label>
                    </div> -->
                </div>
                
            </div>

          </div>

          <!-- /add -->
          <form @submit.prevent="insert">
            <div class="card">
              <div class="card-body" style="border: 1px solid #D6B8844D !important;">
                  
                <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
                
                  <div class="row" :class="{ 'custom-active': active_page !== 'umum' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'umum'">
                      <div class="row">
                        <!-- <div class="title-tab py-2 mb-3 w-100" style="border-bottom: 1px solid #aaabad">
                          <h5>Info Umum</h5>
                        </div> -->
                        
                        <div class="col-md-6">
                          <div class="">

                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-4">Tanggal</label>
                              <input type="date" v-model="currentData.date" class="form-control" placeholder="" disabled>
                            </div>

                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-4">No. Permintaan</label>
                              <input type="text" v-model="currentData.number" class="form-control" placeholder="" disabled>
                            </div>

                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-4">Departemen</label>
                              <model-select :options="needData1" :settings="{ settingOption: value, settingOption: value }"
                              v-model="currentData.id_department" class="custom-width form-control" placeholder="" ></model-select> 
                            </div>

                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-4">Status</label>
                              <model-select :options="statusUmum" :settings="{ settingOption: value, settingOption: value }"
                              v-model="currentData.status_request" class="custom-width form-control" placeholder="" ></model-select> 
                            </div>

                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-4">Catatan</label>
                              <textarea class="form-control" v-model="currentData.notes" placeholder=""></textarea>
                            </div>

                          </div>




                        </div>

                        <div class="col-md-6">
                          <div class="">
                            <!-- <div class="d-flex align-items-center mb-3">
                              
                              <label for="" class="col-3" >Foto Produk</label>
                              <div class="custom-file-container" data-upload-id="product-image">
                                <label v-show="isGambar == true" @click="isGambar = false">Remove
                                  <a href="javascript:void(0)" class="custom-file-container__image-clear"
                                    title="Clear Image">x</a></label>
                                <label class="custom-file-container__custom-file">
                                  <input type="file" @change="updateIsGambar()" ref="gambar"
                                    class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
                                  <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                                  <span class="custom-file-container__custom-file__custom-file-control"></span>
                                </label>
                                <div class="custom-file-container__image-preview "></div>
                              </div>
                              <input type="file" class="form-control" placeholder="">
                              
                            </div> -->
                          </div>
                        </div>
                        
                      </div>
                      
                      
                      <!-- <div class="col-md-6 mb-3">

                        <div class="d-flex align-items-center">
                          <label for="" class="col-4">Nama Pelanggan</label>
                          <vue-select :options="namaList" :settings="{ settingOption: value, settingOption: value }"
                          v-model="currentData.status" class="custom-width" placeholder="AUTO LOAD DATA" /> 
                        </div>
                      </div>
                      <div class="col-md-6 mb-3">

                        <div class="d-flex align-items-center">
                          <label for="" class="col-4">Supplier</label>
                          <vue-select :options="supplierList" :settings="{ settingOption: value, settingOption: value }"
                          v-model="currentData.status" class="custom-width" placeholder="AUTO LOAD DATA" /> 
                        </div>
                      </div> -->
                      
                    
                      
                    </div>
                  </div>


                  <div class="row mb-5" :class="{ 'custom-active': active_page !== 'rincian_produk' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'rincian_produk'">
                      <table class="table table-responsive table-border">
                        <thead style="background-color: #D6B884;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col " class="border-1 border-end text-center text-white">Nama Produk</th>
                            <th scope="col " class="border-1 border-end text-center text-white">SKU</th>
                            <th scope="col " class="border-1 border-end text-center text-white">Qty</th>
                            <th scope="col" class="border-1 border-end text-center text-white">Satuan</th>
                            <th scope="col" class="border-1 border-end text-center text-white">Tanggal Diminta</th>
                          </tr>
                        </thead>
                        <tbody style="border: 1px solid #fafafa;">
                          <tr v-for="(item, index) in currentData.product_list" :key="index" class="border border-1" style="height: 40px;">
                              <!-- <td class="text-center">{{ index + 1 }}</td> -->
                              

                              <td class="text-center td-tabel"><input type="text" v-model="item.name" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.sku" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.qty" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.satuan" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.date_request" class="form-control" placeholder="" style="border: none !important;"></td>
                              
                          </tr>
                          
                        </tbody>
                      </table>
                      
                    </div>
                  </div>

                  <div class="row d-none" :class="{ 'custom-active': active_page !== 'info' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'info'">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Info Pengiriman</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Tanggal Pengiriman</label>
                              <input type="date" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Ekspedisi</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Status Pengiriman</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >FOB</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Alamat Pengiriman</h5>
                          </div>

                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Alamat</label>
                              <textarea type="date" class="form-control" placeholder="" disabled></textarea>
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Provinsi</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder="" disabled/> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kota/Kabupaten</label>
                              <div class="d-flex align-items-center gap-3">

                                <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="currentData.status" class="custom-select" placeholder="" disabled/> -->
                                <input type="text" class="form-control" placeholder="Kode Pos" style="width: 105px;" disabled>
                              </div>
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kelurahan</label>
                              <!-- <vue-select :options="kelurahanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder="" disabled/> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6">
                            <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                              <h5>Info Pajak</h5>
                            </div>

                            <div class="">
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3 text-muted" style="font-weight: 300;">Pajak</label>
                                <div class="d-flex align-items-center gap-4">
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Kena Pajak
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Harga Termasuk Pajak
                                    </label>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>

                  

                  <!-- <div class="p-3 d-flex flex-row align-items-start gap-4 mb-3 ms-auto" style="border: 1px solid #D6B884; border-radius: 12px; width: 436px; height: 116px">
                    <div class="d-flex flex-column justify-content-between p-1" style="border-right: 1px solid #D6B884;  width: 120px; height: 100%;">
                      <p class="mb-3">Sub Total</p>
                      <p class="text-muted text-end">Rp.0,-</p>
                    </div>
                    <div class="d-flex flex-column justify-content-between p-1" style="border-right: 1px solid #D6B884;  width: 120px; height: 100%;">
                      <p class="mb-3">Diskon</p>
                      <p class="text-muted text-end">%</p>
                    </div>
                    <div class="d-flex flex-column justify-content-between p-1" style="width: 120px; height: 100%;">
                      <p>Total</p>
                      <p class="text-muted text-end">Rp.0,-</p>
                    </div>
                  </div> -->

                  
                  
                  
              </div>
            </div>
            <div class="simpan-button d-flex justify-content-end mt-5">
              <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
              <RouterLink to="purchase" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px;">Batal</RouterLink>
            </div>
          </form>
          
        <!-- /add -->
        </div>
    </div>
  </div>

  <!-- <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladditempurchase"
    ref="buttonopenmodaladditempurchase" hidden><i class="fa fa-plus me-2"></i></a>
  <additempurchasemodal :marketingdata="data_marketing" :kategoridata="data_kategori" :itemdata="data_item_filter"
    :warehousedata="data_warehouse" @id_kategori_selected="handleKategoriSelected"
    @id_item_selected="handleItemSelected" :isMarketing="isMarketing" @item_object="handleItemAdd"
    :itemEdited="itemEdited">
  </additempurchasemodal> -->


  <!-- upload form -->
    <!-- <label class="mb-">Product Image</label>
    <div class="custom-file-container" data-upload-id="product-image">
      <label v-show="isGambar == true" @click="isGambar = false">Remove
        <a href="javascript:void(0)" class="custom-file-container__image-clear"
          title="Clear Image">x</a></label>
      <label class="custom-file-container__custom-file">
        <input type="file" @change="updateIsGambar()" ref="gambar"
          class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
        <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
        <span class="custom-file-container__custom-file__custom-file-control"></span>
      </label>
      <div class="custom-file-container__image-preview"></div>
    </div> -->


</template>
<style scoped>
.order-total {

  color: #637381;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-right: 1px solid #f8f8f8;
  background: #fafbfe;
}

.order-amount {
  color: #212b36;
  font-size: 14px;
  padding: 10px;
  font-weight: 700;
  text-align: right;

}

.menu-top {
    background-color: rgba(214, 184, 132, 0.3); /* Adjust opacity and color as needed */
    border-left: 4px solid #D6B884; /* Primary color (Bootstrap default) */
    color: black; /* Dark text color (Bootstrap default) */
    font-weight: 600;
    border-radius: 0.125rem;
  }

  .custom-width {
    width: 400px;
  }

  .custom-select {
    width: 250px;
  }

  .custom-active {
    overflow: auto;
    max-height: 16rem;
  }

  .form-control {
    text-transform: none !important;
  }
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiah, rupiahInput } from "@/assets/js/function";
// import modaladditem from "./modaladditem";
import { ModelSelect } from 'vue-search-select';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect
  },
  name: "edit Invoice",
  data() {
    return {
      filter: true,
      title: "Tambah Permintaan Pembelian",
      title1: "Tambah Permintaan Pembelian",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentDate: new Date,
      currentData: {
        // suplier_detail: {
        //   id: "",
        // },
        // invoice_detail: [],
        // invoice_payment: [],
        // discount_type: "rupiah",
        // calculated_discount: "0",
        // discount_amount: 0,
        // tax_amount: 0,
        // tax_rate: 0,
        // tax_name: "PPN",
        // tax_type: "persen",
        // grand_total: 0,
        // bank_name: "",
        // po_number: "",
        // code_project: "",
        // quotation_note: "",
        // quotation_term: "",
        product_list: [],
        date: '',
        // status: null,


      },

      needData1: [],

      // items: [{
      //   nama_produk: '',
      //   sku: '',
      //   qty: '', 
      //   satuan: '', 
      //   harga: '',
      //   diskon: '',
      //   total_harga: '',
      // }],
      statusUmum: [],

      statusList: [
        { label: "Aktif", value: "aktif" },
        { label: "Tidak Aktif", value: "tidak aktif" },
      ],

      data_suplier: [],
      data_item: [],
      data_kategori: [],
      data_marketing: [],
      data_payments: [],
      data_bank: [],
      // data_list_items_in_warehouse: [],
      data_warehouse: [],
      data_ekspedisi: [],


      //to modaladditem
      // data_item_filter: [],
      // isMarketing: false,
      // itemEdited: {
      //   marketing_id: "",
      //   kategori_id: "",
      //   item_id: "",

      // },
      // paymentEdited: {
      //   id: "",
      //   payment_method: "",
      //   payment_amount: "",
      //   payment_notes: "",
      //   payment_date: new Date(),
      // },

      gambar_file_64: "",
      isGambar: false,



      // cek deposit
      // status_item_deposit: false,

      // //cek payment
      // payment_active: "",

      // //test 
      // batas_payment_edit: 0,

      // //rate 
      // rate_active: "",

      // pass_data: {
      //   from: "",
      // },

      // //bank
      // bankCek: false,
      // bank_selected: "",

      active_page: "umum",
      isActive: true,

    };
  },

  mounted() {
    // var FileUploadWithPreview = (function () {
    //   "use strict";
    //   var A,
    //     e =
    //       ((function (A) {
    //         var e = (function (A) {
    //           var e,
    //             t = Object.prototype,
    //             g = t.hasOwnProperty,
    //             n = "function" == typeof Symbol ? Symbol : {},
    //             i = n.iterator || "@@iterator",
    //             B = n.asyncIterator || "@@asyncIterator",
    //             E = n.toStringTag || "@@toStringTag";
    //           function r(A, e, t, g) {
    //             var n = e && e.prototype instanceof c ? e : c,
    //               i = Object.create(n.prototype),
    //               B = new k(g || []);
    //             return (
    //               (i._invoke = (function (A, e, t) {
    //                 var g = o;
    //                 return function (n, i) {
    //                   if (g === Q) throw new Error("Generator is already running");
    //                   if (g === a) {
    //                     if ("throw" === n) throw i;
    //                     return R();
    //                   }
    //                   for (t.method = n, t.arg = i; ;) {
    //                     var B = t.delegate;
    //                     if (B) {
    //                       var E = S(B, t);
    //                       if (E) {
    //                         if (E === s) continue;
    //                         return E;
    //                       }
    //                     }
    //                     if ("next" === t.method) t.sent = t._sent = t.arg;
    //                     else if ("throw" === t.method) {
    //                       if (g === o) throw ((g = a), t.arg);
    //                       t.dispatchException(t.arg);
    //                     } else "return" === t.method && t.abrupt("return", t.arg);
    //                     g = Q;
    //                     var r = C(A, e, t);
    //                     if ("normal" === r.type) {
    //                       if (((g = t.done ? a : I), r.arg === s)) continue;
    //                       return { value: r.arg, done: t.done };
    //                     }
    //                     "throw" === r.type &&
    //                       ((g = a), (t.method = "throw"), (t.arg = r.arg));
    //                   }
    //                 };
    //               })(A, t, B)),
    //               i
    //             );
    //           }
    //           function C(A, e, t) {
    //             try {
    //               return { type: "normal", arg: A.call(e, t) };
    //             } catch (A) {
    //               return { type: "throw", arg: A };
    //             }
    //           }
    //           A.wrap = r;
    //           var o = "suspendedStart",
    //             I = "suspendedYield",
    //             Q = "executing",
    //             a = "completed",
    //             s = {};
    //           function c() { }
    //           function u() { }
    //           function l() { }
    //           var h = {};
    //           h[i] = function () {
    //             return this;
    //           };
    //           var m = Object.getPrototypeOf,
    //             f = m && m(m(J([])));
    //           f && f !== t && g.call(f, i) && (h = f);
    //           var d = (l.prototype = c.prototype = Object.create(h));
    //           function p(A) {
    //             ["next", "throw", "return"].forEach(function (e) {
    //               A[e] = function (A) {
    //                 return this._invoke(e, A);
    //               };
    //             });
    //           }
    //           function v(A) {
    //             var e;
    //             this._invoke = function (t, n) {
    //               function i() {
    //                 return new Promise(function (e, i) {
    //                   !(function e(t, n, i, B) {
    //                     var E = C(A[t], A, n);
    //                     if ("throw" !== E.type) {
    //                       var r = E.arg,
    //                         o = r.value;
    //                       return o && "object" == typeof o && g.call(o, "__await")
    //                         ? Promise.resolve(o.__await).then(
    //                           function (A) {
    //                             e("next", A, i, B);
    //                           },
    //                           function (A) {
    //                             e("throw", A, i, B);
    //                           }
    //                         )
    //                         : Promise.resolve(o).then(
    //                           function (A) {
    //                             (r.value = A), i(r);
    //                           },
    //                           function (A) {
    //                             return e("throw", A, i, B);
    //                           }
    //                         );
    //                     }
    //                     B(E.arg);
    //                   })(t, n, e, i);
    //                 });
    //               }
    //               return (e = e ? e.then(i, i) : i());
    //             };
    //           }
    //           function S(A, t) {
    //             var g = A.iterator[t.method];
    //             if (g === e) {
    //               if (((t.delegate = null), "throw" === t.method)) {
    //                 if (
    //                   A.iterator.return &&
    //                   ((t.method = "return"), (t.arg = e), S(A, t), "throw" === t.method)
    //                 )
    //                   return s;
    //                 (t.method = "throw"),
    //                   (t.arg = new TypeError(
    //                     "The iterator does not provide a 'throw' method"
    //                   ));
    //               }
    //               return s;
    //             }
    //             var n = C(g, A.iterator, t.arg);
    //             if ("throw" === n.type)
    //               return (t.method = "throw"), (t.arg = n.arg), (t.delegate = null), s;
    //             var i = n.arg;
    //             return i
    //               ? i.done
    //                 ? ((t[A.resultName] = i.value),
    //                   (t.next = A.nextLoc),
    //                   "return" !== t.method && ((t.method = "next"), (t.arg = e)),
    //                   (t.delegate = null),
    //                   s)
    //                 : i
    //               : ((t.method = "throw"),
    //                 (t.arg = new TypeError("iterator result is not an object")),
    //                 (t.delegate = null),
    //                 s);
    //           }
    //           function y(A) {
    //             var e = { tryLoc: A[0] };
    //             1 in A && (e.catchLoc = A[1]),
    //               2 in A && ((e.finallyLoc = A[2]), (e.afterLoc = A[3])),
    //               this.tryEntries.push(e);
    //           }
    //           function w(A) {
    //             var e = A.completion || {};
    //             (e.type = "normal"), delete e.arg, (A.completion = e);
    //           }
    //           function k(A) {
    //             (this.tryEntries = [{ tryLoc: "root" }]),
    //               A.forEach(y, this),
    //               this.reset(!0);
    //           }
    //           function J(A) {
    //             if (A) {
    //               var t = A[i];
    //               if (t) return t.call(A);
    //               if ("function" == typeof A.next) return A;
    //               if (!isNaN(A.length)) {
    //                 var n = -1,
    //                   B = function t() {
    //                     for (; ++n < A.length;)
    //                       if (g.call(A, n)) return (t.value = A[n]), (t.done = !1), t;
    //                     return (t.value = e), (t.done = !0), t;
    //                   };
    //                 return (B.next = B);
    //               }
    //             }
    //             return { next: R };
    //           }
    //           function R() {
    //             return { value: e, done: !0 };
    //           }
    //           return (
    //             (u.prototype = d.constructor = l),
    //             (l.constructor = u),
    //             (l[E] = u.displayName = "GeneratorFunction"),
    //             (A.isGeneratorFunction = function (A) {
    //               var e = "function" == typeof A && A.constructor;
    //               return (
    //                 !!e && (e === u || "GeneratorFunction" === (e.displayName || e.name))
    //               );
    //             }),
    //             (A.mark = function (A) {
    //               return (
    //                 Object.setPrototypeOf
    //                   ? Object.setPrototypeOf(A, l)
    //                   : ((A.__proto__ = l), E in A || (A[E] = "GeneratorFunction")),
    //                 (A.prototype = Object.create(d)),
    //                 A
    //               );
    //             }),
    //             (A.awrap = function (A) {
    //               return { __await: A };
    //             }),
    //             p(v.prototype),
    //             (v.prototype[B] = function () {
    //               return this;
    //             }),
    //             (A.AsyncIterator = v),
    //             (A.async = function (e, t, g, n) {
    //               var i = new v(r(e, t, g, n));
    //               return A.isGeneratorFunction(t)
    //                 ? i
    //                 : i.next().then(function (A) {
    //                   return A.done ? A.value : i.next();
    //                 });
    //             }),
    //             p(d),
    //             (d[E] = "Generator"),
    //             (d[i] = function () {
    //               return this;
    //             }),
    //             (d.toString = function () {
    //               return "[object Generator]";
    //             }),
    //             (A.keys = function (A) {
    //               var e = [];
    //               for (var t in A) e.push(t);
    //               return (
    //                 e.reverse(),
    //                 function t() {
    //                   for (; e.length;) {
    //                     var g = e.pop();
    //                     if (g in A) return (t.value = g), (t.done = !1), t;
    //                   }
    //                   return (t.done = !0), t;
    //                 }
    //               );
    //             }),
    //             (A.values = J),
    //             (k.prototype = {
    //               constructor: k,
    //               reset: function (A) {
    //                 if (
    //                   ((this.prev = 0),
    //                     (this.next = 0),
    //                     (this.sent = this._sent = e),
    //                     (this.done = !1),
    //                     (this.delegate = null),
    //                     (this.method = "next"),
    //                     (this.arg = e),
    //                     this.tryEntries.forEach(w),
    //                     !A)
    //                 )
    //                   for (var t in this)
    //                     "t" === t.charAt(0) &&
    //                       g.call(this, t) &&
    //                       !isNaN(+t.slice(1)) &&
    //                       (this[t] = e);
    //               },
    //               stop: function () {
    //                 this.done = !0;
    //                 var A = this.tryEntries[0].completion;
    //                 if ("throw" === A.type) throw A.arg;
    //                 return this.rval;
    //               },
    //               dispatchException: function (A) {
    //                 if (this.done) throw A;
    //                 var t = this;
    //                 function n(g, n) {
    //                   return (
    //                     (E.type = "throw"),
    //                     (E.arg = A),
    //                     (t.next = g),
    //                     n && ((t.method = "next"), (t.arg = e)),
    //                     !!n
    //                   );
    //                 }
    //                 for (var i = this.tryEntries.length - 1; i >= 0; --i) {
    //                   var B = this.tryEntries[i],
    //                     E = B.completion;
    //                   if ("root" === B.tryLoc) return n("end");
    //                   if (B.tryLoc <= this.prev) {
    //                     var r = g.call(B, "catchLoc"),
    //                       C = g.call(B, "finallyLoc");
    //                     if (r && C) {
    //                       if (this.prev < B.catchLoc) return n(B.catchLoc, !0);
    //                       if (this.prev < B.finallyLoc) return n(B.finallyLoc);
    //                     } else if (r) {
    //                       if (this.prev < B.catchLoc) return n(B.catchLoc, !0);
    //                     } else {
    //                       if (!C)
    //                         throw new Error("try statement without catch or finally");
    //                       if (this.prev < B.finallyLoc) return n(B.finallyLoc);
    //                     }
    //                   }
    //                 }
    //               },
    //               abrupt: function (A, e) {
    //                 for (var t = this.tryEntries.length - 1; t >= 0; --t) {
    //                   var n = this.tryEntries[t];
    //                   if (
    //                     n.tryLoc <= this.prev &&
    //                     g.call(n, "finallyLoc") &&
    //                     this.prev < n.finallyLoc
    //                   ) {
    //                     var i = n;
    //                     break;
    //                   }
    //                 }
    //                 i &&
    //                   ("break" === A || "continue" === A) &&
    //                   i.tryLoc <= e &&
    //                   e <= i.finallyLoc &&
    //                   (i = null);
    //                 var B = i ? i.completion : {};
    //                 return (
    //                   (B.type = A),
    //                   (B.arg = e),
    //                   i
    //                     ? ((this.method = "next"), (this.next = i.finallyLoc), s)
    //                     : this.complete(B)
    //                 );
    //               },
    //               complete: function (A, e) {
    //                 if ("throw" === A.type) throw A.arg;
    //                 return (
    //                   "break" === A.type || "continue" === A.type
    //                     ? (this.next = A.arg)
    //                     : "return" === A.type
    //                       ? ((this.rval = this.arg = A.arg),
    //                         (this.method = "return"),
    //                         (this.next = "end"))
    //                       : "normal" === A.type && e && (this.next = e),
    //                   s
    //                 );
    //               },
    //               finish: function (A) {
    //                 for (var e = this.tryEntries.length - 1; e >= 0; --e) {
    //                   var t = this.tryEntries[e];
    //                   if (t.finallyLoc === A)
    //                     return this.complete(t.completion, t.afterLoc), w(t), s;
    //                 }
    //               },
    //               catch: function (A) {
    //                 for (var e = this.tryEntries.length - 1; e >= 0; --e) {
    //                   var t = this.tryEntries[e];
    //                   if (t.tryLoc === A) {
    //                     var g = t.completion;
    //                     if ("throw" === g.type) {
    //                       var n = g.arg;
    //                       w(t);
    //                     }
    //                     return n;
    //                   }
    //                 }
    //                 throw new Error("illegal catch attempt");
    //               },
    //               delegateYield: function (A, t, g) {
    //                 return (
    //                   (this.delegate = { iterator: J(A), resultName: t, nextLoc: g }),
    //                   "next" === this.method && (this.arg = e),
    //                   s
    //                 );
    //               },
    //             }),
    //             A
    //           );
    //         })(A.exports);
    //         try {
    //           regeneratorRuntime = e;
    //         } catch (A) {
    //           Function("r", "regeneratorRuntime = r")(e);
    //         }
    //       })((A = { exports: {} }), A.exports),
    //         A.exports);
    //   function t(A, e, t, g, n, i, B) {
    //     try {
    //       var E = A[i](B),
    //         r = E.value;
    //     } catch (A) {
    //       return void t(A);
    //     }
    //     E.done ? e(r) : Promise.resolve(r).then(g, n);
    //   }
    //   var g = function (A) {
    //     return function () {
    //       var e = this,
    //         g = arguments;
    //       return new Promise(function (n, i) {
    //         var B = A.apply(e, g);
    //         function E(A) {
    //           t(B, n, i, E, r, "next", A);
    //         }
    //         function r(A) {
    //           t(B, n, i, E, r, "throw", A);
    //         }
    //         E(void 0);
    //       });
    //     };
    //   };
    //   var n = function (A, e) {
    //     if (!(A instanceof e)) throw new TypeError("Cannot call a class as a function");
    //   };
    //   function i(A, e) {
    //     for (var t = 0; t < e.length; t++) {
    //       var g = e[t];
    //       (g.enumerable = g.enumerable || !1),
    //         (g.configurable = !0),
    //         "value" in g && (g.writable = !0),
    //         Object.defineProperty(A, g.key, g);
    //     }
    //   }
    //   var B = function (A, e, t) {
    //     return e && i(A.prototype, e), t && i(A, t), A;
    //   };
    //   return (
    //     Element.prototype.matches ||
    //     (Element.prototype.matches =
    //       Element.prototype.matchesSelector ||
    //       Element.prototype.mozMatchesSelector ||
    //       Element.prototype.msMatchesSelector ||
    //       Element.prototype.oMatchesSelector ||
    //       Element.prototype.webkitMatchesSelector ||
    //       function (A) {
    //         for (
    //           var e = (this.document || this.ownerDocument).querySelectorAll(A),
    //           t = e.length;
    //           --t >= 0 && e.item(t) !== this;

    //         );
    //         return t > -1;
    //       }),
    //     Array.prototype.findIndex ||
    //     Object.defineProperty(Array.prototype, "findIndex", {
    //       value: function (A) {
    //         if (null == this) throw new TypeError('"this" is null or not defined');
    //         var e = Object(this),
    //           t = e.length >>> 0;
    //         if ("function" != typeof A)
    //           throw new TypeError("predicate must be a function");
    //         for (var g = arguments[1], n = 0; n < t;) {
    //           var i = e[n];
    //           if (A.call(g, i, n, e)) return n;
    //           n++;
    //         }
    //         return -1;
    //       },
    //       configurable: !0,
    //       writable: !0,
    //     }),
    //     (function () {
    //       if ("function" == typeof window.CustomEvent) return !1;
    //       function A(A, e) {
    //         e = e || { bubbles: !1, cancelable: !1, detail: null };
    //         var t = document.createEvent("CustomEvent");
    //         return t.initCustomEvent(A, e.bubbles, e.cancelable, e.detail), t;
    //       }
    //       (A.prototype = window.Event.prototype), (window.CustomEvent = A);
    //     })(),
    //     (function () {
    //       function A(e, t) {
    //         if ((n(this, A), !e))
    //           throw new Error(
    //             "No uploadId found. You must initialize file-upload-with-preview with a unique uploadId."
    //           );
    //         if (
    //           ((this.uploadId = e),
    //             (this.options = t || {}),
    //             (this.options.showDeleteButtonOnImages =
    //               !this.options.hasOwnProperty("showDeleteButtonOnImages") ||
    //               this.options.showDeleteButtonOnImages),
    //             (this.options.text = this.options.hasOwnProperty("text")
    //               ? this.options.text
    //               : { chooseFile: "Choose file..." }),
    //             (this.options.text.chooseFile = this.options.text.hasOwnProperty(
    //               "chooseFile"
    //             )
    //               ? this.options.text.chooseFile
    //               : "Choose file..."),
    //             (this.options.text.browse = this.options.text.hasOwnProperty("pilih gambar")
    //               ? this.options.text.browse
    //               : "Pilih Gambar"),
    //             (this.options.text.selectedCount = this.options.text.hasOwnProperty(
    //               "selectedCount"
    //             )
    //               ? this.options.text.selectedCount
    //               : "files selected"),
    //             (this.cachedFileArray = []),
    //             (this.currentFileCount = 0),
    //             (this.el = document.querySelector(
    //               '.custom-file-container[data-upload-id="'.concat(this.uploadId, '"]')
    //             )),
    //             !this.el)
    //         )
    //           throw new Error(
    //             "Could not find a 'custom-file-container' with the id of: ".concat(
    //               this.uploadId
    //             )
    //           );
    //         if (
    //           ((this.input = this.el.querySelector('input[type="file"]')),
    //             (this.inputLabel = this.el.querySelector(
    //               ".custom-file-container__custom-file__custom-file-control"
    //             )),
    //             (this.imagePreview = this.el.querySelector(
    //               ".custom-file-container__image-preview"
    //             )),
    //             (this.clearButton = this.el.querySelector(
    //               ".custom-file-container__image-clear"
    //             )),
    //             (this.inputLabel.innerHTML = this.options.text.chooseFile),
    //             this.addBrowseButton(this.options.text.browse),
    //             !(
    //               this.el &&
    //               this.input &&
    //               this.inputLabel &&
    //               this.imagePreview &&
    //               this.clearButton
    //             ))
    //         )
    //           throw new Error(
    //             "Cannot find all necessary elements. Please make sure you have all the necessary elements in your html for the id: ".concat(
    //               this.uploadId
    //             )
    //           );
    //         (this.options.images = this.options.hasOwnProperty("images")
    //           ? this.options.images
    //           : {}),
    //           (this.baseImage = this.options.images.hasOwnProperty("baseImage")
    //             ? this.options.images.baseImage

    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.successPdfImage = this.options.images.hasOwnProperty(
    //             "successPdfImage"
    //           )
    //             ? this.options.images.successPdfImage
    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.successVideoImage = this.options.images.hasOwnProperty(
    //             "successVideoImage"
    //           )
    //             ? this.options.images.successVideoImage
    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.successFileAltImage = this.options.images.hasOwnProperty(
    //             "successFileAltImage"
    //           )
    //             ? this.options.images.successFileAltImage
    //             : "https://s8-api.arthaskyline.com/images/default-images.png"),
    //           (this.backgroundImage = this.options.images.hasOwnProperty(
    //             "backgroundImage"
    //           )
    //             ? this.options.images.backgroundImage
    //             : ""),
    //           this.bindClickEvents(),
    //           (this.imagePreview.style.backgroundImage = 'url("'.concat(
    //             this.baseImage,
    //             '")'
    //           )),
    //           (this.options.presetFiles = this.options.hasOwnProperty("presetFiles")
    //             ? this.options.presetFiles
    //             : null),
    //           this.options.presetFiles &&
    //           this.addImagesFromPath(this.options.presetFiles)
    //             .then(function () { })
    //             .catch(function (A) {
    //               console.log("Error - " + A.toString()),
    //                 console.log(
    //                   "Warning - An image you added from a path is not able to be added to the cachedFileArray."
    //                 );
    //             });
    //       }
    //       return (
    //         B(A, [
    //           {
    //             key: "bindClickEvents",
    //             value: function () {
    //               var A = this,
    //                 e = this;
    //               e.input.addEventListener(
    //                 "change",
    //                 function () {
    //                   e.addFiles(this.files);
    //                 },
    //                 !0
    //               ),
    //                 this.clearButton.addEventListener(
    //                   "click",
    //                   function () {
    //                     A.clearPreviewPanel();
    //                   },
    //                   !0
    //                 ),
    //                 this.imagePreview.addEventListener("click", function (e) {
    //                   if (
    //                     e.target.matches(
    //                       ".custom-file-container__image-multi-preview__single-image-clear__icon"
    //                     )
    //                   ) {
    //                     var t = e.target.getAttribute("data-upload-token"),
    //                       g = A.cachedFileArray.findIndex(function (A) {
    //                         return A.token === t;
    //                       });
    //                     A.deleteFileAtIndex(g);
    //                   }
    //                 });
    //             },
    //           },
    //           {
    //             key: "addFiles",
    //             value: function (A) {
    //               if (0 !== A.length) {
    //                 this.input.multiple
    //                   ? (this.currentFileCount += A.length)
    //                   : ((this.currentFileCount = A.length), (this.cachedFileArray = []));
    //                 for (var e = 0; e < A.length; e++) {
    //                   var t = A[e];
    //                   (t.token =
    //                     Math.random().toString(36).substring(2, 15) +
    //                     Math.random().toString(36).substring(2, 15)),
    //                     this.cachedFileArray.push(t),
    //                     this.processFile(t);
    //                 }
    //                 var g = new CustomEvent("fileUploadWithPreview:imagesAdded", {
    //                   detail: {
    //                     uploadId: this.uploadId,
    //                     cachedFileArray: this.cachedFileArray,
    //                     addedFilesCount: A.length,
    //                   },
    //                 });
    //                 window.dispatchEvent(g);
    //               }
    //             },
    //           },
    //           {
    //             key: "processFile",
    //             value: function (A) {
    //               var e = this;
    //               0 === this.currentFileCount
    //                 ? (this.inputLabel.innerHTML = this.options.text.chooseFile)
    //                 : 1 === this.currentFileCount
    //                   ? (this.inputLabel.innerHTML = A.name)
    //                   : (this.inputLabel.innerHTML = ""
    //                     .concat(this.currentFileCount, " ")
    //                     .concat(this.options.text.selectedCount)),
    //                 this.addBrowseButton(this.options.text.browse),
    //                 this.imagePreview.classList.add(
    //                   "custom-file-container__image-preview--active"
    //                 );
    //               var t = new FileReader();
    //               t.readAsDataURL(A),
    //                 (t.onload = function () {
    //                   e.input.multiple ||
    //                     (A.type.match("image/png") ||
    //                       A.type.match("image/jpeg") ||
    //                       A.type.match("image/gif")
    //                       ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                         t.result,
    //                         '")'
    //                       ))
    //                       : A.type.match("application/pdf")
    //                         ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                           e.successPdfImage,
    //                           '")'
    //                         ))
    //                         : A.type.match("video/*")
    //                           ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                             e.successVideoImage,
    //                             '")'
    //                           ))
    //                           : (e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                             e.successFileAltImage,
    //                             '")'
    //                           ))),
    //                     e.input.multiple &&
    //                     ((e.imagePreview.style.backgroundImage = 'url("'.concat(
    //                       e.backgroundImage,
    //                       '")'
    //                     )),
    //                       A.type.match("image/png") ||
    //                         A.type.match("image/jpeg") ||
    //                         A.type.match("image/gif")
    //                         ? e.options.showDeleteButtonOnImages
    //                           ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                             .concat(
    //                               A.token,
    //                               '"\n                                style="background-image: url(\''
    //                             )
    //                             .concat(
    //                               t.result,
    //                               '\'); "\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                             )
    //                             .concat(
    //                               A.token,
    //                               '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                             ))
    //                           : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                             .concat(
    //                               A.token,
    //                               '"\n                                style="background-image: url(\''
    //                             )
    //                             .concat(
    //                               t.result,
    //                               "'); \"\n                            ></div>\n                        "
    //                             ))
    //                         : A.type.match("application/pdf")
    //                           ? e.options.showDeleteButtonOnImages
    //                             ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                               .concat(
    //                                 A.token,
    //                                 '"\n                                style="background-image: url(\''
    //                               )
    //                               .concat(
    //                                 e.successPdfImage,
    //                                 '\'); "\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                               )
    //                               .concat(
    //                                 A.token,
    //                                 '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                               ))
    //                             : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
    //                               .concat(
    //                                 A.token,
    //                                 '"\n                                style="background-image: url(\''
    //                               )
    //                               .concat(
    //                                 e.successPdfImage,
    //                                 "'); \"\n                            ></div>\n                        "
    //                               ))
    //                           : A.type.match("video/*")
    //                             ? e.options.showDeleteButtonOnImages
    //                               ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successVideoImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                                 ))
    //                               : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successVideoImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            ></div>\n                        '
    //                                 ))
    //                             : e.options.showDeleteButtonOnImages
    //                               ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successFileAltImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
    //                                 ))
    //                               : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
    //                                 .concat(
    //                                   e.successFileAltImage,
    //                                   '\'); "\n                                data-upload-token="'
    //                                 )
    //                                 .concat(
    //                                   A.token,
    //                                   '"\n                            ></div>\n                        '
    //                                 )));
    //                 });
    //             },
    //           },
    //           {
    //             key: "addImagesFromPath",
    //             value: function (A) {
    //               var t = this;
    //               return new Promise(
    //                 (function () {
    //                   var n = g(
    //                     e.mark(function g(n, i) {
    //                       var B, E, r, C, o;
    //                       return e.wrap(
    //                         function (e) {
    //                           for (; ;)
    //                             switch ((e.prev = e.next)) {
    //                               case 0:
    //                                 (B = []), (E = 0);
    //                               case 2:
    //                                 if (!(E < A.length)) {
    //                                   e.next = 24;
    //                                   break;
    //                                 }
    //                                 return (
    //                                   (r = void 0),
    //                                   (C = void 0),
    //                                   (e.prev = 5),
    //                                   (e.next = 8),
    //                                   fetch(A[E], { mode: "cors" })
    //                                 );
    //                               case 8:
    //                                 return (r = e.sent), (e.next = 11), r.blob();
    //                               case 11:
    //                                 (C = e.sent), (e.next = 18);
    //                                 break;
    //                               case 14:
    //                                 return (
    //                                   (e.prev = 14),
    //                                   (e.t0 = e.catch(5)),
    //                                   i(e.t0),
    //                                   e.abrupt("continue", 21)
    //                                 );
    //                               case 18:
    //                                 ((o = new Blob([C], { type: C.type })).name = A[
    //                                   E
    //                                 ].split("/").pop()),
    //                                   B.push(o);
    //                               case 21:
    //                                 E++, (e.next = 2);
    //                                 break;
    //                               case 24:
    //                                 t.addFiles(B), n();
    //                               case 26:
    //                               case "end":
    //                                 return e.stop();
    //                             }
    //                         },
    //                         g,
    //                         null,
    //                         [[5, 14]]
    //                       );
    //                     })
    //                   );
    //                   return function (A, e) {
    //                     return n.apply(this, arguments);
    //                   };
    //                 })()
    //               );
    //             },
    //           },
    //           {
    //             key: "replaceFiles",
    //             value: function (A) {
    //               if (!A.length) throw new Error("Array must contain at least one file.");
    //               (this.cachedFileArray = A), this.refreshPreviewPanel();
    //             },
    //           },
    //           {
    //             key: "replaceFileAtIndex",
    //             value: function (A, e) {
    //               if (!A) throw new Error("No file found.");
    //               if (!this.cachedFileArray[e])
    //                 throw new Error("There is no file at index", e);
    //               (this.cachedFileArray[e] = A), this.refreshPreviewPanel();
    //             },
    //           },
    //           {
    //             key: "deleteFileAtIndex",
    //             value: function (A) {
    //               if (!this.cachedFileArray[A])
    //                 throw new Error("There is no file at index", A);
    //               this.cachedFileArray.splice(A, 1), this.refreshPreviewPanel();
    //               var e = new CustomEvent("fileUploadWithPreview:imageDeleted", {
    //                 detail: {
    //                   uploadId: this.uploadId,
    //                   cachedFileArray: this.cachedFileArray,
    //                   currentFileCount: this.currentFileCount,
    //                 },
    //               });
    //               window.dispatchEvent(e);
    //             },
    //           },
    //           {
    //             key: "refreshPreviewPanel",
    //             value: function () {
    //               var A = this;
    //               (this.imagePreview.innerHTML = ""),
    //                 (this.currentFileCount = this.cachedFileArray.length),
    //                 this.cachedFileArray.forEach(function (e) {
    //                   return A.processFile(e);
    //                 }),
    //                 this.cachedFileArray.length || this.clearPreviewPanel();
    //             },
    //           },
    //           {
    //             key: "addBrowseButton",
    //             value: function (A) {
    //               this.inputLabel.innerHTML += '<span class="custom-file-container__custom-file__custom-file-control__button"> '.concat(
    //                 A,
    //                 " </span>"
    //               );
    //             },
    //           },
    //           {
    //             key: "emulateInputSelection",
    //             value: function () {
    //               this.input.click();
    //             },
    //           },
    //           {
    //             key: "clearPreviewPanel",
    //             value: function () {
    //               (this.input.value = ""),
    //                 (this.inputLabel.innerHTML = this.options.text.chooseFile),
    //                 this.addBrowseButton(this.options.text.browse),
    //                 (this.imagePreview.style.backgroundImage = 'url("'.concat(
    //                   this.baseImage,
    //                   '")'
    //                 )),
    //                 this.imagePreview.classList.remove(
    //                   "custom-file-container__image-preview--active"
    //                 ),
    //                 (this.cachedFileArray = []),
    //                 (this.imagePreview.innerHTML = ""),
    //                 (this.currentFileCount = 0);
    //             },
    //           },
    //         ]),
    //         A
    //       );
    //     })()
    //   );
    // })();

    // First upload
    // let gambar2 = new FileUploadWithPreview("product-image");
    // this.$refs.gambar.style.backgroundImage = `url('https://s8-api.arthaskyline.com/images/default-images.png')`;

    this.handleStatusUmum()

    this.currentData.date = this.formatDate(new Date());

  },

  computed: {
    // totalAmount() {

    //   if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
    //     return 0;
    //   } else {
    //     const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.item_total), 0)
    //     return total
    //   }
    // },
    // total_payment() {
    //   if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
    //     return 0;
    //   } else {
    //     const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.payment_amount), 0)
    //     return total
    //   }
    // },

    // batas_payment() {
    //   let payment = 0;
    //   let amount = 0;
    //   if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
    //     payment = 0;
    //   } else {
    //     const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.payment_amount), 0)
    //     payment = total;
    //   }
    //   if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
    //     amount = 0;
    //   } else {
    //     const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.item_total), 0)
    //     amount = total
    //   }


    //   return amount - payment;
    // }

  },
  watch: {
    // 'bank_selected': {
    //   handler(newValue) {
    //     this.bankCek = false;
    //   }
    // },
    // 'currentData.suplier_detail.id': {
    //   handler(newValue) {
    //     this.currentData.invoice_detail = [];
    //     this.currentData.invoice_payment = [];

    //     this.cek_rate_active();

    //   }
    // },
    // 'currentData.discount_type': {
    //   handler(newValue) {
    //     if (this.currentData.calculated_discount == '' || (this.currentData.calculated_discount > 100 && newValue == 'persen')
    //       || (this.currentData.calculated_discount < 0) || (newValue == 'rupiah' && this.currentData.calculated_discount > this.totalAmount)) {
    //       this.currentData.calculated_discount = 0;
    //     }
    //     if (newValue == 'rupiah') {
    //       this.currentData.discount_amount = this.currentData.calculated_discount;
    //     } else if (newValue == 'persen' && this.totalAmount > 0) {
    //       this.currentData.discount_amount = parseInt(this.currentData.calculated_discount) * (parseInt(this.totalAmount)) / 100;
    //     } else {
    //       this.currentData.discount_amount = 0;
    //     }

    //     this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    //   }
    // },
    // 'currentData.calculated_discount': {
    //   handler(newValue) {
    //     if (this.currentData.calculated_discount == '' || (newValue > 100 && this.currentData.discount_type == 'persen')
    //       || newValue < 0 || (this.currentData.discount_type == 'rupiah' && newValue > this.totalAmount)) {
    //       this.currentData.calculated_discount = 0;
    //     }
    //     if (this.currentData.discount_type == 'rupiah') {
    //       this.currentData.discount_amount = newValue;
    //     } else if (this.currentData.discount_type == 'persen' && this.totalAmount > 0) {
    //       this.currentData.discount_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
    //     } else {
    //       this.currentData.discount_amount = 0;
    //     }


    //     this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    //   }
    // },
    // 'currentData.tax_rate': {
    //   handler(newValue) {
    //     if ((newValue > 100 && this.currentData.tax_type == 'persen') || newValue < 0) {
    //       this.currentData.tax_rate = 0;
    //     }

    //     if (this.currentData.tax_type == 'persen' && this.totalAmount > 0) {
    //       this.currentData.tax_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
    //     } else {
    //       this.currentData.tax_amount = 0;
    //     }
    //     this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    //   }
    // }
  },

  created() {
    ShowLoading();
    const token = Cookies.get("token_refresh");

    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    // get needed data
    this.getData();
    // this.currentData.invoice_date = new Date()
    // this.currentData.payment_date = new Date()
    // this.currentData.due_date = new Date()
    CloseLoading();




  },

  methods: {
    rupiah,
    activePage(page) {
      this.active_page = page;
      localStorage.setItem("active_page", page)
    },
    selectAll(event) {
      // Select all text when input field is focused
      event.target.select();
    },

    addNewRow() {
      this.currentData.product_list.push({
        name: '',
        sku: '',
        qty: '',
        satuan: '',
        date_request: '',
        
      });
    },

    goPageCallback(url, callback) {
      localStorage.setItem('callback', callback);
      goPage(url);

    },

    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    // cek_deposit() {
    //   const index = this.currentData.invoice_detail.findIndex((record) => record.isdeposit == 'AKTIF');
    //   const indexPayment = this.data_payments.findIndex((record) => record.nama == 'DEPOSIT');

    //   const indexsuplier = this.data_suplier.findIndex((record) => record.id == this.currentData.suplier_detail.id);
    //   if (index > -1) {
    //     this.status_item_deposit = true;
    //   } else {
    //     this.status_item_deposit = false;
    //   }

    //   if ((indexPayment > -1 && index > -1) || (indexsuplier > -1 && this.data_suplier[indexsuplier].amount_deposit <= 0)) {
    //     this.data_payments.splice(indexPayment, 1);
    //     if (this.currentData.payment_method == 'DEPOSIT') {
    //       this.currentData.payment_method = "";
    //     }
    //   } else if (indexsuplier > -1 && this.data_suplier[indexsuplier].amount_deposit > 0 && indexPayment < 0) {
    //     this.data_payments.push({ text: 'DEPOSIT (' + rupiah(this.data_suplier[indexsuplier].amount_deposit) + ')', value: this.data_payments.length, nama: 'DEPOSIT' })
    //   }

    // },
    // handleKategoriSelected(item) {
    //   if (item != "") {
    //     this.data_item_filter = this.dataItemsFilterKategori(item);
    //   } else {
    //     this.data_item_filter = this.data_item;
    //   }

    // },

    // dataItemsFilterKategori(id) {
    //   return this.data_item.filter(item => {
    //     // Apply your filtering condition
    //     if (item.id_kategori == id) {
    //       // Modify the 'value' attribute here
    //       item.text = item.nama;// Your modification logic here;
    //       return true; // Keep this item in the filtered result
    //     }
    //     return false; // Exclude this item from the filtered result
    //   });
    // },
    // handleItemSelected(item) {
    //   this.isMarketing = item;
    // },
    // handleItemAdd(item) {

    //   const index = this.data_item.findIndex((record) => record.id == item.id_items);
    //   const indexMarketing = this.data_marketing.findIndex((record) => record.id == item.id_marketing);
    //   const indexWarehouse = this.data_warehouse.findIndex((record) => record.id == item.id_warehouse);

    //   const indexKeranjangUpdate = this.currentData.invoice_detail.findIndex(
    //     (record) => record.id == item.id
    //   );

    //   const indexWarehouseInvoiceDetail = this.currentData.invoice_detail.findIndex(
    //     (record) => record.id_warehouse == item.id_warehouse && record.id_items == item.id_items
    //   );


    //   const rate_active = this.cek_rate_active();
    //   let nama_marketing = "";
    //   if (this.data_item[index].ismarketing == 'AKTIF' && this.data_marketing[indexMarketing] != undefined) {
    //     nama_marketing = this.data_marketing[indexMarketing].fullname;
    //   }

    //   let total_harga_item = 0;
    //   let total_discount = 0;
    //   if (item.discount_type == 'percent') {
    //     total_harga_item = item.harga - (item.harga * (item.discount / 100));
    //     total_discount = (item.harga * (item.discount / 100));
    //   } else {
    //     total_harga_item = item.harga - item.discount;
    //     total_discount = item.discount;
    //   }


    //   const itemPrepare = {
    //     ...item, name: this.data_item[index].nama,
    //     isdeposit: this.data_item[index].isdeposit,
    //     on_bungkus: this.data_item[index].on_bungkus,
    //     deskripsi: this.data_item[index].deskripsi,
    //     nama_marketing: nama_marketing,
    //     rate: item.harga,
    //     discount_type: item.discount_type,
    //     discount_amount: item.discount,
    //     calculated_discount: total_discount,
    //     item_total: item.qty * parseInt(total_harga_item),
    //     id_warehouse: item.id_warehouse,
    //     nama_gudang: this.data_warehouse[indexWarehouse].text,
    //   };

    //   this.cek_deposit();

    //   if (item.status == 'tambah') {

    //     if (indexWarehouseInvoiceDetail > -1) {
    //       AlertPopup('error', '', 'Produk dengan gudang tersebut sudah terdaftar', 2000, true);
    //       return true;
    //     }

    //     itemPrepare.id = this.currentData.invoice_detail.length;

    //     if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK') {
    //       this.currentData.invoice_detail.push(itemPrepare);
    //     } else if (this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF') {
    //       this.currentData.invoice_detail.push(itemPrepare);
    //     } else if (this.status_item_deposit == false && this.currentData.invoice_detail.length == 0 && this.data_item[index].isdeposit == 'AKTIF') {
    //       this.currentData.invoice_detail.push(itemPrepare);
    //     }
    //     else {
    //       AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
    //     }
    //   } else {




    //     if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK' && indexKeranjangUpdate > -1) {
    //       this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
    //     } else if ((this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1) || ((this.status_item_deposit == false && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1 && this.currentData.invoice_detail.length == 1))) {
    //       if (this.data_item[index].isdeposit == 'AKTIF' && this.currentData.invoice_detail.length == 1) {
    //         this.currentData.invoice_payment = [];
    //       }
    //       this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;

    //     } else if (indexKeranjangUpdate == -1) {
    //       AlertPopup('error', '', 'item tidak ditemukan', 2000, true);
    //     }
    //     else {
    //       AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
    //     }
    //   }
    //   this.cek_deposit();

    //   this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;

    // },
    // cek_rate_active() {
    //   let status = "rate";
    //   if (this.currentData.suplier_detail.id == "0" || this.currentData.suplier_detail.id == 0) {
    //     status = 'rate';
    //   } else {
    //     const indexsuplier = this.data_suplier.findIndex((record) => record.id === this.currentData.suplier_detail.id);
    //     if (this.data_suplier[indexsuplier].rate == "0") {
    //       status = "rate";
    //     } else {
    //       status = 'rate' + this.data_suplier[indexsuplier].rate;
    //     }
    //   }
    //   this.rate_active = status;
    //   return status;
    // },
    // deleteItem(indexToRemove) {
    //   this.currentData.invoice_detail.splice(indexToRemove, 1);
    //   this.cek_deposit();
    //   if (this.currentData.invoice_detail.length == 0) {
    //     this.currentData.invoice_payment = [];
    //   }
    //   this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    // },

    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result); // Resolve with the result after loading
        };
        reader.onerror = reject; // Reject if an error occurs
        reader.readAsDataURL(file);
      });
    },

    updateIsGambar() {
      if (this.$refs.gambar.value != "") {
        this.isGambar = true;
      } else {
        this.isGambar = false;
      }

      const file = this.$refs.gambar.files[0];
      if (file) {
        this.convertToBase64(file).then(base64String => {
          this.gambar_file_64 = base64String;
        });
      }

    },

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },

    handleStatusUmum() {
      const hardcodedStatusUmum = [
        { id: "DIAJUKAN", nama: 'Diajukan' },
        { id: "DITOLAK", nama: 'Ditolak' },
        { id: "DIPROSES", nama: 'Diproses' },
        
      ];

      hardcodedStatusUmum.forEach((item) => {
        this.statusUmum.push({
          value: item.id,
          text: item.nama,
        });
      });
    },

    getData() {

      // this.data_suplier.push({ id: 0, text: 'Walk in', rate: "0", value: "0" });

      // data departemen
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.nama };
        this.needData1.push(item_prepare)
      });

      // const suplier = JSON.parse(localStorage.getItem('suplier'));
      // suplier.forEach((item, index) => {
      //   let item_prepare = { ...item, value: item.id, text: item.fullname };
      //   this.data_suplier.push(item_prepare)
      // });




      //get data kategori 
      // const kategori = JSON.parse(localStorage.getItem('kategori'));
      // kategori.forEach((item, index) => {
      //   let item_prepare = { ...item, value: item.id, text: item.nama };
      //   this.data_kategori.push(item_prepare)
      // });

      // const item = JSON.parse(localStorage.getItem('item'));
      // item.forEach((item, index) => {
      //   let item_prepare = { ...item, value: item.id, text: item.nama, };
      //   this.data_item.push(item_prepare)
      // });

      // console.log(this.data_item);

      // const list_items_in_warehouse = JSON.parse(localStorage.getItem('list_items_in_warehouse'));
      // list_items_in_warehouse.forEach((item, index) => {
      //   let item_prepare = { ...item, value: item.id, text: item.id_warehouse, };
      //   this.data_list_items_in_warehouse.push(item_prepare)
      // });

      // const data_warehouse = JSON.parse(localStorage.getItem('warehouse'));
      // data_warehouse.forEach((item, index) => {
      //   let item_prepare = { ...item, value: item.id, text: item.nama, };
      //   this.data_warehouse.push(item_prepare)
      // });

      // const data_ekspedisi = JSON.parse(localStorage.getItem('ekspedisi'));
      // data_ekspedisi.forEach((item, index) => {
      //   let item_prepare = { ...item, value: item.id, text: item.nama, };
      //   this.data_ekspedisi.push(item_prepare)
      // });





      // if (localStorage.getItem('pass_data') != null) {
      //   this.pass_data = JSON.parse(localStorage.getItem('pass_data'));

      //   if (this.pass_data.from == 'suplier') {
      //     const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
      //     this.data_suplier.unshift(item_prepare);

      //   } else if (this.pass_data.from == 'marketing') {
      //     const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
      //     this.data_marketing.unshift(item_prepare);

      //   }

      // }

      // if (localStorage.getItem('pass_data') != null) {
      //   if (this.pass_data.from == 'marketing') {
      //     localStorage.setItem('marketing', JSON.stringify(this.data_marketing));
      //   } else if (this.pass_data.from == 'suplier') {
      //     localStorage.setItem('suplier', JSON.stringify(this.data_suplier));
      //   }
      //   localStorage.removeItem('pass_data');
      // }




    },
    // addItem() {
    //   if (this.currentData.suplier_detail.id == '') {
    //     AlertPopup('error', '', 'Silahkan Pilih Pemasok terebih dahulu ', 2000, true);
    //     return true;
    //   }

    //   this.itemEdited = {
    //     marketing_id: "",
    //     kategori_id: "",
    //     id_warehouse: "",
    //     item_id: "",
    //     qty: 1,
    //     status: 'tambah',
    //     discount_type: 'percent',
    //     harga: 0,
    //     discount: 0,
    //   }



    //   const openButton = this.$refs.buttonopenmodaladditempurchase;
    //   openButton.click();

    // },
    // editItem(item) {

    //   const index = this.data_item.findIndex((record) => record.id == item.id_items);
    //   const indexKategori = this.data_kategori.findIndex((record) => record.id == this.data_item[index].id_kategori);

    //   this.itemEdited = {
    //     marketing_id: item.id_marketing,
    //     kategori_id: this.data_kategori[indexKategori].id,
    //     item_id: item.id_items,
    //     qty: item.qty,
    //     status: 'edit',
    //     harga: parseInt(item.rate),
    //     discount_type: item.discount_type,
    //     discount_amount: parseInt(item.discount_amount),
    //     id_warehouse: item.id_warehouse,
    //     id: item.id,
    //   }
    //   const openButton = this.$refs.buttonopenmodaladditempurchase;
    //   openButton.click();
    // },



    //payment
    // handlePaymentAdd(item) {

    //   const indexKeranjangUpdate = this.currentData.invoice_payment.findIndex(
    //     (record) => record.id == item.id
    //   );
    //   item.payment_method = this.data_payments[item.payment_method].nama;

    //   if (item.status == 'tambah') {
    //     item.id = this.currentData.invoice_payment.length;

    //     this.currentData.invoice_payment.push(item);
    //   } else {
    //     this.currentData.invoice_payment[indexKeranjangUpdate] = item;
    //   }
    // },


    // addPayment() {
    //   const paymentobject = {
    //     id: "",
    //     payment_method: "",
    //     payment_amount: rupiahInput(0),
    //     payment_notes: "",
    //     payment_date: new Date(),
    //     status: 'tambah'
    //   }
    //   this.paymentEdited = paymentobject;
    //   const openButton = this.$refs.buttonopenmodalpayment;
    //   openButton.click();


    // },
    // editPayment(id, payment_amount, payment_method, payment_date, payment_notes) {

    //   const indexPayment = this.data_payments.findIndex((list) => list.nama == payment_method);
    //   const paymentobject = {
    //     id: id,
    //     payment_method: indexPayment,
    //     payment_amount: rupiahInput(parseInt(payment_amount)),
    //     payment_notes: payment_notes,
    //     payment_date: new Date(payment_date),
    //     status: 'update'
    //   }
    //   // total amount 
    //   let amount = 0;
    //   if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
    //     amount = 0;
    //   } else {
    //     const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.item_total), 0)
    //     amount = total;
    //   }

    //   let payment = 0;
    //   if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
    //     payment = 0;
    //   } else {
    //     const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.payment_amount), 0)
    //     payment = total
    //   }

    //   this.batas_payment_edit = parseInt(amount) - parseInt(payment) + parseInt(payment_amount);

    //   this.paymentEdited = paymentobject;
    //   const openButton = this.$refs.buttonopenmodalpayment;
    //   openButton.click();
    // },
    // deletePayment(index) {
    //   this.currentData.invoice_payment.splice(index, 1);



    // },

    convertDate(value) {
      const date = new Date(value);

      // Use built-in methods to get year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },


    insert() {

      ShowLoading();
      let formData = new FormData();
      // const currentDate = new Date().toISOString().split('T')[0];

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // formData.delete("gambar");
      // if (this.$refs.gambar.value != "") {
      //   formData.append("gambar", this.gambar_file_64);
      // }


      formData.delete("product_list")
      formData.append('product_list', JSON.stringify(this.currentData.product_list));

      // this.currentData.invoice_payment.forEach((item) => {
      //   payment_notes.push(item.payment_notes);
      //   payment_method.push(item.payment_method);
      //   payment_amount.push(item.payment_amount);
      //   payment_date.push(item.payment_date);
      // })


      // yang dicomment udh ada
      // formData.append("id", this.currentData.id);
      // formData.append("invoice_date", this.convertDate(this.currentData.invoice_date)); //udh ada
      // formData.append("due_date", this.convertDate(this.currentData.due_date)); //udh ada 
      // formData.append("terms", 0); //kosong
      // formData.append("po_number", this.currentData.po_number); //kosong
      // formData.append("id_suplier", this.currentData.suplier_detail.id); //udh ada
      // formData.append("subtotal", this.totalAmount);// udh ada 
      // formData.append("discount_type", this.currentData.discount_type); //kosong
      // formData.append("discount_amount", this.currentData.discount_amount);//kosong
      // formData.append("tax_type", this.currentData.tax_type);//kosong
      // formData.append("tax_rate", this.currentData.tax_rate);//kosong
      // formData.append("tax_name", 'PPN');//kosong
      // formData.append("tax_amount", this.currentData.tax_amount);
      // formData.append("payment_date", payment_date); //udh ada
      // formData.append("payment_amount", payment_amount);
      // formData.append("payment_method", payment_method);
      // formData.append("payment_notes", payment_notes); //kosong
      // formData.append("unpaid_amount", 0);//kosong
      // formData.append("grand_total", this.currentData.grand_total); // udh ada 
      // formData.append("notes", ''); //kosong
      // formData.append("payment_info", ''); //kosong
      // formData.append("bank_name", ""); //kosong
      // formData.append("bank_account_number", ""); //kosong
      // formData.append("bank_account_name", ""); //kosong
      // formData.append("calculated_discount", this.currentData.calculated_discount); //kosong


      // formData.append("item_id", item_id);// udh ada
      // formData.append("item_qty", item_qty); //udh ada
      // formData.append("item_rate", item_rate); //udh ada
      // formData.append("item_discount_type", item_discount_type); //kosong
      // formData.append("item_discount_amount", item_discount_amount); //kosong
      // formData.append("item_is_tax", item_is_tax); //kosong
      // formData.append("item_name", item_name); //kosong
      // formData.append("item_warehouse", item_warehouse); //kosong
      // formData.append("item_calculated_discount", item_calculated_discount); //kosong
      // formData.append("item_total", item_total);// udh ada 
      // formData.append("item_isdeposit", item_isdeposit); // udh ada 
      // formData.append("item_id_marketing", item_id_marketing);// udh ada
      // formData.append("quotation_term", this.currentData.quotation_term);// udh ada
      // formData.append("quotation_note", this.currentData.quotation_note);// udh ada
      // formData.append("id_marketing", this.currentData.id_marketing);
      // formData.append("po_number", this.currentData.po_number);
      // formData.append("code_project", this.currentData.code_project);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      // CloseLoading();
      // return false;


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'purchase_request_insert';
      } else {
        this.api = base_url + 'purchase_request_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('purchase_request');
            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    }


  },

};
</script>
