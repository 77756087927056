<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">

              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12 mb-4">
                  <div class="form-group d-flex align-items-center">
                    <label class="me-3 mb-0 " style="width: 150px;">Nama <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" v-model="currentData.nama" placeholder="" required />
                  </div>

                </div>
                <!-- <div class="col-lg-12 col-sm-12 col-12">

                  <label class="mb-">Gambar </label>
                  <div class="custom-file-container" data-upload-id="product-image">
                    <label v-show="isGambar == true" @click="isGambar = false">Remove
                      <a href="javascript:void(0)" class="custom-file-container__image-clear"
                        title="Clear Image">x</a></label>
                    <label class="custom-file-container__custom-file">
                      <input type="file" @change="updateIsGambar()" ref="gambar"
                        class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
                      <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                      <span class="custom-file-container__custom-file__custom-file-control"></span>
                    </label>
                    <div class="custom-file-container__image-preview"></div>
                  </div>

                </div> -->

                <!-- <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="payment" class="btn btn-cancel">Batal</router-link>
                </div> -->

                <div class="simpan-button d-flex justify-content-end mt-5">
                    <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
                    <RouterLink to="category" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px; border: 1px solid #D6B884 !important;">Batal</RouterLink>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editkategori",
  data() {
    return {
      filter: true,
      title: "Perbaharui Kategori Produk",
      title1: "perbaharui kategori produk",
      config: "",
      api: "",

      //data
      currentData: {
      },
      currentDate: new Date,
      //gambar
      gambar_file_64: "",
      isGambar: false,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData();

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
 
  methods: {

    // getNameFileUrl(url) {
    //   const parts = url.split('/');
    //   const fileName = parts[parts.length - 1];
    //   return fileName;
    // },
    // convertToBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       resolve(reader.result); // Resolve with the result after loading
    //     };
    //     reader.onerror = reject; // Reject if an error occurs
    //     reader.readAsDataURL(file);
    //   });
    // },
    // updateIsGambar() {
    //   if (this.$refs.gambar.value != "") {
    //     this.isGambar = true;
    //   } else {
    //     this.isGambar = false;
    //   }


    //   const file = this.$refs.gambar.files[0];
    //   if (file) {
    //     this.convertToBase64(file).then(base64String => {
    //       this.gambar_file_64 = base64String;
    //     });
    //   }

    // },

    prepareData() {
      this.currentData = JSON.parse(localStorage.getItem('currentData'));
    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if(!this.currentData.nama || this.currentData.nama === ''){
        AlertPopup("error", "Validation Error", "Kode kategori tidak boleh kosong!", 1500, false)
        return;
      }

      // formData.delete("gambar");

      // if (this.$refs.gambar.value != "") {

      //   formData.append("gambar", this.gambar_file_64);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'items_kategori_update';
      } else {
        this.api = base_url + 'items_kategori_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("category");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>

<style scoped>
.form-control {
  text-transform: none !important;
}

label {
  font-weight: 300 !important;

}
</style>