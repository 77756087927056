<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      
      <div class="content">
          <pageheader :title="title" :title1="title1" />

          <div class="menu-tab">
              <div class="d-flex justify-content-start align-items-center gap-4 order-2 order-md-1 mt-4 mt-md-0">
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('umum');"
                      :class="{ 'menu-top': active_page === 'umum', 'text-muted': active_page !== 'umum' }" style="cursor: pointer;">
                      UMUM
                  </div>

                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('kontak');"
                      :class="{ 'menu-top': active_page === 'kontak', 'text-muted': active_page !== 'kontak' }" style="cursor: pointer;">
                      INFO PRODUK
                  </div>
                  
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('alamat');"
                      :class="{ 'menu-top': active_page === 'alamat', 'text-muted': active_page !== 'alamat' }" style="cursor: pointer;">
                      MINIMAL ORDER
                  </div>
  
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('pembelian');"
                      :class="{ 'menu-top': active_page === 'pembelian', 'text-muted': active_page !== 'pembelian' }" style="cursor: pointer;">
                      HARGA
                  </div>
  
                  <!-- <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('pajak');"
                      :class="{ 'menu-top': active_page === 'pajak', 'text-muted': active_page !== 'pajak' }" style="cursor: pointer;">
                      PAJAK
                  </div> -->
                  
                  <!-- <div class="ms-auto mb-2" v-if="active_page != 'kontak'">
                      <button type="button" data-bs-toggle="modal" data-bs-target="#fakturModal" class="btn btn-primary"  style="background-color: transparent !important; border: 1px solid #D6B884; color: #D6B884;">Cari Faktur</button>
                      <div class="form-check d-flex align-items-center">
                          <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" style="width: 28px; height: 28px;">
                          <label class="form-check-label" for="flexCheckDefault">
                              Non-Aktif
                          </label>
                      </div>
                  </div> -->
                  <div class="ms-auto mb-2" v-if="active_page === 'alamat'">
                      <button type="button" @click="addNewRow" class="btn btn-primary rounded-circle p-0" style="width: 35px; height: 35px; font-size: 24px;">
                          <i class='bx bx-plus'></i>
                      </button>
                      <!-- <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                          <label class="form-check-label" for="flexCheckDefault">
                              Non-Aktif
                          </label>
                      </div> -->
                  </div>
                  
                  
                  
              </div>

          </div>

          <!-- /add -->
          <form @submit.prevent="insert">
            <div class="card">
              <div class="card-body">
                  
                <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
                  <div class="row" :class="{ 'custom-active': active_page !== 'umum' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'umum'">
                      <div class="row">
                        <div class="title-tab py-2 mb-3 w-100" style="border-bottom: 1px solid #aaabad">
                          <h5>Info Umum</h5>
                        </div>
                        <div class="col-md-6">
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3 " >SKU <span class="text-danger">*</span></label>
                              <input type="text" v-model="currentData.code" class="form-control" placeholder="">
                              <!-- disabled jika harus auto sku nya -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3 " >Nama Produk <span class="text-danger">*</span></label>
                              <input type="text" v-model="currentData.name" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3 " >Kategori</label>
                              <model-select :options="needData4" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_kategori" class="custom-width form-control" placeholder=""></model-select>
                              <!-- <input type="text" v-model="currentData.email" class="form-control" placeholder="" > -->

                            </div>

                            <!-- <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3 text-muted" style="font-weight: 300;">No. Telepon</label>
                                <div class="d-flex align-items-center gap-4">
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Kena Pajak
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Harga Termasuk Pajak
                                    </label>
                                  </div>
                                </div>
                                
                            </div> -->
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Ekspedisi</label>
                              <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Group Produk</label>
                              <model-select :options="needData3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_group_product" class="custom-width form-control" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Merek</label>
                              <model-select :options="needData2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.id_merek" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="text" v-model="currentData.website" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Satuan Produk</label>
                              <!-- <input type="text" v-model="currentData.website" class="form-control" placeholder="" > -->
                              <model-select :options="satuanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.satuan_berat" class="custom-width" placeholder=""></model-select>
                                <!-- :settings="{ settingOption: value, settingOption: value }" -->
                              <!-- <textarea type="text" v-model="currentData.catatan_umum" class="form-control" placeholder="" ></textarea> -->
                              
                            </div>
                          </div>
  
                        </div>
                        <div class="col-md-6">
                          <!-- <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Info Lainnya</h5>
                          </div> -->
  
                          <div class="">
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Alamat</label>
                              <textarea type="date" class="form-control" placeholder="" ></textarea>
                              
                            </div> -->
                            <div class="d-flex align-items-center mb-3" >
                              <label for="" class="col-3" >Status Produk*</label>
                              <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status_product" class="custom-width" placeholder="" ></model-select>
                              <!-- <input type="text" v-model="currentData.nama" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Tipe Produk</label>
                              <model-select :options="tipeProduk" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.type_product" class="custom-width" placeholder="" ></model-select>
                              <!-- <input type="text" v-model="currentData.cabang" class="form-control" placeholder="" > -->
                            </div>

                            <div class="d-flex flex-column mb-3 ">
                              <div class="d-flex align-items-center">
                                <label for="" class="col-3" >Foto Produk</label>
                                <div class="custom-file-container" data-upload-id="product-image">
                                  <label v-show="isGambar == true" @click="isGambar = false">Remove
                                    <a href="javascript:void(0)" class="custom-file-container__image-clear"
                                      title="Clear Image">x</a></label>
                                  <label class="custom-file-container__custom-file">
                                    <input type="file" @change="updateIsGambar()" ref="gambar_update"
                                      class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
                                    <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                                    <span class="custom-file-container__custom-file__custom-file-control"></span>
                                  </label>
                                  <div v-show="isGambar == true" class="custom-file-container__image-preview"></div>
                                  
                                </div>
                              </div>

                              <div class="gambar-container">

                                <div v-show="isGambar == false" class="gambar-sebelumnya" ref="gambar"> </div>
                              </div>

                              <!-- <input type="text" v-model="currentData.nomor_rekening" class="form-control" placeholder="" > -->
                            </div>
                            
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kelurahan</label>
                              <vue-select :options="kelurahanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder="" disabled/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>
  
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  
                  <div class="row " :class="{ 'custom-active': active_page !== 'kontak' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'kontak'">
                      <div class="row">
                        <div class="col-md-12">
                          <!-- <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Alamat Pembayaran</h5>
                          </div> -->
                          
  
                        </div>

                        <div class="col-md-6">
                            <div class="">
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3 " >Panjang</label>
                                <input type="text" v-model="currentData.panjang" class="form-control" placeholder="" >
                                
                              </div>
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3 " >Lebar</label>
                                <input type="text" v-model="currentData.lebar" class="form-control" placeholder="" >
                                
                              </div>
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3 " >Tinggi</label>
                                <input type="text" v-model="currentData.tinggi" class="form-control" placeholder="" >
                                <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="currentData.status" class="custom-width" placeholder=""/> -->

                              </div>

                              <!-- <div class="d-flex align-items-center mb-3">
                                  <label for="" class="col-3 text-muted" style="font-weight: 300;">No. Telepon</label>
                                  <div class="d-flex align-items-center gap-4">
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                      <label class="form-check-label" for="flexCheckDefault">
                                        Kena Pajak
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                      <label class="form-check-label" for="flexCheckDefault">
                                        Harga Termasuk Pajak
                                      </label>
                                    </div>
                                  </div>
                                  
                              </div> -->
                              <!-- <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Ekspedisi</label>
                                <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="currentData.status" class="custom-width" placeholder=""/>
                                <input type="text" class="form-control" placeholder="" >
                                
                              </div> -->
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Berat - Gram</label>
                                <!-- <model-select :options="needData1" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="currentData.jenis_industri" class="custom-width" placeholder=""></model-select> -->
                                <input type="text" v-model="currentData.berat" class="form-control" placeholder="" >
                                
                              </div>
                              
                              
                            </div>
    
                          </div>
                          
                          <div class="col-md-6">
                            <!-- <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                              <h5>Info Lainnya</h5>
                            </div> -->
    
                            <div class="">
                              <!-- <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Alamat</label>
                                <textarea type="date" class="form-control" placeholder="" ></textarea>
                                
                              </div> -->
                              <!-- <div class="d-flex align-items-center mb-3" >
                                <label for="" class="col-3" >Gudang</label>
                                <model-select :options="needData1" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="currentData.id_warehouse" class="custom-width form-control" placeholder=""></model-select>
                                <input type="text" v-model="currentData.gudang" class="form-control" placeholder="" >
                                
                              </div> -->
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Stock*</label>
                                <input type="text" v-model="currentData.stok" class="form-control" placeholder="" >
                              </div>
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Total Stock*</label>
                                <input type="text" v-model="currentData.total_stok" class="form-control" placeholder="" >
                              </div>
                              <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Avarage*</label>
                                <input type="text" v-model="currentData.average" class="form-control" placeholder="" >
                              </div>
                              
                              <!-- <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3" >Kelurahan</label>
                                <vue-select :options="kelurahanList" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="currentData.status" class="custom-width" placeholder="" disabled/>
                                <input type="text" class="form-control" placeholder="" >
                                
                              </div> -->
                            </div>
    
                          </div>
                      
                      </div>
                      
                    </div>
                  </div>

                  <!-- minimal order -->
                  <div class="row mb-2" :class="{ 'custom-active': active_page !== 'alamat' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'alamat'">
                      <table class="table table-responsive table-border">
                        <thead style="background-color: #D6B884;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col " class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">Kemasan</th>
                            <th scope="col " class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">Jumlah</th>
                            <th scope="col " class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">Satuan</th>
                            <!-- <th scope="col" class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">Agama</th> -->
                            
                            <!-- <th scope="col" class="border-1 border-end text-center text-white">Tanggal Diminta</th> -->
                          </tr>
                        </thead>
                        <tbody style="border: 1px solid #fafafa;">
                          <tr v-for="(item, index) in currentData.minimal_order" :key="index" class="border border-1" style="height: 40px;">
                              <!-- <td class="text-center">{{ index + 1 }}</td> -->

                              <td class="text-center td-tabel"><input type="text" v-model="item.kemasan" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.jumlah" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.satuan" class="form-control" placeholder="" style="border: none !important;"></td>
                              
                              <!-- <td class="text-center td-tabel"><input type="text" v-model="item.agama" class="form-control" placeholder="" style="border: none !important;"></td> -->
                              
                              <!-- <td class="text-center">{{ item.tanggal_diminta }}</td> -->
                              
                          </tr>
                          
                        </tbody>
                      </table>
                      
                    </div>
                  </div>
  
                  
                  <!-- Harga -->
                  <div class="row " :class="{ 'custom-active': active_page !== 'pembelian' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'pembelian'">
                      <div class="row">
                        <div class="col-md-6">
                          <!-- <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Pembelian</h5>
                          </div> -->
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Modal</label>
                              <!-- <model-select :options="needData2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.syarat_pembayaran" class="custom-width" placeholder=""></model-select> -->
                              <input type="text" class="form-control" v-model="currentData.modal_price" @input="formatCurrency('modal_price', this.currentData.modal_price)" placeholder="Rp.0,-" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Pricelist</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.pricelist_price" @input="formatCurrency('pricelist_price', this.currentData.pricelist_price)" class="form-control" placeholder="Rp.0,-" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Enduser</label>
                              <input type="text" v-model="currentData.enduser_price" @input="formatCurrency('enduser_price', this.currentData.enduser_price)" class="form-control" placeholder="Rp.0,-" >
                              <!-- <input type="text" v-model="currentData.enduser_price" class="form-control" placeholder="Rp.0,-" > -->
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Retail</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.retail_price" @input="formatCurrency('retail_price', this.currentData.retail_price)" class="form-control" placeholder="Rp.0,-" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Umum</label>
                              <input type="text" v-model="currentData.general_price" @input="formatCurrency('general_price', this.currentData.general_price)" class="form-control" placeholder="Rp.0,-" >
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <textarea type="text" v-model="currentData.catatan_pembelian" class="form-control" placeholder="" ></textarea> -->

                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Agen Khusus</label>
                              <input type="text" v-model="currentData.special_agent_price" @input="formatCurrency('special_agent_price', this.currentData.special_agent_price)" class="form-control" placeholder="Rp.0,-" >
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <textarea type="text" v-model="currentData.catatan_pembelian" class="form-control" placeholder="" ></textarea> -->

                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga Supplier</label>
                              <input type="text" v-model="currentData.supplier_price" @input="formatCurrency('supplier_price', this.currentData.supplier_price)" class="form-control" placeholder="Rp.0,-" >
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <textarea type="text" v-model="currentData.catatan_pembelian" class="form-control" placeholder="" ></textarea> -->

                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Harga <br> E-Commerce</label>
                              <input type="text" v-model="currentData.ecommerce_price" @input="formatCurrency('ecommerce_price', this.currentData.ecommerce_price)" class="form-control" placeholder="Rp.0,-" >
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <textarea type="text" v-model="currentData.catatan_pembelian" class="form-control" placeholder="" ></textarea> -->

                              
                            </div>
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kode Pos</label>
                              <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>
  
                        </div>
                        <div class="col-md-6">
                          <!-- <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Info Lainnya</h5>
                          </div> -->
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Catatan</label>
                              <textarea type="text" v-model="currentData.note" class="form-control" name="" row="4" id=""></textarea>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <!-- <input type="text" v-model="currentData.batas_hutang_jatuh_tempo" class="form-control" placeholder="Hari" > -->
                              
                            </div>
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Mata Uang</label>
                              <model-select :options="needData3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.mata_uang" class="custom-width" placeholder=""></model-select>
                              <input type="text" class="form-control" placeholder="Rp." >
                              
                            </div> -->
                            
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kode Pos</label>
                              <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>
  
                        </div>
                      
                      </div>
                      
                    </div>
                  </div>

                  
  
                  
  
                  
              </div>
            </div>

            <div class="simpan-button d-flex justify-content-end mt-5">
                <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
                <RouterLink to="product" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px; border: 1px solid #D6B884 !important;">Batal</RouterLink>
            </div>
          </form>
        
        <!-- /add -->

              <!-- <div class="p-3 d-flex flex-row align-items-start mb-3 ms-auto" style="border: 1px solid #D6B884; border-radius: 12px; width: 216px; height: 116px">
                  <div class="d-flex flex-column justify-content-between p-1" style="border-right: 1px solid #D6B884;  width: 120px; height: 100%;">
                      <p class="mb-3">Sub Total</p>
                      <p class="text-muted text-end">Rp.0,-</p>
                  </div>
                  <div class="d-flex flex-column justify-content-between p-1" style="border-right: 1px solid #D6B884;  width: 120px; height: 100%;">
                      <p class="mb-3">Diskon</p>
                      <p class="text-muted text-end">%</p>
                  </div>
                  <div class="d-flex flex-column justify-content-between p-1" style="width: 100%; height: 100%;">
                      <p>Faktur di bayar</p>
                      <p class="text-muted text-end">Rp.0,-</p>
                  </div>
              </div> -->
  
                  
                  
                  
          </div>
    </div>
  </div>

  <!-- <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladditempurchase"
    ref="buttonopenmodaladditempurchase" hidden><i class="fa fa-plus me-2"></i></a>
  <additempurchasemodal :marketingdata="data_marketing" :kategoridata="data_kategori" :itemdata="data_item_filter"
    :warehousedata="data_warehouse" @id_kategori_selected="handleKategoriSelected"
    @id_item_selected="handleItemSelected" :isMarketing="isMarketing" @item_object="handleItemAdd"
    :itemEdited="itemEdited">
  </additempurchasemodal> -->


  
  <!-- Bootstrap Modal -->
  <div class="modal fade" id="fakturModal" tabindex="-1" aria-labelledby="fakturModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header" style="background-color: #D6B884; color: white;">
                  <h5 class="modal-title" id="fakturModalLabel">Faktur Belum Lunas</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <!-- Modal content goes here -->
                  <p>Content for Cari Faktur search modal.</p>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>


  <!-- upload form -->
    <!-- <label class="mb-">Product Image</label>
    <div class="custom-file-container" data-upload-id="product-image">
      <label v-show="isGambar == true" @click="isGambar = false">Remove
        <a href="javascript:void(0)" class="custom-file-container__image-clear"
          title="Clear Image">x</a></label>
      <label class="custom-file-container__custom-file">
        <input type="file" @change="updateIsGambar()" ref="gambar"
          class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
        <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
        <span class="custom-file-container__custom-file__custom-file-control"></span>
      </label>
      <div class="custom-file-container__image-preview"></div>
    </div> -->


</template>

<style>
.tox-statusbar__branding {
  display: none !important;
}

.menu-top {
    background-color: rgba(214, 184, 132, 0.3); /* Adjust opacity and color as needed */
    border-left: 4px solid #D6B884; /* Primary color (Bootstrap default) */
    color: black; /* Dark text color (Bootstrap default) */
    font-weight: 600;
    border-radius: 0.125rem;
  }

  .custom-width {
    width: 400px;
  }

  .custom-select {
    width: 250px;
  }

  .custom-active {
    overflow: auto;
    max-height: 16rem;
  }

  .search-input {
      background-color: #F6F6F6;
      border: 1px solid #D6B884;
      color: #D6B884;
      border-radius: 4px;
  }

  .td-tabel {
    text-transform: none !important;
  }

  .form-control {
    text-transform: none !important;
  }

  .gambar-container {
  width: 100% !important;
  margin-top: 15px !important; /* Adjust spacing between input and image */
}

  .gambar-sebelumnya {
    box-sizing: border-box;
    /* transition: all 0.2s ease; */
    /* margin-top: 50px;
    margin-bottom: 40px; */
    height: 250px;
    width: 100%;
    border-radius: 4px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: auto;
    padding: 15px;

  }
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { satuanList, ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiahInput, rupiahoutput, } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";
import Editor from '@tinymce/tinymce-vue';
import { ModelSelect } from 'vue-search-select';

export default {

  name: "editkategori",
  data() {
    return {
      filter: true,
      title: "Perbaharui Produk",
      title1: "edit produk baru",
      config: "",
      api: "",
      //data
      currentData: {
        // list_items_in_warehouse_detail: [],
        // satuan_berat: "",
        // id_kategori: "",
        // satuan_berat: "BH",
        // minimal_order: 1,
        // daftar_kontak: [],
        minimal_order: [],
        satuan_berat: null,
      },

      satuanList: [], // satuan produk
      statusList: [], //status produk
      tipeProduk: [], //status produk
      
      needData1: [],
      needData2: [],
      needData3: [],
      needData4: [],

      // currentDate: new Date,
      gambar_file_64: "",
      isGambar: false,

      // disabledDeposit: false,
      // disabledMarketing: false,

      //crud item warehouse
      // itemEdited: {},
      // warehouseDataFilter: [],
      // satuanList: satuanList,

      active_page: "umum",
      isActive: true,
    };
  },
  mounted() {
    var FileUploadWithPreview = (function () {
      "use strict";
      var A,
        e =
          ((function (A) {
            var e = (function (A) {
              var e,
                t = Object.prototype,
                g = t.hasOwnProperty,
                n = "function" == typeof Symbol ? Symbol : {},
                i = n.iterator || "@@iterator",
                B = n.asyncIterator || "@@asyncIterator",
                E = n.toStringTag || "@@toStringTag";
              function r(A, e, t, g) {
                var n = e && e.prototype instanceof c ? e : c,
                  i = Object.create(n.prototype),
                  B = new k(g || []);
                return (
                  (i._invoke = (function (A, e, t) {
                    var g = o;
                    return function (n, i) {
                      if (g === Q) throw new Error("Generator is already running");
                      if (g === a) {
                        if ("throw" === n) throw i;
                        return R();
                      }
                      for (t.method = n, t.arg = i; ;) {
                        var B = t.delegate;
                        if (B) {
                          var E = S(B, t);
                          if (E) {
                            if (E === s) continue;
                            return E;
                          }
                        }
                        if ("next" === t.method) t.sent = t._sent = t.arg;
                        else if ("throw" === t.method) {
                          if (g === o) throw ((g = a), t.arg);
                          t.dispatchException(t.arg);
                        } else "return" === t.method && t.abrupt("return", t.arg);
                        g = Q;
                        var r = C(A, e, t);
                        if ("normal" === r.type) {
                          if (((g = t.done ? a : I), r.arg === s)) continue;
                          return { value: r.arg, done: t.done };
                        }
                        "throw" === r.type &&
                          ((g = a), (t.method = "throw"), (t.arg = r.arg));
                      }
                    };
                  })(A, t, B)),
                  i
                );
              }
              function C(A, e, t) {
                try {
                  return { type: "normal", arg: A.call(e, t) };
                } catch (A) {
                  return { type: "throw", arg: A };
                }
              }
              A.wrap = r;
              var o = "suspendedStart",
                I = "suspendedYield",
                Q = "executing",
                a = "completed",
                s = {};
              function c() { }
              function u() { }
              function l() { }
              var h = {};
              h[i] = function () {
                return this;
              };
              var m = Object.getPrototypeOf,
                f = m && m(m(J([])));
              f && f !== t && g.call(f, i) && (h = f);
              var d = (l.prototype = c.prototype = Object.create(h));
              function p(A) {
                ["next", "throw", "return"].forEach(function (e) {
                  A[e] = function (A) {
                    return this._invoke(e, A);
                  };
                });
              }
              function v(A) {
                var e;
                this._invoke = function (t, n) {
                  function i() {
                    return new Promise(function (e, i) {
                      !(function e(t, n, i, B) {
                        var E = C(A[t], A, n);
                        if ("throw" !== E.type) {
                          var r = E.arg,
                            o = r.value;
                          return o && "object" == typeof o && g.call(o, "__await")
                            ? Promise.resolve(o.__await).then(
                              function (A) {
                                e("next", A, i, B);
                              },
                              function (A) {
                                e("throw", A, i, B);
                              }
                            )
                            : Promise.resolve(o).then(
                              function (A) {
                                (r.value = A), i(r);
                              },
                              function (A) {
                                return e("throw", A, i, B);
                              }
                            );
                        }
                        B(E.arg);
                      })(t, n, e, i);
                    });
                  }
                  return (e = e ? e.then(i, i) : i());
                };
              }
              function S(A, t) {
                var g = A.iterator[t.method];
                if (g === e) {
                  if (((t.delegate = null), "throw" === t.method)) {
                    if (
                      A.iterator.return &&
                      ((t.method = "return"), (t.arg = e), S(A, t), "throw" === t.method)
                    )
                      return s;
                    (t.method = "throw"),
                      (t.arg = new TypeError(
                        "The iterator does not provide a 'throw' method"
                      ));
                  }
                  return s;
                }
                var n = C(g, A.iterator, t.arg);
                if ("throw" === n.type)
                  return (t.method = "throw"), (t.arg = n.arg), (t.delegate = null), s;
                var i = n.arg;
                return i
                  ? i.done
                    ? ((t[A.resultName] = i.value),
                      (t.next = A.nextLoc),
                      "return" !== t.method && ((t.method = "next"), (t.arg = e)),
                      (t.delegate = null),
                      s)
                    : i
                  : ((t.method = "throw"),
                    (t.arg = new TypeError("iterator result is not an object")),
                    (t.delegate = null),
                    s);
              }
              function y(A) {
                var e = { tryLoc: A[0] };
                1 in A && (e.catchLoc = A[1]),
                  2 in A && ((e.finallyLoc = A[2]), (e.afterLoc = A[3])),
                  this.tryEntries.push(e);
              }
              function w(A) {
                var e = A.completion || {};
                (e.type = "normal"), delete e.arg, (A.completion = e);
              }
              function k(A) {
                (this.tryEntries = [{ tryLoc: "root" }]),
                  A.forEach(y, this),
                  this.reset(!0);
              }
              function J(A) {
                if (A) {
                  var t = A[i];
                  if (t) return t.call(A);
                  if ("function" == typeof A.next) return A;
                  if (!isNaN(A.length)) {
                    var n = -1,
                      B = function t() {
                        for (; ++n < A.length;)
                          if (g.call(A, n)) return (t.value = A[n]), (t.done = !1), t;
                        return (t.value = e), (t.done = !0), t;
                      };
                    return (B.next = B);
                  }
                }
                return { next: R };
              }
              function R() {
                return { value: e, done: !0 };
              }
              return (
                (u.prototype = d.constructor = l),
                (l.constructor = u),
                (l[E] = u.displayName = "GeneratorFunction"),
                (A.isGeneratorFunction = function (A) {
                  var e = "function" == typeof A && A.constructor;
                  return (
                    !!e && (e === u || "GeneratorFunction" === (e.displayName || e.name))
                  );
                }),
                (A.mark = function (A) {
                  return (
                    Object.setPrototypeOf
                      ? Object.setPrototypeOf(A, l)
                      : ((A.__proto__ = l), E in A || (A[E] = "GeneratorFunction")),
                    (A.prototype = Object.create(d)),
                    A
                  );
                }),
                (A.awrap = function (A) {
                  return { __await: A };
                }),
                p(v.prototype),
                (v.prototype[B] = function () {
                  return this;
                }),
                (A.AsyncIterator = v),
                (A.async = function (e, t, g, n) {
                  var i = new v(r(e, t, g, n));
                  return A.isGeneratorFunction(t)
                    ? i
                    : i.next().then(function (A) {
                      return A.done ? A.value : i.next();
                    });
                }),
                p(d),
                (d[E] = "Generator"),
                (d[i] = function () {
                  return this;
                }),
                (d.toString = function () {
                  return "[object Generator]";
                }),
                (A.keys = function (A) {
                  var e = [];
                  for (var t in A) e.push(t);
                  return (
                    e.reverse(),
                    function t() {
                      for (; e.length;) {
                        var g = e.pop();
                        if (g in A) return (t.value = g), (t.done = !1), t;
                      }
                      return (t.done = !0), t;
                    }
                  );
                }),
                (A.values = J),
                (k.prototype = {
                  constructor: k,
                  reset: function (A) {
                    if (
                      ((this.prev = 0),
                        (this.next = 0),
                        (this.sent = this._sent = e),
                        (this.done = !1),
                        (this.delegate = null),
                        (this.method = "next"),
                        (this.arg = e),
                        this.tryEntries.forEach(w),
                        !A)
                    )
                      for (var t in this)
                        "t" === t.charAt(0) &&
                          g.call(this, t) &&
                          !isNaN(+t.slice(1)) &&
                          (this[t] = e);
                  },
                  stop: function () {
                    this.done = !0;
                    var A = this.tryEntries[0].completion;
                    if ("throw" === A.type) throw A.arg;
                    return this.rval;
                  },
                  dispatchException: function (A) {
                    if (this.done) throw A;
                    var t = this;
                    function n(g, n) {
                      return (
                        (E.type = "throw"),
                        (E.arg = A),
                        (t.next = g),
                        n && ((t.method = "next"), (t.arg = e)),
                        !!n
                      );
                    }
                    for (var i = this.tryEntries.length - 1; i >= 0; --i) {
                      var B = this.tryEntries[i],
                        E = B.completion;
                      if ("root" === B.tryLoc) return n("end");
                      if (B.tryLoc <= this.prev) {
                        var r = g.call(B, "catchLoc"),
                          C = g.call(B, "finallyLoc");
                        if (r && C) {
                          if (this.prev < B.catchLoc) return n(B.catchLoc, !0);
                          if (this.prev < B.finallyLoc) return n(B.finallyLoc);
                        } else if (r) {
                          if (this.prev < B.catchLoc) return n(B.catchLoc, !0);
                        } else {
                          if (!C)
                            throw new Error("try statement without catch or finally");
                          if (this.prev < B.finallyLoc) return n(B.finallyLoc);
                        }
                      }
                    }
                  },
                  abrupt: function (A, e) {
                    for (var t = this.tryEntries.length - 1; t >= 0; --t) {
                      var n = this.tryEntries[t];
                      if (
                        n.tryLoc <= this.prev &&
                        g.call(n, "finallyLoc") &&
                        this.prev < n.finallyLoc
                      ) {
                        var i = n;
                        break;
                      }
                    }
                    i &&
                      ("break" === A || "continue" === A) &&
                      i.tryLoc <= e &&
                      e <= i.finallyLoc &&
                      (i = null);
                    var B = i ? i.completion : {};
                    return (
                      (B.type = A),
                      (B.arg = e),
                      i
                        ? ((this.method = "next"), (this.next = i.finallyLoc), s)
                        : this.complete(B)
                    );
                  },
                  complete: function (A, e) {
                    if ("throw" === A.type) throw A.arg;
                    return (
                      "break" === A.type || "continue" === A.type
                        ? (this.next = A.arg)
                        : "return" === A.type
                          ? ((this.rval = this.arg = A.arg),
                            (this.method = "return"),
                            (this.next = "end"))
                          : "normal" === A.type && e && (this.next = e),
                      s
                    );
                  },
                  finish: function (A) {
                    for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                      var t = this.tryEntries[e];
                      if (t.finallyLoc === A)
                        return this.complete(t.completion, t.afterLoc), w(t), s;
                    }
                  },
                  catch: function (A) {
                    for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                      var t = this.tryEntries[e];
                      if (t.tryLoc === A) {
                        var g = t.completion;
                        if ("throw" === g.type) {
                          var n = g.arg;
                          w(t);
                        }
                        return n;
                      }
                    }
                    throw new Error("illegal catch attempt");
                  },
                  delegateYield: function (A, t, g) {
                    return (
                      (this.delegate = { iterator: J(A), resultName: t, nextLoc: g }),
                      "next" === this.method && (this.arg = e),
                      s
                    );
                  },
                }),
                A
              );
            })(A.exports);
            try {
              regeneratorRuntime = e;
            } catch (A) {
              Function("r", "regeneratorRuntime = r")(e);
            }
          })((A = { exports: {} }), A.exports),
            A.exports);
      function t(A, e, t, g, n, i, B) {
        try {
          var E = A[i](B),
            r = E.value;
        } catch (A) {
          return void t(A);
        }
        E.done ? e(r) : Promise.resolve(r).then(g, n);
      }
      var g = function (A) {
        return function () {
          var e = this,
            g = arguments;
          return new Promise(function (n, i) {
            var B = A.apply(e, g);
            function E(A) {
              t(B, n, i, E, r, "next", A);
            }
            function r(A) {
              t(B, n, i, E, r, "throw", A);
            }
            E(void 0);
          });
        };
      };
      var n = function (A, e) {
        if (!(A instanceof e)) throw new TypeError("Cannot call a class as a function");
      };
      function i(A, e) {
        for (var t = 0; t < e.length; t++) {
          var g = e[t];
          (g.enumerable = g.enumerable || !1),
            (g.configurable = !0),
            "value" in g && (g.writable = !0),
            Object.defineProperty(A, g.key, g);
        }
      }
      var B = function (A, e, t) {
        return e && i(A.prototype, e), t && i(A, t), A;
      };
      return (
        Element.prototype.matches ||
        (Element.prototype.matches =
          Element.prototype.matchesSelector ||
          Element.prototype.mozMatchesSelector ||
          Element.prototype.msMatchesSelector ||
          Element.prototype.oMatchesSelector ||
          Element.prototype.webkitMatchesSelector ||
          function (A) {
            for (
              var e = (this.document || this.ownerDocument).querySelectorAll(A),
              t = e.length;
              --t >= 0 && e.item(t) !== this;

            );
            return t > -1;
          }),
        Array.prototype.findIndex ||
        Object.defineProperty(Array.prototype, "findIndex", {
          value: function (A) {
            if (null == this) throw new TypeError('"this" is null or not defined');
            var e = Object(this),
              t = e.length >>> 0;
            if ("function" != typeof A)
              throw new TypeError("predicate must be a function");
            for (var g = arguments[1], n = 0; n < t;) {
              var i = e[n];
              if (A.call(g, i, n, e)) return n;
              n++;
            }
            return -1;
          },
          configurable: !0,
          writable: !0,
        }),
        (function () {
          if ("function" == typeof window.CustomEvent) return !1;
          function A(A, e) {
            e = e || { bubbles: !1, cancelable: !1, detail: null };
            var t = document.createEvent("CustomEvent");
            return t.initCustomEvent(A, e.bubbles, e.cancelable, e.detail), t;
          }
          (A.prototype = window.Event.prototype), (window.CustomEvent = A);
        })(),
        (function () {
          function A(e, t) {
            if ((n(this, A), !e))
              throw new Error(
                "No uploadId found. You must initialize file-upload-with-preview with a unique uploadId."
              );
            if (
              ((this.uploadId = e),
                (this.options = t || {}),
                (this.options.showDeleteButtonOnImages =
                  !this.options.hasOwnProperty("showDeleteButtonOnImages") ||
                  this.options.showDeleteButtonOnImages),
                (this.options.text = this.options.hasOwnProperty("text")
                  ? this.options.text
                  : { chooseFile: "Choose file..." }),
                (this.options.text.chooseFile = this.options.text.hasOwnProperty(
                  "chooseFile"
                )
                  ? this.options.text.chooseFile
                  : "Choose file..."),
                (this.options.text.browse = this.options.text.hasOwnProperty("Pilih Gambar")
                  ? this.options.text.browse
                  : "Pilih Gambar"),
                (this.options.text.selectedCount = this.options.text.hasOwnProperty(
                  "selectedCount"
                )
                  ? this.options.text.selectedCount
                  : "files selected"),
                (this.cachedFileArray = []),
                (this.currentFileCount = 0),
                (this.el = document.querySelector(
                  '.custom-file-container[data-upload-id="'.concat(this.uploadId, '"]')
                )),
                !this.el)
            )
              throw new Error(
                "Could not find a 'custom-file-container' with the id of: ".concat(
                  this.uploadId
                )
              );
            if (
              ((this.input = this.el.querySelector('input[type="file"]')),
                (this.inputLabel = this.el.querySelector(
                  ".custom-file-container__custom-file__custom-file-control"
                )),
                (this.imagePreview = this.el.querySelector(
                  ".custom-file-container__image-preview"
                )),
                (this.clearButton = this.el.querySelector(
                  ".custom-file-container__image-clear"
                )),
                (this.inputLabel.innerHTML = this.options.text.chooseFile),
                this.addBrowseButton(this.options.text.browse),
                !(
                  this.el &&
                  this.input &&
                  this.inputLabel &&
                  this.imagePreview &&
                  this.clearButton
                ))
            )
              throw new Error(
                "Cannot find all necessary elements. Please make sure you have all the necessary elements in your html for the id: ".concat(
                  this.uploadId
                )
              );
            (this.options.images = this.options.hasOwnProperty("images")
              ? this.options.images
              : {}),
              (this.baseImage = this.options.images.hasOwnProperty("baseImage")
                ? this.options.images.baseImage

                : "https://s8-api.arthaskyline.com/images/default-images.png"),
              (this.successPdfImage = this.options.images.hasOwnProperty(
                "successPdfImage"
              )
                ? this.options.images.successPdfImage
                : "https://s8-api.arthaskyline.com/images/default-images.png"),
              (this.successVideoImage = this.options.images.hasOwnProperty(
                "successVideoImage"
              )
                ? this.options.images.successVideoImage
                : "https://s8-api.arthaskyline.com/images/default-images.png"),
              (this.successFileAltImage = this.options.images.hasOwnProperty(
                "successFileAltImage"
              )
                ? this.options.images.successFileAltImage
                : "https://s8-api.arthaskyline.com/images/default-images.png"),
              (this.backgroundImage = this.options.images.hasOwnProperty(
                "backgroundImage"
              )
                ? this.options.images.backgroundImage
                : ""),
              this.bindClickEvents(),
              (this.imagePreview.style.backgroundImage = 'url("'.concat(
                this.baseImage,
                '")'
              )),
              (this.options.presetFiles = this.options.hasOwnProperty("presetFiles")
                ? this.options.presetFiles
                : null),
              this.options.presetFiles &&
              this.addImagesFromPath(this.options.presetFiles)
                .then(function () { })
                .catch(function (A) {
                  console.log("Error - " + A.toString()),
                    console.log(
                      "Warning - An image you added from a path is not able to be added to the cachedFileArray."
                    );
                });
          }
          return (
            B(A, [
              {
                key: "bindClickEvents",
                value: function () {
                  var A = this,
                    e = this;
                  e.input.addEventListener(
                    "change",
                    function () {
                      e.addFiles(this.files);
                    },
                    !0
                  ),
                    this.clearButton.addEventListener(
                      "click",
                      function () {
                        A.clearPreviewPanel();
                      },
                      !0
                    ),
                    this.imagePreview.addEventListener("click", function (e) {
                      if (
                        e.target.matches(
                          ".custom-file-container__image-multi-preview__single-image-clear__icon"
                        )
                      ) {
                        var t = e.target.getAttribute("data-upload-token"),
                          g = A.cachedFileArray.findIndex(function (A) {
                            return A.token === t;
                          });
                        A.deleteFileAtIndex(g);
                      }
                    });
                },
              },
              {
                key: "addFiles",
                value: function (A) {
                  if (0 !== A.length) {
                    this.input.multiple
                      ? (this.currentFileCount += A.length)
                      : ((this.currentFileCount = A.length), (this.cachedFileArray = []));
                    for (var e = 0; e < A.length; e++) {
                      var t = A[e];
                      (t.token =
                        Math.random().toString(36).substring(2, 15) +
                        Math.random().toString(36).substring(2, 15)),
                        this.cachedFileArray.push(t),
                        this.processFile(t);
                    }
                    var g = new CustomEvent("fileUploadWithPreview:imagesAdded", {
                      detail: {
                        uploadId: this.uploadId,
                        cachedFileArray: this.cachedFileArray,
                        addedFilesCount: A.length,
                      },
                    });
                    window.dispatchEvent(g);
                  }
                },
              },
              {
                key: "processFile",
                value: function (A) {
                  var e = this;
                  0 === this.currentFileCount
                    ? (this.inputLabel.innerHTML = this.options.text.chooseFile)
                    : 1 === this.currentFileCount
                      ? (this.inputLabel.innerHTML = A.name)
                      : (this.inputLabel.innerHTML = ""
                        .concat(this.currentFileCount, " ")
                        .concat(this.options.text.selectedCount)),
                    this.addBrowseButton(this.options.text.browse),
                    this.imagePreview.classList.add(
                      "custom-file-container__image-preview--active"
                    );
                  var t = new FileReader();
                  t.readAsDataURL(A),
                    (t.onload = function () {
                      e.input.multiple ||
                        (A.type.match("image/png") ||
                          A.type.match("image/jpeg") ||
                          A.type.match("image/gif")
                          ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
                            t.result,
                            '")'
                          ))
                          : A.type.match("application/pdf")
                            ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
                              e.successPdfImage,
                              '")'
                            ))
                            : A.type.match("video/*")
                              ? (e.imagePreview.style.backgroundImage = 'url("'.concat(
                                e.successVideoImage,
                                '")'
                              ))
                              : (e.imagePreview.style.backgroundImage = 'url("'.concat(
                                e.successFileAltImage,
                                '")'
                              ))),
                        e.input.multiple &&
                        ((e.imagePreview.style.backgroundImage = 'url("'.concat(
                          e.backgroundImage,
                          '")'
                        )),
                          A.type.match("image/png") ||
                            A.type.match("image/jpeg") ||
                            A.type.match("image/gif")
                            ? e.options.showDeleteButtonOnImages
                              ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
                                .concat(
                                  A.token,
                                  '"\n                                style="background-image: url(\''
                                )
                                .concat(
                                  t.result,
                                  '\'); "\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
                                )
                                .concat(
                                  A.token,
                                  '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
                                ))
                              : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
                                .concat(
                                  A.token,
                                  '"\n                                style="background-image: url(\''
                                )
                                .concat(
                                  t.result,
                                  "'); \"\n                            ></div>\n                        "
                                ))
                            : A.type.match("application/pdf")
                              ? e.options.showDeleteButtonOnImages
                                ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
                                  .concat(
                                    A.token,
                                    '"\n                                style="background-image: url(\''
                                  )
                                  .concat(
                                    e.successPdfImage,
                                    '\'); "\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
                                  )
                                  .concat(
                                    A.token,
                                    '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
                                  ))
                                : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                data-upload-token="'
                                  .concat(
                                    A.token,
                                    '"\n                                style="background-image: url(\''
                                  )
                                  .concat(
                                    e.successPdfImage,
                                    "'); \"\n                            ></div>\n                        "
                                  ))
                              : A.type.match("video/*")
                                ? e.options.showDeleteButtonOnImages
                                  ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
                                    .concat(
                                      e.successVideoImage,
                                      '\'); "\n                                data-upload-token="'
                                    )
                                    .concat(
                                      A.token,
                                      '"\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
                                    )
                                    .concat(
                                      A.token,
                                      '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
                                    ))
                                  : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
                                    .concat(
                                      e.successVideoImage,
                                      '\'); "\n                                data-upload-token="'
                                    )
                                    .concat(
                                      A.token,
                                      '"\n                            ></div>\n                        '
                                    ))
                                : e.options.showDeleteButtonOnImages
                                  ? (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
                                    .concat(
                                      e.successFileAltImage,
                                      '\'); "\n                                data-upload-token="'
                                    )
                                    .concat(
                                      A.token,
                                      '"\n                            >\n                                <span class="custom-file-container__image-multi-preview__single-image-clear">\n                                    <span\n                                        class="custom-file-container__image-multi-preview__single-image-clear__icon"\n                                        data-upload-token="'
                                    )
                                    .concat(
                                      A.token,
                                      '"\n                                    >&times;</span>\n                                </span>\n                            </div>\n                        '
                                    ))
                                  : (e.imagePreview.innerHTML += '\n                            <div\n                                class="custom-file-container__image-multi-preview"\n                                style="background-image: url(\''
                                    .concat(
                                      e.successFileAltImage,
                                      '\'); "\n                                data-upload-token="'
                                    )
                                    .concat(
                                      A.token,
                                      '"\n                            ></div>\n                        '
                                    )));
                    });
                },
              },
              {
                key: "addImagesFromPath",
                value: function (A) {
                  var t = this;
                  return new Promise(
                    (function () {
                      var n = g(
                        e.mark(function g(n, i) {
                          var B, E, r, C, o;
                          return e.wrap(
                            function (e) {
                              for (; ;)
                                switch ((e.prev = e.next)) {
                                  case 0:
                                    (B = []), (E = 0);
                                  case 2:
                                    if (!(E < A.length)) {
                                      e.next = 24;
                                      break;
                                    }
                                    return (
                                      (r = void 0),
                                      (C = void 0),
                                      (e.prev = 5),
                                      (e.next = 8),
                                      fetch(A[E], { mode: "cors" })
                                    );
                                  case 8:
                                    return (r = e.sent), (e.next = 11), r.blob();
                                  case 11:
                                    (C = e.sent), (e.next = 18);
                                    break;
                                  case 14:
                                    return (
                                      (e.prev = 14),
                                      (e.t0 = e.catch(5)),
                                      i(e.t0),
                                      e.abrupt("continue", 21)
                                    );
                                  case 18:
                                    ((o = new Blob([C], { type: C.type })).name = A[
                                      E
                                    ].split("/").pop()),
                                      B.push(o);
                                  case 21:
                                    E++, (e.next = 2);
                                    break;
                                  case 24:
                                    t.addFiles(B), n();
                                  case 26:
                                  case "end":
                                    return e.stop();
                                }
                            },
                            g,
                            null,
                            [[5, 14]]
                          );
                        })
                      );
                      return function (A, e) {
                        return n.apply(this, arguments);
                      };
                    })()
                  );
                },
              },
              {
                key: "replaceFiles",
                value: function (A) {
                  if (!A.length) throw new Error("Array must contain at least one file.");
                  (this.cachedFileArray = A), this.refreshPreviewPanel();
                },
              },
              {
                key: "replaceFileAtIndex",
                value: function (A, e) {
                  if (!A) throw new Error("No file found.");
                  if (!this.cachedFileArray[e])
                    throw new Error("There is no file at index", e);
                  (this.cachedFileArray[e] = A), this.refreshPreviewPanel();
                },
              },
              {
                key: "deleteFileAtIndex",
                value: function (A) {
                  if (!this.cachedFileArray[A])
                    throw new Error("There is no file at index", A);
                  this.cachedFileArray.splice(A, 1), this.refreshPreviewPanel();
                  var e = new CustomEvent("fileUploadWithPreview:imageDeleted", {
                    detail: {
                      uploadId: this.uploadId,
                      cachedFileArray: this.cachedFileArray,
                      currentFileCount: this.currentFileCount,
                    },
                  });
                  window.dispatchEvent(e);
                },
              },
              {
                key: "refreshPreviewPanel",
                value: function () {
                  var A = this;
                  (this.imagePreview.innerHTML = ""),
                    (this.currentFileCount = this.cachedFileArray.length),
                    this.cachedFileArray.forEach(function (e) {
                      return A.processFile(e);
                    }),
                    this.cachedFileArray.length || this.clearPreviewPanel();
                },
              },
              {
                key: "addBrowseButton",
                value: function (A) {
                  this.inputLabel.innerHTML += '<span class="custom-file-container__custom-file__custom-file-control__button"> '.concat(
                    A,
                    " </span>"
                  );
                },
              },
              {
                key: "emulateInputSelection",
                value: function () {
                  this.input.click();
                },
              },
              {
                key: "clearPreviewPanel",
                value: function () {
                  (this.input.value = ""),
                    (this.inputLabel.innerHTML = this.options.text.chooseFile),
                    this.addBrowseButton(this.options.text.browse),
                    (this.imagePreview.style.backgroundImage = 'url("'.concat(
                      this.baseImage,
                      '")'
                    )),
                    this.imagePreview.classList.remove(
                      "custom-file-container__image-preview--active"
                    ),
                    (this.cachedFileArray = []),
                    (this.imagePreview.innerHTML = ""),
                    (this.currentFileCount = 0);
                },
              },
            ]),
            A
          );
        })()
      );
    })();

    // First upload
    let gambar2 = new FileUploadWithPreview("product-image");
    this.$refs.gambar.style.backgroundImage = `url(` + this.currentData.gambar + `)`;

    this.handleSatuanList();  // satuan produk
    this.handleStatusList(); //status produk
    this.handleTipeProduk(); //tipe produk

  },

  created() {

    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.prepareData()
    CloseLoading()
    
    // const needData2 = JSON.parse(localStorage.getItem('needData2'));
    // needData2.forEach((value, index) => {
    //   let item_prepare = { value: value.id, text: value.nama };
    //   this.needData2.push(item_prepare)
    // });
    // const needData3 = JSON.parse(localStorage.getItem('needData3'));
    // needData3.forEach((value, index) => {
    //   let item_prepare = { value: value.id, text: value.nama };
    //   this.needData3.push(item_prepare)
    // });

  },
  components: {
    Editor
  },
  watch: {
    // 'currentData.rate': {
    //   handler(newValue) {
    //     this.currentData.rate2 = newValue;
    //     this.currentData.rate3 = newValue;
    //     this.currentData.rate4 = newValue;
    //     this.currentData.rate5 = newValue;
    //     this.currentData.rate6 = newValue;

    //   }
    // },
    // 'currentData.rate2': {
    //   handler(newValue) {
    //     this.currentData.rate3 = newValue;
    //     this.currentData.rate4 = newValue;
    //     this.currentData.rate5 = newValue;
    //     this.currentData.rate6 = newValue;
    //   }
    // },
    // 'currentData.rate3': {
    //   handler(newValue) {
    //     this.currentData.rate4 = newValue;
    //     this.currentData.rate5 = newValue;
    //     this.currentData.rate6 = newValue;
    //   }
    // },
    // 'currentData.rate4': {
    //   handler(newValue) {
    //     this.currentData.rate5 = newValue;
    //     this.currentData.rate6 = newValue;
    //   }
    // },
    // 'currentData.rate5': {
    //   handler(newValue) {
    //     this.currentData.rate6 = newValue;
    //   }
    // },
  },
  methods: {
    rupiahoutput,
    activePage(page) {
      this.active_page = page;
      localStorage.setItem("active_page", page)
    },

    addNewRow() {
      this.currentData.minimal_order.push({
        kemasan: '',
        jumlah: '',
        satuan: '',
        
      });
    },

    formatCurrency(label, number) {
        this.currentData[label] = rupiahInput(number);
    },

    handleSatuanList() {
      const hardcodedSatuanList = [
        { id: "BUAH", nama: 'buah' },
        { id: "GRAM", nama: 'gram' },
        { id: "KILOGRAM", nama: 'kilogram' },
        { id: "LITER", nama: 'liter' },
        { id: "METER", nama: 'meter' },
        { id: "CENTIMETER", nama: 'centimeter' },
        { id: "MILIMETER", nama: 'milimeter' },
        { id: "INCH", nama: 'inch' },
      ];

      hardcodedSatuanList.forEach((item) => {
        this.satuanList.push({
          value: item.id,
          text: item.nama,
        });
      });
    },

    handleStatusList() {
      const hardcodedStatusList = [
        { id: "PRE ORDER", nama: 'pre order' },
        { id: "READY STOCK", nama: 'ready stock' },
        
      ];

      hardcodedStatusList.forEach((item) => {
        this.statusList.push({
          value: item.id,
          text: item.nama,
        });
      });
    },

    handleTipeProduk() {
      const hardcodedTipeProduk = [
        { id: "LANGSUNG JUAL", nama: 'langsung jual' },
        { id: "RAKITAN", nama: 'rakitan' },
        
      ];

      hardcodedTipeProduk.forEach((item) => {
        this.tipeProduk.push({
          value: item.id,
          text: item.nama,
        });
      });
    },

    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result); // Resolve with the result after loading
        };
        reader.onerror = reject; // Reject if an error occurs
        reader.readAsDataURL(file);
      });
    },

    updateIsGambar() {
      if (this.$refs.gambar_update.value != "") {
        this.isGambar = true;
      } else {
        this.isGambar = false;
      }
      const file = this.$refs.gambar_update.files[0];
      if (file) {
        this.convertToBase64(file).then(base64String => {
          this.gambar_file_64 = base64String;
        });
      }
    },

    // formatCurrency(number, index) {
    //   const indexrate = index + 1;
    //   if (indexrate == 1) {
    //     this.currentData['rate'] = rupiahInput(number);
    //   } else {
    //     this.currentData['rate' + indexrate] = rupiahInput(number);
    //   }
    // },


    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },


    // cekDepositMarketing() {
    //   if (this.currentData.ismarketing == true) {
    //     this.disabledDeposit = true;
    //     this.currentData.isdeposit = false;
    //   } else if (this.currentData.ismarketing == false) {
    //     this.disabledDeposit = false;
    //     // this.currentData.isdeposit = true;
    //   }

    //   if (this.currentData.isdeposit == true) {

    //     this.disabledMarketing = true;
    //     // this.currentData.ismarketing = false;
    //   } else if (this.currentData.isdeposit == false) {
    //     this.disabledMarketing = false;
    //     // this.currentData.ismarketing = true;
    //   }
    // },

    rupiahInputWithoutDecimal(value) {
      if (!value) return '0'; // Return '0' if value is null or undefined
      value = value.toString().replace(/\.00$/, ''); // Remove `.00` if it exists
      return rupiahInput(value); // Apply your existing rupiah formatting
    },

    prepareData() {
      this.currentData = JSON.parse(localStorage.getItem('currentData'));
      
      // data gudang
      // const needData1 = JSON.parse(localStorage.getItem('needData1'));
      // needData1.forEach((value, index) => {
      //   let item_prepare = { value: value.id, text: value.nama };
      //   this.needData1.push(item_prepare)
      // });

      // data merek
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.nama };
        this.needData2.push(item_prepare)
      });

      // data group product
      const needData3 = JSON.parse(localStorage.getItem('needData3'));
      needData3.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.name };
        this.needData3.push(item_prepare)
      });

      // data list ketegori
      const needData4 = JSON.parse(localStorage.getItem('needData4'));
      needData4.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.nama }
        this.needData4.push(item_prepare);
      });


      // const index = needData1.findIndex((item) => item.id == this.currentData.id_warehouse);
      // // console.log(this.currentData.bidang_industri)
      // // console.log('ini id:', this.currentData.bidang_industri)
      // // console.log('ini option', needData1)
      // console.log(index)
      // if ( index > -1) {
      //   this.currentData.id_warehouse = this.needData1[index].value;
      // }

      const indexMerek = needData2.findIndex((item) => item.id == this.currentData.id_merek);
      // console.log(this.currentData.bidang_industri)
      // console.log('ini id:', this.currentData.bidang_industri)
      // console.log('ini option', needData2)
      console.log(indexMerek)
      if ( indexMerek > -1) {
        this.currentData.id_merek = this.needData2[indexMerek].value;
      }


      const indexGroupProduk = needData3.findIndex((item) => item.id == this.currentData.id_group_product);
      // console.log(this.currentData.bidang_industri)
      // console.log('ini id:', this.currentData.bidang_industri)
      // console.log('ini option', needData3)
      console.log(indexGroupProduk)
      if ( indexGroupProduk > -1) {
        this.currentData.id_group_product = this.needData3[indexGroupProduk].value;
      }


      const indexListKategori = needData4.findIndex((item) => item.id == this.currentData.id_kategori);
      // console.log(this.currentData.bidang_industri)
      // console.log('ini id:', this.currentData.bidang_industri)
      // console.log('ini option', needData4)
      console.log(indexListKategori)
      if ( indexListKategori > -1) {
        this.currentData.id_kategori = this.needData4[indexListKategori].value;
      }


       // Prepare tipe produk (hardcoded data)
      // this.handleTipeProduk();
      const indexTipeProduk = this.tipeProduk.findIndex((item) => item.id === this.currentData.type_product);
      // console.log('ini tipe produk :', indexTipeProduk)
      // console.log('ini id tipe produk :', this.currentData.type_product)
      if (indexTipeProduk > -1) {
        this.currentData.type_product = this.tipeProduk[indexTipeProduk].value;
      }

       // Prepare satuan produk berat (hardcoded data)
      // this.handleSatuanList();
      const indexSatuanList = this.satuanList.findIndex((item) => item.id === this.currentData.satuan_berat);
      // console.log('ini satuan produk :', indexSatuanList)
      // console.log('ini id satuan :', this.currentData.satuan_berat)
      if (indexSatuanList > -1) {
        this.currentData.satuan_berat = this.satuanList[indexSatuanList].value;

      }

       // Prepare status produk (hardcoded data)
      // this.handleStatusList();
      const indexStatusProduk = this.statusList.findIndex((item) => item.id === this.currentData.status_product);
      // console.log('ini satuan produk :', indexStatusProduk)
      // console.log('ini id satuan :', this.currentData.status_product)
      if (indexStatusProduk > -1) {
        this.currentData.satuan_berat = this.statusList[indexStatusProduk].value;

      }


      this.currentData.modal_price = this.rupiahInputWithoutDecimal(this.currentData.modal_price);
      this.currentData.pricelist_price = this.rupiahInputWithoutDecimal(this.currentData.pricelist_price);
      this.currentData.enduser_price = this.rupiahInputWithoutDecimal(this.currentData.enduser_price);
      this.currentData.retail_price = this.rupiahInputWithoutDecimal(this.currentData.retail_price);
      this.currentData.general_price = this.rupiahInputWithoutDecimal(this.currentData.general_price);
      this.currentData.special_agent_price = this.rupiahInputWithoutDecimal(this.currentData.special_agent_price);
      this.currentData.supplier_price = this.rupiahInputWithoutDecimal(this.currentData.supplier_price);
      this.currentData.ecommerce_price = this.rupiahInputWithoutDecimal(this.currentData.ecommerce_price);

      // this.currentData.modal_price = rupiahInput(this.currentData.modal_price);
      
      // this.currentData.pricelist_price = rupiahInput(this.currentData.pricelist_price);
      
      // this.currentData.enduser_price = rupiahInput(this.currentData.enduser_price);
      
      // this.currentData.retail_price = rupiahInput(this.currentData.retail_price);
      
      // this.currentData.general_price = rupiahInput(this.currentData.general_price);
      
      // this.currentData.special_agent_price = rupiahInput(this.currentData.special_agent_price);
      
      // this.currentData.supplier_price = rupiahInput(this.currentData.supplier_price);
      
      // this.currentData.ecommerce_price = rupiahInput(this.currentData.ecommerce_price);

    },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("gambar");
      if (this.$refs.gambar.value != "") {
        formData.append("gambar", this.gambar_file_64);
      }

      formData.delete("minimal_order")
      formData.append('minimal_order', JSON.stringify(this.currentData.minimal_order));

      formData.delete("modal_price");
      formData.append("modal_price", rupiahoutput(this.currentData.modal_price));
      formData.delete("pricelist_price");
      formData.append("pricelist_price", rupiahoutput(this.currentData.pricelist_price));
      formData.delete("enduser_price");
      formData.append("enduser_price", rupiahoutput(this.currentData.enduser_price));
      formData.delete("retail_price");
      formData.append("retail_price", rupiahoutput(this.currentData.retail_price));
      formData.delete("general_price");
      formData.append("general_price", rupiahoutput(this.currentData.general_price));
      formData.delete("special_agent_price");
      formData.append("special_agent_price", rupiahoutput(this.currentData.special_agent_price));
      formData.delete("supplier_price");
      formData.append("supplier_price", rupiahoutput(this.currentData.supplier_price));
      formData.delete("ecommerce_price");
      formData.append("ecommerce_price", rupiahoutput(this.currentData.ecommerce_price));

      // formData.delete("list_items_in_warehouse_detail");
      // formData.append("list_items_in_warehouse_detail", JSON.stringify(this.currentData.list_items_in_warehouse_detail));

      // for (let i = 1; i <= 6; i++) {
      //   if (i == 1) {
      //     formData.delete("rate");
      //     formData.append("rate", rupiahoutput(this.currentData.rate));
      //   } else {
      //     formData.delete("rate" + i);
      //     formData.append("rate" + i, rupiahoutput(this.currentData['rate' + i]));
      //   }
      // }

      // formData.delete("on_katalog");
      // if (this.currentData.on_katalog == true) {
      //   formData.append('on_katalog', 'AKTIF');
      // } else {
      //   formData.append('on_katalog', 'TIDAK');
      // }

      // formData.delete("on_jual");
      // if (this.currentData.on_jual == true) {
      //   formData.append('on_jual', 'AKTIF');
      // } else {
      //   formData.append('on_jual', 'TIDAK');
      // }

      // formData.delete("on_bungkus");
      // if (this.currentData.on_bungkus == true) {
      //   formData.append('on_bungkus', 'AKTIF');
      // } else {
      //   formData.append('on_bungkus', 'TIDAK');
      // }

      if( !this.currentData.code || this.currentData.code  === ''){
        AlertPopup("error", "Validation Error", "Kode SKU wajib diisi!", 1500, false)
        return;
      }else if ( !this.currentData.name || this.currentData.name === ''){
        AlertPopup("error", "Validation Error", "Nama Produk tidak boleh kosong! ", 1500, false)
        return;
      }

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      // CloseLoading()
      // return true

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'items_update';
      } else {
        this.api = base_url + 'items_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("product");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    //crud unit gudang 
    // warehouseFilterSet(id_warehouse) {
    //   const needData2 = this.needData2;

    //   this.warehouseDataFilter = [];
    //   if (id_warehouse == undefined) {

    //     this.warehouseDataFilter = needData2;
    //     this.currentData.list_items_in_warehouse_detail.forEach((value, index) => {
    //       const indexWarehouse = this.warehouseDataFilter.findIndex((record) => record.value == value.id_warehouse);
    //       // this.warehouseDataFilter.splice(indexWarehouse, 1);
    //     });
    //   } else {
    //     this.warehouseDataFilter = needData2;
    //     this.currentData.list_items_in_warehouse_detail.forEach((value, index) => {

    //       const indexWarehouse = this.warehouseDataFilter.findIndex((record) => record.value == value.id_warehouse);
    //       console.log(value.id_warehouse);
    //       console.log(id_warehouse);
    //       console.log(indexWarehouse);
    //       console.log('test');
    //       // if (value.id_warehouse != id_warehouse) {
    //       //   this.warehouseDataFilter.splice(indexWarehouse, 1);
    //       // }

    //     });
    //   }


    // },

    // addItem() {
    //   this.itemEdited = {
    //     id_warehouse: "",
    //     id_item: 0,
    //     unit: 0,
    //     status: 'tambah',
    //     from: 'insert',
    //   }

    //   const openButton = this.$refs.modalproductwarehouse;
    //   openButton.click();
    // },
    // handleItemAdd(item) {

    //   const index = this.needData2.findIndex((record) => record.value == item.id_warehouse);
    //   const indexDetail = this.currentData.list_items_in_warehouse_detail.findIndex((record) => record.id_warehouse == item.id_warehouse);

    //   const itemPrepare = {
    //     id_item: item.id,
    //     id_warehouse: item.id_warehouse,
    //     unit: item.unit,
    //     nama: this.needData2[index].text,
    //   };

    //   if (item.status == 'tambah') {
    //     if (indexDetail > -1) {
    //       AlertPopup("error", "", "Gudang Sudah ada didalam daftar", 1500, false);
    //       return true;
    //     }
    //     this.currentData.list_items_in_warehouse_detail.push(itemPrepare);

    //   } else {
    //     this.currentData.list_items_in_warehouse_detail[indexDetail] = itemPrepare;
    //   }


    // },
    // editItem(item) {
    //   this.itemEdited = item;
    //   this.itemEdited['status'] = 'ubah';
    //   this.itemEdited['from'] = 'insert';

    //   const openButton = this.$refs.modalproductwarehouse;
    //   openButton.click();
    // },
    // deleteItem(index) {
    //   this.currentData.list_items_in_warehouse_detail.splice(index, 1);
    // }

  },
  components: {
    ModelSelect
  },

};
</script>
