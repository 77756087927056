import { createRouter, createWebHistory } from "vue-router";

import Index from "../views/pages/dashboard/index";
import Signin from "../views/pages/auth/signin";
import Changepassword from "@/views/pages/auth/changepassword.vue";
import Confirmotp from "../views/pages/auth/confirmotp";
import Cookies from "js-cookie";
import User from "../views/pages/user/user";
import Adduser from "../views/pages/user/adduser";
import Edituser from "../views/pages/user/edituser";


// import { getMessaging, getToken, onMessage } from "firebase/messaging"; // firebase
// import { messaging, firebaseConfig } from "@/assets/js/firbase";
import Category from "../views/pages/category/category";
import Addcategory from "../views/pages/category/addcategory";
import Editcategory from "../views/pages/category/editcategory";
import Warehouse from "../views/pages/warehouse/warehouse";
import Addwarehouse from "../views/pages/warehouse/addwarehouse";
import Editwarehouse from "../views/pages/warehouse/editwarehouse";
import Suplier from "../views/pages/suplier/suplier";
// import Addsuplier from "../views/pages/suplier/addsuplier";
import Editsuplier from "../views/pages/suplier/editsuplier";
import Customer from "../views/pages/customer/customer";
import Addcustomer from "../views/pages/customer/addcustomer";
import Editcustomer from "../views/pages/customer/editcustomer";
import Profile from "../views/pages/profile";
import settingsprofilecompany from "@/views/pages/settingsprofilecompany.vue";
import Product from "../views/pages/product/product";
import Addproduct from "../views/pages/product/addproduct";
import Editproduct from "../views/pages/product/editproduct";

import Purchase from "../views/pages/purchase/purchase";
import Addpurchase from "@/views/pages/purchase/addpurchase";
import Editpurchase from "../views/pages/purchase/editpurchase";
import viewpurchase from "@/views/pages/purchase/viewpurchase";

import Salesorder from "../views/pages/salesorder/salesorder";
import Addsalesorder from "@/views/pages/salesorder/addsalesorder";
import Editsalesorder from "../views/pages/salesorder/editsalesorder";
import viewsalesorder from "@/views/pages/salesorder/viewsalesorder";

import Invoice from "../views/pages/invoice/invoice";
import Addinvoice from "@/views/pages/invoice/addinvoice";
import Editinvoice from "../views/pages/invoice/editinvoice";
import viewinvoice from "@/views/pages/invoice/viewinvoice";

import Merek from "../views/pages/merek/merek";
import Addmerek from "../views/pages/merek/addmerek";
import Editmerek from "../views/pages/merek/editmerek";


import Movementitem from "../views/pages/movementitem/movementitem";
import Addmovementitem from "@/views/pages/movementitem/addmovementitem";
import Editmovementitem from "../views/pages/movementitem/editmovementitem";
import Viewmovementitem from "@/views/pages/movementitem/viewmovementitem";

import Daftarbank from "../views/pages/daftarbank/daftarbank";
import Adddaftarbank from "@/views/pages/daftarbank/adddaftarbank";
import Editdaftarbank from "../views/pages/daftarbank/editdaftarbank";

import Payment from "../views/pages/payment/payment";
import Addpayment from "@/views/pages/payment/addpayment";
import Editpayment from "../views/pages/payment/editpayment";

import Kategoripelanggan from "../views/pages/kategoripelanggan/kategoripelanggan";
import Addkategoripelanggan from "@/views/pages/kategoripelanggan/addkategoripelanggan";
import Editkategoripelanggan from "../views/pages/kategoripelanggan/editkategoripelanggan";

import Ekspedisi from "../views/pages/ekspedisi/ekspedisi";
import Addekspedisi from "@/views/pages/ekspedisi/addekspedisi";
import Editekspedisi from "../views/pages/ekspedisi/editekspedisi";

import kuitansi from "../views/pages/kuitansi/kuitansi";
import Addkuitansi from "@/views/pages/kuitansi/addkuitansi";
import Editkuitansi from "../views/pages/kuitansi/editkuitansi";

// kuitansi AP
import KuitansiAp from "../views/pages/ap/ap.vue";
import AddKuitansiap from "../views/pages/ap/addap.vue";
import EditKuitansiap from "../views/pages/ap/editap.vue";

import StoreList from "../views/pages/places/storelist.vue";
import Purchaseorder from "@/views/pages/purchaseorder/purchaseorder.vue";
import Addpurchaseorder from "@/views/pages/purchaseorder/addpurchaseorder.vue";
import Editpurchaseorder from "@/views/pages/purchaseorder/editpurchaseorder.vue";
import Goodreceipt from "@/views/pages/goodreceipt/goodreceipt.vue";
import Addgoodreceipt from "@/views/pages/goodreceipt/addgoodreceipt.vue";
import Purchaseinvoice from "@/views/pages/purchaseinvoice/purchaseinvoice.vue";
import Retourpurchase from "@/views/pages/retourpurchase/retourpurchase.vue";
import Downpayment from "@/views/pages/downpayment/downpayment.vue";
import Paymentorder from "@/views/pages/paymentorder/paymentorder.vue";
import Penawaran from "@/views/pages/penawaran/penawaran.vue";
import Addpenawaran from "@/views/pages/penawaran/addpenawaran.vue";
import Editpenawaran from "@/views/pages/penawaran/editpenawaran.vue";
import Ordersales from "@/views/pages/pesananpenjualan/ordersales.vue";
import Addordersales from "@/views/pages/pesananpenjualan/addordersales.vue";
import Pengirimanpesanan from "@/views/pages/pengirimanpesanan/pengirimanpesanan.vue";
import Addpengirimanpesanan from "@/views/pages/pengirimanpesanan/addpengirimanpesanan.vue";
import Fakturpenjualan from "@/views/pages/faktur/fakturpenjualan.vue";
import Addfaktur from "@/views/pages/faktur/addfaktur.vue";
import Uangmukapenjualan from "@/views/pages/uangmukapenjualan/uangmukapenjualan.vue";
import Adduangmukapenjualan from "@/views/pages/uangmukapenjualan/adduangmukapenjualan.vue";
import Penerimaanpenjualan from "@/views/pages/penerimaanpenjualan/penerimaanpenjualan.vue";
import Addpenerimaanpenjualan from "@/views/pages/penerimaanpenjualan/addpenerimaanpenjualan.vue";
import Addpurchaseinvoice from "@/views/pages/purchaseinvoice/addpurchaseinvoice.vue";
import Addretourpurchase from "@/views/pages/retourpurchase/addretourpurchase.vue";
import Adddownpayment from "@/views/pages/downpayment/adddownpayment.vue";
import Addpaymentorder from "@/views/pages/paymentorder/addpaymentorder.vue";
import Newaddsupplier from "@/views/pages/suplier/newaddsupplier.vue";
import Departemen from "@/views/pages/departemen/departemen.vue";
import Adddepartemen from "@/views/pages/departemen/adddepartemen.vue";
import Editdepartemen from "@/views/pages/departemen/editdepartemen.vue";
import Groupproduct from "@/views/pages/groupproduct/groupproduct.vue";
import Addgroupproduct from "@/views/pages/groupproduct/addgroupproduct.vue";
import Editgroupproduct from "@/views/pages/groupproduct/editgroupproduct.vue";
import Produkrakitan from "@/views/pages/produkrakitan/produkrakitan.vue";


const routes = [
  {
    path: "/",
    name: "signin",
    component: Signin,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/confirmotp",
    name: "confirmotp",
    component: Confirmotp,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: Changepassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/index",
    name: "index",
    component: Index,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/user",
    name: "user",
    component: User,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/adduser",
    name: "adduser",
    component: Adduser,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/edituser",
    name: "edituser",
    component: Edituser,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/settingsprofile",
    name: "settingsprofile",
    component: settingsprofilecompany,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  //first module kategori 
  {
    path: "/category",
    name: "category",
    component: Category,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },

  {
    path: "/addcategory",
    name: "addcategory",
    component: Addcategory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editcategory",
    name: "editcategory",
    component: Editcategory,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,


    },
  },
  // user
  {
    path: "/user",
    name: "user",
    component: User,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/adduser",
    name: "adduser",
    component: Adduser,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/edituser",
    name: "edituser",
    component: Edituser,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  //product
  {
    path: "/product",
    name: "product",
    component: Product,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addproduct",
    name: "addproduct",
    component: Addproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editproduct",
    name: "editproduct",
    component: Editproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },

  // group product
  {
    path: "/group_product",
    name: "group_product",
    component: Groupproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addgroup_product",
    name: "addgroup_product",
    component: Addgroupproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editgroup_product",
    name: "editgroup_product",
    component: Editgroupproduct,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },

  // produk rakitan
  {
    path: "/produk_rakitan",
    name: "produk_rakitan",
    component: Produkrakitan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  // {
  //   path: "/produk_rakitan",
  //   name: "produk_rakitan",
  //   component: Produkrakitan,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: false,
  //     requiresMenuAkses: false,
  //   },
  // },

  // warehouse
  {
    path: "/warehouse",
    name: "warehouse",
    component: Warehouse,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addwarehouse",
    name: "addwarehouse",
    component: Addwarehouse,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editwarehouse",
    name: "editwarehouse",
    component: Editwarehouse,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,


    },
  },
  // customer
  {
    path: "/customer",
    name: "customer",
    component: Customer,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addcustomer",
    name: "addcustomer",
    component: Addcustomer,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editcustomer",
    name: "editcustomer",
    component: Editcustomer,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },

  // penjualan
  {
    path: "/penawaran",
    name: "penawaran",
    component: Penawaran,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addpenawaran",
    name: "addpenawaran",
    component: Addpenawaran,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  // {
  //   path: "/editpenawaran",
  //   name: "editpenawaran",
  //   component: Editpenawaran,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/pesananpenjualan",
    name: "pesananpenjualan",
    component: Ordersales,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addpesananpenjualan",
    name: "addpesananpenjualan",
    component: Addordersales,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/penerimaanpenjualan",
    name: "penerimaanpenjualan",
    component: Penerimaanpenjualan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addpenerimaanpenjualan",
    name: "addpenerimaanpenjualan",
    component: Addpenerimaanpenjualan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  // {
  //   path: "/editpesananpenjualan",
  //   name: "editpesananpenjualan",
  //   component: Addordersales,//ganti jadi klo udh bikin page nya
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/pengirimanpesanan",
    name: "pengirimanpesanan",
    component: Pengirimanpesanan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addpengirimanpesanan",
    name: "addpengirimanpesanan",
    component: Addpengirimanpesanan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/fakturpenjualan",
    name: "fakturpenjualan",
    component: Fakturpenjualan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addfakturpenjualan",
    name: "addfakturpenjualan",
    component: Addfaktur,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/uangmukapenjualan",
    name: "uangmukapenjualan",
    component: Uangmukapenjualan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/adduangmukapenjualan",
    name: "adduangmukapenjualan",
    component: Adduangmukapenjualan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },



  // {
  //   path: "/customerhistory",
  //   name: "customerhistory",
  //   component: Customerhistory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,


  //   },
  // },
  // suplier
  {
    path: "/suplier",
    name: "suplier",
    component: Suplier,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addsuplier",
    name: "addsuplier",
    component: Newaddsupplier,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editsuplier",
    name: "editsuplier",
    component: Editsuplier,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  // {
  //   path: "/suplierhistory",
  //   name: "suplierhistory",
  //   component: suplierhistory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,


  //   },
  // },



  // {
  //   path: "/marketinghistory",
  //   name: "marketinghistory",
  //   component: marketinghistory,
  //   meta: {
  //     headerClass: "header",
  //     sidebarClass: "",
  //     requiresAuth: true,


  //   },
  // },


  //purchase 
  {
    path: "/purchase_request",
    name: "purchase_request",
    component: Purchase,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addpurchase_request",
    name: "addpurchase_request",
    component: Addpurchase,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editpurchase_request",
    name: "editpurchase_request",
    component: Editpurchase,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/viewpurchase_request",
    name: "viewpurchase_request",
    component: viewpurchase,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },

  {
    path: "/purchase_order",
    name: "purchase_order",
    component: Purchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addpurchase_order",
    name: "addpurchase_order",
    component: Addpurchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editpurchase_order",
    name: "editpurchase_order",
    component: Editpurchaseorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/goodsreceipt",
    name: "goodsreceipt",
    component: Goodreceipt,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/addgoodsreceipt",
    name: "addgoodsreceipt",
    component: Addgoodreceipt,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/purchaseinvoice",
    name: "purchaseinvoice",
    component: Purchaseinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/addpurchaseinvoice",
    name: "addpurchaseinvoice",
    component: Addpurchaseinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/retourpurchase",
    name: "retourpurchase",
    component: Retourpurchase,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/addretourpurchase",
    name: "addretourpurchase",
    component: Addretourpurchase,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/downpayment",
    name: "downpayment",
    component: Downpayment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/adddownpayment",
    name: "adddownpayment",
    component: Adddownpayment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/paymentorder",
    name: "paymentorder",
    component: Paymentorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },
  {
    path: "/addpaymentorder",
    name: "addpaymentorder",
    component: Addpaymentorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },



  //salesorder 
  {
    path: "/sales_order",
    name: "sales_order",
    component: Salesorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addsales_order",
    name: "addsales_order",
    component: Addsalesorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editsales_order",
    name: "editsales_order",
    component: Editsalesorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/viewsales_order",
    name: "viewsales_order",
    component: viewsalesorder,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },


  //invoice
  {
    path: "/invoice",
    name: "invoice",
    component: Invoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,

    },
  },
  {
    path: "/addinvoice",
    name: "addinvoice",
    component: Addinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editinvoice",
    name: "editinvoice",
    component: Editinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/viewinvoice",
    name: "viewinvoice",
    component: viewinvoice,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },

  //merek
  {
    path: "/merek",
    name: "merek",
    component: Merek,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addmerek",
    name: "addmerek",
    component: Addmerek,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editmerek",
    name: "editmerek",
    component: Editmerek,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,


    },
  },

  {
    path: "/movement_item",
    name: "movement_item",
    component: Movementitem,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addmovement_item",
    name: "addmovement_item",
    component: Addmovementitem,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editmovement_item",
    name: "editmovement_item",
    component: Editmovementitem,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,


    },
  },
  {
    path: "/viewmovement_item",
    name: "viewmovement_item",
    component: Viewmovementitem,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
    },
  },

  //daftar bank 
  {
    path: "/daftar_bank",
    name: "daftar_bank",
    component: Daftarbank,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/adddaftar_bank",
    name: "adddaftar_bank",
    component: Adddaftarbank,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editdaftar_bank",
    name: "editdaftar_bank",
    component: Editdaftarbank,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,


    },
  },


  {
    path: "/payment",
    name: "payment",
    component: Payment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/addpayment",
    name: "addpayment",
    component: Addpayment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },
  {
    path: "/editpayment",
    name: "editpayment",
    component: Editpayment,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,


    },
  },

  //kategori pelanggan
  {
    path: "/kategori_pelanggan",
    name: "kategori_pelanggan",
    component: Kategoripelanggan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addkategori_pelanggan",
    name: "addkategori_pelanggan",
    component: Addkategoripelanggan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editkategori_pelanggan",
    name: "editkategori_pelanggan",
    component: Editkategoripelanggan,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },

  {
    path: "/ekspedisi",
    name: "ekspedisi",
    component: Ekspedisi,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/addekspedisi",
    name: "addekspedisi",
    component: Addekspedisi,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },
  {
    path: "/editekspedisi",
    name: "editekspedisi",
    component: Editekspedisi,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
    },
  },

  //kuitansi
  {
    path: "/kuitansi",
    name: "kuitansi",
    component: kuitansi,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addkuitansi",
    name: "addkuitansi",
    component: Addkuitansi,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editkuitansi",
    name: "editkuitansi",
    component: Editkuitansi,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/departemen",
    name: "departemen",
    component: Departemen,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
      requiresMenuAkses: false,
    },
  },
  {
    path: "/adddepartemen",
    name: "adddepartemen",
    component: Adddepartemen,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
      requiresMenuAkses: false,
    },
  },
  {
    path: "/editdepartemen",
    name: "editdepartemen",
    component: Editdepartemen,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
      requiresMenuAkses: false,
    },
  },


  // kuitansi AP
  {
    path: "/ap",
    name: "ap",
    component: KuitansiAp,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/addap",
    name: "addap",
    component: AddKuitansiap,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/editap",
    name: "editap",
    component: EditKuitansiap,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: true,
      requiresMenuAkses: true,
    },
  },
  {
    path: "/storelist",
    name: "storelist",
    component: StoreList,
    meta: {
      headerClass: "header",
      sidebarClass: "",
      requiresAuth: false,
      requiresMenuAkses: false,
    },
  },

];
export const router = createRouter({
  // history: createWebHistory("/miniERP/"),
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  const auth_menu = JSON.parse(localStorage.getItem('auth_menu'));

  const token = Cookies.get("token_refresh");
  // kalo masuk yang harus pake token maka cek tokennya
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    token != "" &&
    token != undefined
  ) {
    if (to.matched.some((route) => route.meta.requiresMenuAkses == true)

    ) {
      //authorization
      if (to.name.includes('add') && auth_menu.some(item => item.menu.toLowerCase().includes(to.name.replace('add', '')))) {
        const index = auth_menu.findIndex((item) => item.menu.toLowerCase() == to.name.replace('add', ''));

        if (index > -1) {
          if (auth_menu[index].create == 'AKTIF') {
            next();
          } else {

            next({ name: "index" });
          }
        } else {
          next({ name: "index" });
        }


      } else if (to.name.includes('edit') && auth_menu.some(item => item.menu.toLowerCase().includes(to.name.replace('edit', '')))) {
        const index = auth_menu.findIndex((item) => item.menu.toLowerCase() == to.name.replace('edit', ''));
        if (index > -1) {
          if (auth_menu[index].update == 'AKTIF') {
            next();
          } else {

            next({ name: "index" });
          }
        } else {
          next({ name: "index" });
        }
      } else if (auth_menu.some(item => item.menu.toLowerCase().includes(to.name))) {

        next();

      } else {
        next({ name: "index" });
      }

    } else {
      next();
    }
    // kalo masuk yang harus pake token tapi tokennya kosong maka balik lagi ke login
  } else if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    (token == "" || token == undefined)
  ) {
    next({ name: "signin" });
    // kalo gk pake token bisa langsung akses pagenya
  } else if (to.matched.some((route) => route.meta.requiresAuth == false)) {
    next();
    // kalo tidak matching dengan routing apapun langsung direct ke index
  } else {

    next({ name: "index" });

  }


});
