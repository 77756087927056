<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" />
                <!-- <pageaddheader :title="title" :title1="title1" :items="items" /> -->
                <!-- /product list -->
                <div class="card" style="border: none !important;">
                    <div class="card-body pt-0">
                        <!-- <div class="table-top d-flex justify-content-between align-items-center">
                            <div class="feature-search d-flex  align-items-center">
                                <div class="icon-container me-2">
                                    
                                     <img src="../../../assets/img/icon-table-header2.png" alt="import" style="width: 24px; height: 24px; ">
                                </div>
                                <div class="icon-container me-2">
                                    
                                    <img src="../../../assets/img/icon-table-header3.png" alt="export" style="width: 24px; height: 24px; ">

                                </div>
                                <div class="icon-container me-2">
                                    <i class='bx bxs-printer'></i>
                                </div>
                                <div class="icon-container ">
                                    
                                    <img src="../../../assets/img/icon-table-header1.png" alt="export" style="width: 24px; height: 24px; ">

                                </div>
                            </div>
                            <div class="d-flex align-items-center ms-auto">

                                <div class="search-set d-flex align-items-center me-2">
                                    <div class="search-path">
                                        <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                            :class="{ setclose: filter }">
                                            <img src="@/assets/img/icons/filter.svg" alt="img" />
                                            <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                        </a>
                                    </div>
                                    
                                    <div class="search-input me-2">
                                            <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                                    alt="img" /></a>
                                            <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                                <label>
                                                    <input type="search" class="form-control form-control-sm"
                                                        placeholder="Cari..." aria-controls="DataTables_Table_0"
                                                        v-model="query" />
                                                </label>
                                            </div>
                                    </div>
                                    
                                </div>
                                <div class="add-set">
                                    <RouterLink to="addsuplier" class="btn btn-primary add-btn">
                                        <i class='bx bx-plus'></i>
                                    </RouterLink>
    
                                    <RouterLink to="addsuplier" class="btn btn-primary rounded-circle p-0" style="width: 35px; height: 35px; font-size: 24px;">
                                        <i class='bx bx-plus'></i>
                                    </RouterLink>
                                </div>
                            </div>
                            <div class="wordset">
                                <ul>
                                    <li>

                                        <div class="d-flex flex-column justify-content-center align-items-center ">

                                            <a href="javascript:void(0);" @click="exportExcel()"><img
                                                    src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                                            <span style="font-size: 10px; margin-top: -8px;">excel</span>
                                            
                                        </div>

                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="@/assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="@/assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <div class="table-responsive position-relative" >
                            <div class="feature-search position-absolute d-flex  align-items-center" v-if="isLoading == false" style="top: 15px; left: 0px; z-index: 1099;">
                                <!-- <div class="icon-container me-2">
                                    <i class='bx bx-import'></i>
                                     <img src="../../../assets/img/icon-table-header2.png" alt="import" style="width: 24px; height: 24px; ">
                                </div> -->
                                <div class="icon-container me-2" @click="convertToExcel">
                                    <!-- <i class='bx bx-export'></i> -->
                                    <img src="../../../assets/img/icon-table-header3.png" alt="export" style="width: 20px; height: 20px; ">
                                    

                                </div>
                                <!-- <div class="icon-container me-2">
                                    <i class='bx bxs-printer'></i>
                                </div> -->
                                <div class="icon-container ">
                                    <!-- <i class='bx bxs-cog'></i> -->
                                    <img src="../../../assets/img/icon-table-header1.png" alt="export" style="width: 20px; height: 20px; ">

                                </div>
                            </div>

                            <div class="search-set position-absolute  d-flex align-items-center me-2" v-if="isLoading == false" style="top: 15px; left: 80px; z-index: 1099;">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                
                                <div class="search-input me-2">
                                        <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                                alt="img" /></a>
                                        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm search-input"
                                                    placeholder="Cari..." aria-controls="DataTables_Table_0"
                                                    v-model="query" />
                                            </label>
                                        </div>
                                </div>
                                
                            </div>

                            <div class="add-set position-absolute " style="top: 15px; right: 30px; z-index: 1099;">
                                

                                <button type="button" @click="navigateTo('addsuplier')" class="btn btn-primary rounded-circle p-0" style="width: 35px; height: 35px; font-size: 24px;">
                                    <i class='bx bx-plus'></i>
                                </button>
                            </div>
                            
                            <a-table :columns="columns" :data-source="filteredData" :loading="tableLoading" @change="onChange" :pagination="{
                                position: ['top'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                showSizeChanger: false,
                            }" :class="{'custom-pt': isEmptyData}" :row-selection="{
                                    selectedRowKeys: selectedRowKeys,
                                    onChange: onSelectChange,
                                }" :row-key="record => record.id">
                                <template #bodyCell="{ column, record }">
                                    <!-- <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template> -->

                                    <template v-if="column.key === 'nama'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.fullname }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'outstanding'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <!-- <a href="javascript:void(0);">{{ rupiah(record.outstanding) }}</a> -->
                                             <!-- bikin kondisi kalo lebih besar dari 0 pake <span> -->
                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="@/assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <div class="d-flex align-items-center ">
                                            <!-- <router-link class="me-3 text-secondary fs-5 " to="customerhistory"
                                                @click="edit(record);">
                                                <MDBIcon icon="history " />
                                            </router-link> -->
                                            <!-- <router-link class="me-3" to="editsuplier" @click="edit(record);">
                                                <img src="@/assets/img/icons/edit.svg" alt="img" />
                                            </router-link>
                                            <a class="confirm-text" href="javascript:void(0);"
                                                @click="terminate(record.id, record.fullname); showModal('terminate');">
                                                <img src="@/assets/img/icons/delete.svg" alt="img" />
                                            </a> -->

                                            <a class="action-set" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="true">
                                                <!-- <i class="fa fa-ellipsis-v" aria-hidden="true"></i> -->
                                                <i class='bx bx-dots-horizontal-rounded' aria-hidden="true" style="font-size: 24px; color: black !important;"></i>
                                            </a>
                                            <ul class="dropdown-menu p-2">
                                                <li class="text-center" style="border-bottom: 1px solid #f4f4f4;">
                                                    <!-- <router-link class="me-3 dropdown-item" to="editsuplier" @click="edit(record);">
                                                        <img src="@/assets/img/icons/edit.svg" alt="img" /> Edit 
                                                    </router-link> -->
                                                    <router-link to="editsuplier" class="dropdown-item d-flex justify-content-center" @click="edit(record);"><img
                                                        src="@/assets/img/new-icon/Edit_light.png" class="me-2" alt="img" />
                                                        Edit</router-link>
                                                </li>
                                                <li>
                                                    <a class="confirm-text dropdown-item d-flex justify-content-center" href="javascript:void(0);"
                                                        @click="terminate(record.id, record.fullname); showModal('terminate');">
                                                        <img src="@/assets/img/new-icon/Trash.png" alt="img" class="me-2" /> Hapus
                                                    </a>
                                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-bs-toggle="modal"
                                                        data-bs-target="#showpayment"><img src="../../../assets/img/icons/dollar-square.svg"
                                                        class="me-2" alt="img" />Show Payments</a> -->
                                                </li>
                                                
                                                
                                            </ul>
                                        </div>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Apakah anda yakin ingin menghapus data ini?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary"
                            @click="hideModal2('terminate')">cancel</button>
                        <button type="submit" class="btn  btn-danger">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import Cookies from "js-cookie";
import axios from "axios";
import { MDBIcon } from "mdb-vue-ui-kit";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, goPage, base_url, AlertPopup, rupiah, AlertBottom, openInNewTab } from "@/assets/js/function";
// import ExcellentExport from '@/assets/js/excellentexport';
import XLSX from "xlsx/dist/xlsx.full.min.js";


export default {

    components: {
        MDBIcon,
    },
    data() {
        return {
            filter: false,
            title: "Daftar Pemasok",
            title1: "Atur pemasok",
            items: [
                {
                    text: "",
                    addname: "/addsuplier",
                },
            ],

            //data
            data: [],
            columns: [
                // {
                //     title: "No",
                //     dataIndex: "no",
                //     key: "no",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.no.toLowerCase();
                //             b = b.no.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Nama Perusahaan",
                    dataIndex: "nama_perusahaan",
                    key: "nama_perusahaan",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_perusahaan.toLowerCase();
                            b = b.nama_perusahaan.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Telepon",
                    dataIndex: "no_telepon",
                    key: "no_telepon",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no_telepon.toLowerCase();
                            b = b.no_telepon.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Whatsapp",
                    dataIndex: "whatsapp",
                    key: "whatsapp",
                    sorter: {
                        compare: (a, b) => {
                            a = a.whatsapp.toLowerCase();
                            b = b.whatsapp.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Daerah ",
                    dataIndex: "provinsi",
                    key: "provinsi",
                    sorter: {
                        compare: (a, b) => {
                            a = a.provinsi.toLowerCase();
                            b = b.provinsi.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Hutang ",
                    dataIndex: "hutang",
                    key: "hutang",
                    sorter: {
                        compare: (a, b) => {
                            a = a.hutang.toLowerCase();
                            b = b.hutang.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                    
                },
                // {
                //     title: "Jumlah Deposit",
                //     dataIndex: "amount_deposit",
                //     key: "amount_deposit",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.amount_deposit.toLowerCase();
                //             b = b.amount_deposit.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },



                {
                    title: "",
                    key: "action",
                    sorter: false,
                },
            ],

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            //filter
            query: "",
            isLoading: true,

            tableLoading: true,

        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    },

    computed: {

        filteredData() {
            // Return all data immediately if no query
            if (!this.query) {
                return this.data;
            }

            const lowerCaseQuery = this.query.toLowerCase(); // Cache lowercased query for reuse
            let counter = 1; // Initialize counter for 'no'

            // Use a single loop to filter and reset 'no'
            const filteredAndReset = this.data.reduce((acc, item) => {
                const matchesQuery =
                    (item.nama_perusahaan?.toLowerCase() || "").includes(lowerCaseQuery) ||
                    (item.no_telepon?.toLowerCase() || "").includes(lowerCaseQuery) ||
                    (item.whatsapp?.toLowerCase() || "").includes(lowerCaseQuery) ||
                    (item.provinsi?.toLowerCase() || "").includes(lowerCaseQuery);

                if (matchesQuery) {
                    acc.push({
                        ...item,
                        no: counter++ // Assign new 'no' and increment counter
                    });
                }

                return acc;
            }, []);

            return filteredAndReset; // Already includes reset 'no'
        },



        // filteredData() {

        //     // Filter data based on selected jenis_kehadiran
        //     if (this.query == '') {
        //         return this.data; // If no filter selected, return all data
        //     }
        //     const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

        //     const filtered = this.data.filter(item =>
        //         (item.nama_perusahaan?.toLowerCase() || "").includes(lowerCaseQuery) ||
        //         (item.no_telepon?.toLowerCase() || "").includes(lowerCaseQuery) ||
        //         (item.whatsapp?.toLowerCase() || "").includes(lowerCaseQuery) ||
        //         (item.provinsi?.toLowerCase() || "").includes(lowerCaseQuery)
        //         // (item.outstanding?.toLowerCase() || "").includes(lowerCaseQuery)
        //         // (item.amount_deposit?.includes(lowerCaseQuery) || false)
        //     );
        //     // const filtered = this.data.filter(item =>
        //     //     item.nama_perusahaan.toLowerCase().includes(lowerCaseQuery) ||
        //     //     item.no_telepon.toLowerCase().includes(lowerCaseQuery) ||
        //     //     item.whatsapp.toLowerCase().includes(lowerCaseQuery) ||
        //     //     item.provinsi.toLowerCase().includes(lowerCaseQuery) 
        //     //     // item.outstanding.toLowerCase().includes(lowerCaseQuery)
        //     //     // item.amount_deposit.includes(lowerCaseQuery)
        //     // );
        //     // Sort the filtered data by 'no' in ascending order
        //     const sorted = filtered.sort((a, b) => a.no - b.no);

        //     // Reset 'no' values to start from 1
        //     const resetNos = sorted.map((item, index) => {
        //         return {
        //             ...item,
        //             no: index + 1 // Set 'no' to index + 1
        //         };
        //     });

        //     return resetNos;
        // },

        isEmptyData() {
            return this.filteredData.length === 0;
        },
    },
    methods: {
        rupiah,
        navigateTo(route) {
            goPage(route)
        },

        // show_izitoast() {
        //     AlertBottom('tessst');
        // },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },

        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const { nama_perusahaan, no_telepon, whatsapp, alamat_pembayaran, provinsi, daftar_bank, daftar_kontak, jenis_industri,
                    website, mata_uang, syarat_pembayaran, batas_hutang_jatuh_tempo, per_tanggal, no_pkp, pajak, keterangan_pembayaran, 
                    npwp, namaPic, catatan_umum, tipe_pajak, keterangan_pajak, alamat_pajak, provinsi_pajak,
                    kabupaten_pajak, kecamatan_pajak, kelurahan_pajak, kode_pos_pajak,  ...rest } = objArray[i];  // Extract fields to be renamed
                
                 // Flatten or format the array fields
                const formattedBanks = daftar_bank?.map(bank => `${bank.nama} (Atas Nama: ${bank.nama_account}, No. Rekening: ${bank.nomor_rekening}, Cabang: ${bank.cabang})`).join(',') || '';
                const formattedContacts = daftar_kontak?.map(contact => `${contact.nama} (No. Whatsapp: ${contact.whatsapp}, Jabatan: ${contact.posisi}, Agama: ${contact.agama})`).join(', ') || '';

                
                const newObj = {
                No: i + 1,
                "Nama Bank": nama_perusahaan,  // Rename id_customer to customer_id
                "Nama PIC": namaPic,
                "Telepon": no_telepon,
                "Whatsapp": whatsapp,
                "Daerah": provinsi,
                "Alamat Pembayaran": alamat_pembayaran,
                "Jenis Industri": jenis_industri,
                "Website": website,
                "Mata Uang": mata_uang,
                "Syarat Pembayaran": syarat_pembayaran,
                "Batas Hutang Jatuh Tempo": batas_hutang_jatuh_tempo,
                "Per Tanggal": per_tanggal,
                "NPWP": npwp,
                "No PKP": no_pkp,
                "Pajak": pajak,
                "Tipe Pajak": tipe_pajak,
                "Keterangan Pembayaran": keterangan_pembayaran,
                "Catatan Umum": catatan_umum,
                "Keterangan Pajak": keterangan_pajak,
                "Alamat Pajak": alamat_pajak,
                "Provinsi": provinsi_pajak,
                "Kabupaten": kabupaten_pajak,
                "Kecamatan": kecamatan_pajak,
                "Kelurahan": kelurahan_pajak,
                "Kode Pos Pajak": kode_pos_pajak,

                
                "Daftar Bank": formattedBanks,
                "Daftar Kontak": formattedContacts,
                // "Daftar Kontak": daftar_kontak,
                
                // "Size": size,
                
                ...rest
                };
                objArray[i] = newObj;
            }
            return objArray;
        },
        convertToExcel() {
            ShowLoading();

            // Create a deep copy of `this.records` to avoid modifying the original data
            // const data = JSON.parse(JSON.stringify(this.record));
            const data = JSON.parse(JSON.stringify(this.filteredData));
            const ArrExport = this.addNumberToObjects(data);

            for (let i = 0; i < ArrExport.length; i++) {
                delete ArrExport[i]["id"];
                delete ArrExport[i]["category"];
                delete ArrExport[i]["status"];
                delete ArrExport[i]["id_profiles"];
                delete ArrExport[i]["created_by"];
                delete ArrExport[i]["updated_by"];
                delete ArrExport[i]["created_at"];
                delete ArrExport[i]["updated_at"];
                delete ArrExport[i]["deleted_at"];
                delete ArrExport[i]["deleted_by"];
                delete ArrExport[i]["deleted_by"];
                delete ArrExport[i]["no"];
                delete ArrExport[i]["gambar"];
                delete ArrExport[i]["status_supplier"];
                // delete ArrExport[i]["daftar_bank"];
                // delete ArrExport[i]["daftar_kontak"];
                delete ArrExport[i]["catatan_pembelian"];
                delete ArrExport[i]["kode_pos"];
                delete ArrExport[i]["saldo_awal_hutang"];
                delete ArrExport[i]["alamat_pembayaran"];

                
            }

            

            // const footerRow = {
            //     No: '', // Leave the No column empty for the footer
            //     Supplier: '', // Empty for footer
            //     Kategori: '', // Empty for footer
            //     "Kode Produk": '', // Empty for footer
            //     Varian: '', // Empty for footer
            //     Size: 'TOTAL', // Empty for footer
            //     "Qty diterima": this.totalQty,// Total of qty_buy
            //     "Harga Modal": '', // Empty for footer
            //     Total: this.currencyInput(this.total, this.currency), // Total cost formatted as currency
                
            // };

            // ArrExport.push(footerRow);

            // Create a new worksheet


            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Create a new worksheet
            const ws = XLSX.utils.json_to_sheet(ArrExport);
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, 'REKAP');
            // Convert the workbook to an XLSX file and download it
            XLSX.writeFile(wb, 'Daftar Supplier' + ".xlsx");
            CloseLoading();
        },

        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys; // Set the selected row keys
            console.log('Selected Row Keys:', selectedRowKeys);
        },

        // excellent export JS
        // convertToExcel(event) {

        //      // Create a temporary table element
        //     const tempTable = document.createElement('table');
        //     tempTable.id = 'tempTable';
        //     tempTable.style.display = 'none'; // Hide the table

        //     // Add table headers
        //     const headers = `
        //     <tr>
        //         <th>No</th>
        //         <th>Nama Perusahaan</th>
        //         <th>No Telepon</th>
        //         <th>WhatsApp</th>
        //         <th>Provinsi</th>
        //     </tr>
        //     `;
        //     tempTable.innerHTML = headers;

        //     // Add table rows using the computed `filteredData`
        //     this.filteredData.forEach(item => {
        //         const row = `
        //         <tr>
        //             <td>${item.no}</td>
        //             <td>${item.nama_perusahaan}</td>
        //             <td>${item.no_telepon}</td>
        //             <td>${item.whatsapp}</td>
        //             <td>${item.provinsi}</td>
        //         </tr>
        //         `;
        //         tempTable.innerHTML += row;
        //     });

        //     // Append the table to the body temporarily
        //     document.body.appendChild(tempTable);

        //     // Use ExcellentExport to export
        //     ExcellentExport.excel(event.target, 'tempTable', 'Filtered Data');

        //     // Remove the temporary table after exporting
        //     document.body.removeChild(tempTable);

            
        // },

        // exportExcel() {

        //     ShowLoading();
        //     // let formData = new FormData();

        //     // Object.entries(this.currentData).forEach(([key, value]) => {
        //     //     // Create a new Date object
        //     //     const date = new Date(value);

        //     //     // Use built-in methods to get year, month, and day
        //     //     const year = date.getFullYear();
        //     //     const month = String(date.getMonth() + 1).padStart(2, '0');
        //     //     const day = String(date.getDate()).padStart(2, '0');
        //     //     const formattedDate = `${year}-${month}-${day}`;
        //     //     formData.append(key, formattedDate);
        //     // });

        //     if (process.env.NODE_ENV === "development") {
        //         this.api = base_url + 'customer_export';
        //     } else {
        //         this.api = base_url + 'customer_export';
        //     }
        //     axios
        //         .post(this.api, '', this.config)
        //         .then((response) => {
        //             if (response.data.status == true) {
        //                 openInNewTab(response.data.data.file_report);
        //                 CloseLoading();
        //             }


        //             if (response.data.status == false) {
        //                 AlertPopup("error", "", response.data.message, 1500, false);
        //             }
        //         })
        //         .catch((error) => {
        //             ErrorConnectionTimeOut(error);
        //         });

        // },

        index() {
            // ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'suplier';
            } else {
                this.api = base_url + 'suplier';
            }
            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data_supplier.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });

                        this.isLoading = false
                        // for stop initialize loading from ant-table
                        this.tableLoading = false;


                        //save jenis industri to local
                        localStorage.setItem('needData1', JSON.stringify(response.data.data_jenis_industri));
                        //save syarat pembayaran to local
                        localStorage.setItem('needData2', JSON.stringify(response.data.data_syarat_pembayaran));
                        //save mata uang to ekspedisi
                        localStorage.setItem('needData3', JSON.stringify(response.data.data_mata_uang));
                        // for pajak
                        localStorage.setItem('needData4', JSON.stringify(response.data.data_pajak));
                        // for tipe pajak
                        localStorage.setItem('needData5', JSON.stringify(response.data.data_tipe_pajak));

                        // CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.isLoading = false
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            formData.append("nama", this.nama_terminate);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'suplier_delete';
            } else {
                this.api = base_url + 'suplier_delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id, nama) {
            this.id_terminate = id;
            this.nama_terminate = nama;
            this.tanggal_terminate = new Date();
        }
    },
    
    name: "productlist",
};
</script>

<style scoped>
.ant-tooltip {
    display: none !important;
}

.ant-pagination-total-text {
    margin-left: auto; 
    /* padding-right: 70px ; */
}

.ant-table-pagination.ant-pagination {
    padding-right: 100px ;

}

/* .btn-added {
    border-radius: 50% !important;
    width: 50px; 
    height: 50px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
} */

/* .custom-search {
    background-color: #F6F6F6;
} */


.custom-input {
    background-color: #F6F6F6;
    /* border: 1px solid #F6F6F6 !important; */
}

.feature-search {
    display: flex;
    align-items: center; /* Center aligns the icons vertically */
}

.icon-container {
    font-size: 24px; /* Adjust size as needed */
    margin-right: 10px; /* Space between icons */
    cursor: pointer; /* Changes the cursor to pointer on hover */
    border: none !important;
    padding: 5px; 
    border-radius: 4px; 
    display: inline-flex; 
    align-items: center; /* Centers the content vertically */
    justify-content: center;

}

.icon-container i {
    color: #d6b884;
}

.search-set .search-input {
    background-color: #F6F6F6;
    border: none !important;
}

.custom-pt {
    padding-top: 4rem;
}

.search-input {
    border-color: #f4f4f4 !important;
    /* font-weight: 400 !important; */
    text-transform: none !important;
    /* color: #C3C3C3 !important; */
    border-radius: 5px !important;
    
}

/* .add-btn {
    border-radius: 50% !important;
    width: 30px; 
    height: 30px;
    display: flex; 
    justify-content: center !important; 
    align-items: center !important; 
    text-align: center !important;
    color: white;
    font-size: 14px;
} */

/* .icon-container:hover {
    color: #007bff; 
} */
</style>